import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFirstAndLastDate } from '../../../../utils/dateFormatter'
import { getItem, getLoginToken } from '../../../../utils/storageUtils'
import { getAdminUserId, getAllTenantOptionsSelected, checkIsTenant, getJackpotReportSortedValue, getTenantId } from '../../../../utils/constants'
import { getJackpotContributionStart } from '../../../../store/redux-slices/dashboard'

const useJackpotContribution = ({ selectedPortal = '', selectedClient = '', accordionState }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const isTenant = checkIsTenant()

  const { jackpotContributionLoading: loading, jackpotContribution } = useSelector((state) => state.dashboard)
  const [jackpotContributionData, setJackpotContributionData] = useState(jackpotContribution)
  // const [state, setState] = useState(new Date())
  const [selectedTab, setSelectedTab] = useState('total')
  const [state, setState] = useState(`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}`)

  const [sortBy, setSortBy] = useState({
    isSortingField: null,
    id: '',
    jackpotContribution: '',
    jackpotWin: '',
    jackpotRevenue: ''
  })
  const [hover, setHover] = useState({
    id: false,
    jackpotContribution: false,
    jackpotWin: false,
    jackpotRevenue: false
  })

  const fetchDetails = () => {
    const [startDate, endDate] = getFirstAndLastDate(state?.split('-')?.[1], state?.split('-')?.[0])
    accordionState.JackpotReport && dispatch(getJackpotContributionStart({
      isTenant,
      startDate,
      endDate,
      tenantId: isTenant ? getAllTenantOptionsSelected() ? '' : getTenantId() : selectedPortal,
      adminId: isTenant ? getAdminUserId() : selectedClient,
      userId: userId || '',
      selectedTab
    }))
  }

  useEffect(() => {
    const orderBy = getJackpotReportSortedValue[sortBy.isSortingField] || ''
    const sort = sortBy.id || sortBy.jackpotContribution || sortBy.jackpotWin || sortBy.jackpotRevenue

    if (jackpotContribution && orderBy && sort) {
      const tableData = JSON.parse(JSON.stringify(jackpotContribution))
      const dynamicSort = (property) => (a, b) => {
        if (sort === 'desc') {
          return parseFloat(b[property]) - parseFloat(a[property])
        } else {
          return parseFloat(a[property]) - parseFloat(b[property])
        }
      }
      const sortedTableData = tableData.sort(dynamicSort(orderBy))
      setJackpotContributionData(sortedTableData)
    } else setJackpotContributionData(jackpotContribution)
  }, [sortBy, jackpotContribution])

  useEffect(() => {
    fetchDetails()
  }, [state, selectedClient, selectedPortal, selectedTab, accordionState.JackpotReport])

  const getCsvDownloadUrl = () => {
    const [startDate, endDate] = getFirstAndLastDate(state?.split('-')?.[1], state?.split('-')?.[0])
    return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/jackpot?${!isTenant ? `adminId=${selectedClient}&` : ''}tenantId=${isTenant ? getItem('tenant-id') : selectedPortal}&startDate=${startDate}&endDate=${endDate}&csvDownload=true&token=${getLoginToken()}&userId=${userId || ''}&tab=${selectedTab}`
  }

  return {
    state,
    hover,
    sortBy,
    loading,
    setState,
    setHover,
    setSortBy,
    selectedTab,
    fetchDetails,
    setSelectedTab,
    getCsvDownloadUrl,
    jackpotContribution: jackpotContributionData
  }
}

export default useJackpotContribution
