import React from 'react'
import CreatableSelect from 'react-select/creatable'

const CustomCreatableSelect = ({
  name,
  options, // Array of options for the select dropdown (label-value pairs)
  value, // Selected value(s) from the options (single or multi-select)
  onChange, // Function to update the selected value(s)
  selectClassName = '', // CSS class applied to the Select component
  selectClassPrefix = 'select', // Class prefix for styling React Select (default is 'select')
  disabled = false,
  isMulti = false,
  formik // Formik object, if passed
}) => {
  // Helper to get the currently selected options
  const getSelectedOptions = () => {
    if (isMulti) {
      return Array.isArray(value)
        ? options.filter(option => value.includes(option.value))
        : []
    } else {
      return Array.isArray(options) ? options.find(option => option.value === value) || null : null
    }
  }

  /**
   * handleSelectChange - Handles the selection change event
   * @param {object|Array} selectedOptions - The selected option(s) from the React Select component
   */
  const handleSelectChange = (selectedOptions) => {
    if (isMulti) {
      const newSelectedValues = selectedOptions
        ? selectedOptions.map(option => option.value)
        : []
      onChange(newSelectedValues)
      formik && formik.setFieldValue(name, newSelectedValues)
    } else {
      const newSelectedValue = selectedOptions ? selectedOptions.value : null
      onChange(newSelectedValue)
      formik && formik.setFieldValue(name, newSelectedValue)
    }
  }

  /**
   * handleCreateOption - Handles the creation of a new option
   * @param {string} newOption - The new option created by the user
   */
  const handleCreateOption = (newOption) => {
    const newOptionObj = { label: newOption, value: newOption }
    const updatedOptions = [...options, newOptionObj]
    if (isMulti) {
      onChange([...value, newOption], 'create')
      formik?.setFieldValue && formik.setFieldValue(name, updatedOptions)
    } else {
      onChange(newOption, 'create')
      formik?.setFieldValue && formik.setFieldValue(name, newOption)
    }

    // You might want to persist the new option if required (e.g., save to the backend)
  }

  return (
    <>
      {/* Render the Creatable Select dropdown */}
      <CreatableSelect
        isMulti={isMulti}
        isClearable={false}
        value={getSelectedOptions()}
        options={options}
        className={selectClassName}
        classNamePrefix={selectClassPrefix}
        onChange={handleSelectChange}
        onCreateOption={handleCreateOption}
        isDisabled={disabled}
      />
    </>
  )
}

export default CustomCreatableSelect
