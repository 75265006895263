
import * as Yup from 'yup'

export const redirectionValidationSchema = Yup.object().shape({
  ownerId: Yup.string().required('Owner is required'),
  tenantId: Yup.string().required('Portal is required'),
  countryCodes: Yup.array().min(1, 'At least one country must be selected'),
  redirectionUrl: Yup.string()
    .url('Enter a valid URL')
    .required('Redirection URL is required')
})
