import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSuperAdminAggregatorsStart } from '../../store/redux-slices/superAdminCasinoManagement'

const useAggregatorList = ({ tenantId }) => {
  // Get the state of aggregator categories and loading status from Redux store
//   const { allSAAggregators, allAggregators } = useSelector(state => state.casino)
  const { aggregators } = useSelector((state) => state.superAdminCasino)
  // Local state to manage the selected aggregator category ID
  const [selectedAggregatorId, setSelectedAggregatorId] = useState('')

  // Dispatch function to trigger Redux actions
  const dispatch = useDispatch()
  useEffect(() => {
    // Fetch aggregator categories if gameAggregatorId is provided
    dispatch(getSuperAdminAggregatorsStart({ limit: '', pageNo: '', search: '', tenantId }))
  }, [tenantId]) // Dependency array: rerun effect when gameAggregatorId or search changes

  const aggregatorsOptions = useMemo(() =>
    Array.isArray(aggregators?.rows)
      ? aggregators.rows?.map(({ name, masterGameAggregatorId }) => ({
        label: name,
        value: masterGameAggregatorId
      }))
      : [],
  [aggregators] // Re-run the transformation only if aggregators changes
  )

  // Return necessary values and functions for the component that uses this hook
  return {
    aggregators,
    aggregatorsOptions, // Available options for category selection (currently empty array)
    selectedAggregatorId, // Function to set the selected  ID
    setSelectedAggregatorId // Currently selected aggregator  ID
  }
}

export default useAggregatorList
