import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getAllSAProvidersStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getSuperAdminCasinoTransactionsStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getLoginToken } from '../../../../utils/storageUtils'
import { getCasinoTransactionSortedValue } from '../../../../utils/constants'

const useCasinoTransactionsList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [amountType, setAmountType] = useState('')
  const [selectedAction, setSelectedAction] = useState('all')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('all')
  const [search, setSearch] = useState('')
  const [gameSearch, setGameSearch] = useState('')
  const [showTotalAmount, setShowTotalAmount] = useState(false)
  const [providers, setProviders] = useState('')
  const [sortBy, setSortBy] = useState({
    bonusAmount: '',
    cashAmount: '',
    id: '',
    afterBalance: '',
    beforeBalance: '',
    userId: '',
    isSortingField: null
  })
  const [over, setOver] = useState({
    bonusAmount: false,
    cashAmount: false,
    afterBalance: false,
    beforeBalance: false,
    userId: false,
    id: false
  })

  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { allCurrencies } = useSelector((state) => state.currencies)
  const { allProviders } = useSelector((state) => state.superAdminCasino)
  const { loading, casinoTransactions } = useSelector((state) => state.superAdminTransactions)
  const totalPages = Math.ceil(casinoTransactions?.count / limit)

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  useEffect(() => {
    dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))
    dispatch(getAllSAProvidersStart({ tenantId: '' }))
  }, [])

  useEffect(() => {
    const orderBy = getCasinoTransactionSortedValue[sortBy.isSortingField] || ''
    const { bonusAmount, cashAmount, id, afterBalance, beforeBalance, userId } = sortBy
    const sort = bonusAmount || cashAmount || id || afterBalance || beforeBalance || userId

    !isInitialRender && dispatch(getSuperAdminCasinoTransactionsStart({
      limit,
      pageNo: page,
      searchByUserId: search.replace('+', '%2B'),
      gameSearch: gameSearch.replace(/\s/g, ''),
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      currencyCode: selectedCurrency,
      transactionType: selectedAction,
      tenantId: selectedPortal,
      adminId: selectedClient,
      status,
      userId: '',
      amountType,
      orderType: sort,
      orderBy,
      providers,
      showTotalAmount
    }))
  }, [page])

  useEffect(() => {
    const orderBy = getCasinoTransactionSortedValue[sortBy.isSortingField] || ''
    const { bonusAmount, cashAmount, id, afterBalance, beforeBalance, userId } = sortBy
    const sort = bonusAmount || cashAmount || id || afterBalance || beforeBalance || userId

    setPage(1)
    dispatch(getSuperAdminCasinoTransactionsStart({
      limit,
      pageNo: 1,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      currencyCode: selectedCurrency,
      transactionType: selectedAction,
      tenantId: selectedPortal,
      adminId: selectedClient,
      status,
      userId: '',
      gameSearch: gameSearch.replace(/\s/g, ''),
      searchByUserId: search.replace('+', '%2B'),
      amountType,
      orderType: sort,
      orderBy,
      providers,
      showTotalAmount
    }))
  }, [sortBy, limit, showTotalAmount, providers, selectedPortal, selectedCurrency, selectedAction, state, status, selectedClient, amountType])

  useEffect(() => {
    const orderBy = getCasinoTransactionSortedValue[sortBy.isSortingField] || ''
    const { bonusAmount, cashAmount, id, afterBalance, beforeBalance, userId } = sortBy
    const sort = bonusAmount || cashAmount || id || afterBalance || beforeBalance || userId

    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getSuperAdminCasinoTransactionsStart({
            limit,
            pageNo: page,
            searchByUserId: search.replace('+', '%2B'),
            gameSearch: gameSearch.replace(/\s/g, ''),
            startDate: formatDateYMD(state.map(a => a.startDate)),
            endDate: formatDateYMD(state.map(a => a.endDate)),
            currencyCode: selectedCurrency,
            transactionType: selectedAction,
            tenantId: selectedPortal,
            adminId: selectedClient,
            status,
            userId: '',
            amountType,
            orderType: sort,
            orderBy,
            providers,
            showTotalAmount
          }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, gameSearch])

  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/superadmin/casino/transactions?csvDownload=true&limit=${limit}&pageNo=${page}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&currencyCode=${selectedCurrency}&transactionType=${selectedAction}&tenantId=${selectedPortal}&status=${status}&email=${search}&adminId=${selectedClient}&gameSearch=${gameSearch.replace(/\s/g, '')}&token=${getLoginToken()}`

  return {
    allCurrencies,
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    navigate,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    casinoTransactions,
    loading,
    status,
    setStatus,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    getCsvDownloadUrl,
    sortBy,
    setSortBy,
    over,
    setOver,
    amountType,
    setAmountType,
    gameSearch,
    setGameSearch,
    allProviders,
    providers,
    setProviders,
    showTotalAmount,
    setShowTotalAmount
  }
}

export default useCasinoTransactionsList
