import React from 'react'
import { Row, Button, Modal, Col } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { redirectionValidationSchema } from '../constant'
import useRedirectionModal from '../hooks/useRedirectionModal'
import FormFieldContainer from '../../../../shared/Formik/FormFieldContainer'

const RedirectionModal = ({ isVisible, closeModal, onConfirm, ownerId, tenantId }) => {
  const {
    initialValue,
    formFields,
    redirectionDetail
  } = useRedirectionModal({ ownerId, tenantId })

  return (
    <Modal show={isVisible} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Mirror Site Redirection </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={redirectionValidationSchema}
          onSubmit={onConfirm}
        >
          {(formik) => {
            const { handleSubmit } = formik
            return (
              <Form onSubmit={handleSubmit} className='form-grid'>
                <Row className='px-2'>
                  {formFields.filter((props) => props.isFieldRequired).map((props) => (
                    <Col
                      key={props.name}
                      xs={12}
                    >
                      <FormFieldContainer formik={formik} {...props} />
                    </Col>
                  ))}
                </Row>
                {
                !redirectionDetail.isView &&
                  <div className='mt-4 d-flex justify-content-between'>
                    <Button variant='outline-warning' onClick={closeModal}>
                      Cancel
                    </Button>

                    <Button variant='outline-success' type='submit'>
                      Submit
                    </Button>
                  </div>
              }
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default RedirectionModal
