import React from 'react'
import { Form } from '@themesberg/react-bootstrap'

const CustomSelect = ({
  label = '',
  size = 'sm',
  options = [],
  value = '',
  onChange,
  hasAllOptions = true,
  setFieldValue,
  disabled = false,
  fieldName = '',
  placeholder = '',
  noOptionsMessage = '', // Message when no options are available
  width = '220px', // Custom width for the select element
  labelClassName = 'm-1'
}) => {
  const handleSelectChange = (e) => {
    const value = e.target.value
    setFieldValue && setFieldValue(fieldName, value)
    onChange && onChange(value) // Call custom onChange if provided
  }

  return (
    <>
      <Form.Label className={labelClassName}>
        {label}
      </Form.Label>

      <Form.Select
        onChange={handleSelectChange}
        style={{ width }}
        value={value}
        size={size}
        disabled={disabled}
      >
        {hasAllOptions && (
          <option value=''>
            {placeholder}
          </option>
        )}

        {options.length === 0
          ? (
            <option value='' disabled>
              {noOptionsMessage}
            </option>
            )
          : (
              options.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))
            )}
      </Form.Select>
    </>
  )
}

export default CustomSelect
