import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPortalsStart } from '../../store/redux-slices/tenants'
import { checkIsTenant } from '../../utils/constants'

const useTenantOwner = ({ fetchTenant = false, adminId }) => {
  const dispatch = useDispatch()
  const isTenant = checkIsTenant()
  const { tenantDetails } = useSelector((state) => state.login)
  const { portalsFilterData, tenantsList } = useSelector(state => state.tenants)
  const [selectedTenant, setSelectedTenant] = useState(isTenant ? tenantDetails?.tenantId : '')

  // Fetch portals based on selected owner when not a tenant
  useEffect(() => {
    if (fetchTenant) {
      dispatch(getAllPortalsStart({ adminId }))
    }
  }, [adminId])

  // Memoized options for portal selection
  const tenantOptions = useMemo(() => {
    if (isTenant && tenantsList?.rows?.length > 0) {
      return tenantsList.rows.map((portal) => ({
        label: `${portal.name} (${portal.domain})`, // Portal name as label
        value: portal.tenantId // tenantId as value
      }))
    } else if (!isTenant && portalsFilterData?.rows?.length > 0) {
      return portalsFilterData.rows.map((portal) => ({
        label: `${portal.name} (${portal.domain})`, // Portal name as label
        value: portal.tenantId // tenantId as value
      }))
    }
    return []
  }, [tenantsList, portalsFilterData])

  return {
    tenantOptions,
    selectedTenant,
    setSelectedTenant
  }
}

export default useTenantOwner
