import { takeLatest, put, call } from 'redux-saga/effects'
import {
  createLicenseRecord,
  updateLicenseRecord,
  getLicenseDetail,
  getCountryList,
  getLicenseList,
  updateGlobalLicense,
  addGlobalLicense,
  getExcludedLicenses,
  updateDefaultLicense
} from '../../utils/apiCalls' // Adjust import as necessary

import {
  createLicenseRecordStart,
  createLicenseRecordSuccess,
  createLicenseRecordFailure,
  updateLicenseRecordStart,
  updateLicenseRecordSuccess,
  updateLicenseRecordFailure,
  getLicenseDetailStart,
  getLicenseDetailSuccess,
  getLicenseDetailFailure,
  getCountryListStart,
  getCountryListSuccess,
  getCountryListFailure,
  getLicenseListStart,
  getLicenseListSuccess,
  getLicenseListFailure,
  updateGlobalLicenseStart,
  updateGlobalLicenseComplete,
  getLicenseListCountSuccess,
  getExcludedLicenseListStart,
  getExcludedLicenseListSuccess,
  getExcludedLicenseListFailure,
  startSettingDefaultLicense,
  settingDefaultLicenseSuccess,
  settingDefaultLicenseFailure
} from '../redux-slices/licenseManagement' // Adjust import as necessary
import { toast } from '../../components/Toast'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { checkIsTenant } from '../../utils/constants'

export default function * licenseManagementWatcher () {
  yield takeLatest(getExcludedLicenseListStart.type, getExcludedLicenseListWatcher)
  yield takeLatest(getLicenseListStart.type, getLicenseListWatcher)
  yield takeLatest(createLicenseRecordStart.type, createLicenseRecordWatcher)
  yield takeLatest(updateLicenseRecordStart.type, updateLicenseRecordWatcher)
  yield takeLatest(getLicenseDetailStart.type, getLicenseDetailWatcher)
  yield takeLatest(getCountryListStart.type, getCountryListWatcher)
  yield takeLatest(updateGlobalLicenseStart.type, updateGlobalLicenseWatcher)
  yield takeLatest(startSettingDefaultLicense.type, updateDefaultLicenseWatcher)
}

function * getExcludedLicenseListWatcher (action) {
  try {
    const response = yield call(getExcludedLicenses, action.payload)
    yield put(getExcludedLicenseListSuccess(response.data?.data?.licenses))
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to fetch license list', 'error')
    yield put(getExcludedLicenseListFailure(e?.response?.data?.errors[0]?.description))
  }
}

// Get License List
function * getLicenseListWatcher (action) {
  try {
    const response = yield call(getLicenseList, action.payload)
    yield put(getLicenseListSuccess(response.data?.data?.tenantLicense?.rows ? response.data?.data?.tenantLicense?.rows : response.data?.data?.tenantLicense))
    yield put(getLicenseListCountSuccess(response.data?.data?.tenantLicense?.rows ? response.data?.data?.tenantLicense?.rows?.length : response.data?.data?.tenantLicense?.length))
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to fetch license list', 'error')
    yield put(getLicenseListFailure(e?.response?.data?.errors[0]?.description))
  }
}

// Create License Record
function * createLicenseRecordWatcher (action) {
  const { licenseFormValues, navigate } = action.payload
  try {
    const response = yield call(createLicenseRecord, licenseFormValues)
    yield put(createLicenseRecordSuccess(response.data))
    toast('License Created Successfully', 'success')
    navigate(checkIsTenant() ? TenantRoutes.Credentials : SuperAdminRoutes.Credentials, { state: 'licenseManagement' })
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to create license', 'error')
    yield put(createLicenseRecordFailure(e?.response?.data?.errors[0]?.description))
  }
}

// Update License Record
function * updateLicenseRecordWatcher (action) {
  const { licenseFormValues, navigate } = action.payload
  try {
    const response = yield call(updateLicenseRecord, licenseFormValues)
    yield put(updateLicenseRecordSuccess(response.data))
    toast('License Updated Successfully', 'success')
    navigate(checkIsTenant() ? TenantRoutes.Credentials : SuperAdminRoutes.Credentials, { state: 'licenseManagement' })
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to update license', 'error')
    yield put(updateLicenseRecordFailure(e?.response?.data?.errors[0]?.description))
  }
}

// Get License Detail
function * getLicenseDetailWatcher (action) {
  try {
    const response = yield call(getLicenseDetail, action.payload)
    yield put(getLicenseDetailSuccess(response.data?.data.tenantLicenseDetails))
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to fetch license details', 'error')
    yield put(getLicenseDetailFailure(e?.response?.data?.errors[0]?.description))
  }
}

// Get Country List
function * getCountryListWatcher (action) {
  try {
    const response = yield call(getCountryList, action.payload)
    yield put(getCountryListSuccess(response.data?.data))
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to fetch country list', 'error')
    yield put(getCountryListFailure(e?.response?.data?.errors[0]?.description))
  }
}

// Update Global License Record
function * updateGlobalLicenseWatcher (action) {
  const { licenseFormValues, type, search } = action.payload
  try {
    const response = type === 'edit' ? yield call(updateGlobalLicense, licenseFormValues) : yield call(addGlobalLicense, licenseFormValues)
    yield put(updateGlobalLicenseComplete(response.data))
    yield put(getLicenseListStart({ search }))
    yield toast(`License ${type === 'create' ? 'Created' : 'Edited'} Successfully`, 'success')
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to update license', 'error')
    yield put(updateGlobalLicenseComplete())
  }
}

function * updateDefaultLicenseWatcher (action) {
  try {
    const { data, fetchLicenseList } = action.payload
    const response = yield call(updateDefaultLicense, data)
    fetchLicenseList()
    yield put(settingDefaultLicenseSuccess(response.data))

    yield toast('License has been successfully set as the default', 'success')
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to update license', 'error')
    yield put(settingDefaultLicenseFailure())
  }
}
