import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isTenant } from '../../../../utils/constants'
import { getElasticHealthStart } from '../../../../store/redux-slices/dashboard'
import useCheckPermission from '../../../../utils/checkPermission'
import { getLicenseListStart } from '../../../../store/redux-slices/licenseManagement'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useDashboard = () => {
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { adminPermissions, loading } = useSelector(state => state.admins)
  const { elasticHealth } = useSelector(state => state.dashboard)
  const { isHidden } = useCheckPermission()

  const [selectedLicense, setSelectedLicense] = useState('')

  const { loadingLicenseList, licenseList } = useSelector((state) => state.licenseManagement)

  // Custom hook to check if the component has just mounted
  const isInitialRender = useDidMountEffect()

  const [accordionState, setAccordionState] = useState({
    demographicReport: true,
    playerManagementReport: false,
    playerLiabilityReport: false,
    kpiSummaryReport: false,
    kpiReport: false,
    gameReport: false,
    JackpotReport: false,
    depositsGGRReport: false
  })
  const reportsToShow = ['GameReport', 'KpiReport', 'DemographReport', 'PlayerManagementReport', 'KpiSummaryReport', 'LivePlayerReport', 'PlayerLiabilityReport', 'JackpotReport']
  const permissionKeys = adminPermissions && Object.keys(adminPermissions)
  const dispatch = useDispatch()
  const nonElasticReports = ['DemographReport', 'LivePlayerReport', 'PlayerManagementReport', 'JackpotReport']

  useEffect(() => {
    if (!isTenant) {
      dispatch(
        getLicenseListStart({
          tenantId: selectedPortal || ''
        })
      )
    }
  }, [selectedPortal])

  useEffect(() => {
    if (!isInitialRender) {
      setSelectedLicense('')
    }
  }, [selectedPortal])

  const licenseListOptions = useMemo(() => {
    return licenseList
      ? licenseList.map((item) => ({
        value: item.id,
        label: item.name
      }))
      : []
  }, [licenseList])

  useEffect(() => {
    dispatch(getElasticHealthStart({ isTenant }))
  }, [])

  return {
    loading,
    isHidden,
    elasticHealth,
    reportsToShow,
    selectedClient,
    selectedPortal,
    accordionState,
    permissionKeys,
    adminPermissions,
    setSelectedClient,
    setSelectedPortal,
    nonElasticReports,
    setAccordionState,
    loadingLicenseList,
    licenseListOptions,
    selectedLicense,
    setSelectedLicense
  }
}

export default useDashboard
