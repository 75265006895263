import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateReorderGamesStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { getAllCasinoCategoriesStart, getAllGamesStart, getAllSubCategoriesStart } from '../../../../store/redux-slices/tenantCasino'
import { getItem } from '../../../../utils/storageUtils'
import { getTenantId } from '../../../../utils/constants'
import useAggregatorList from '../../../../shared/hooks/useAggregatorList'
import useAggregatorCategories from '../../../../shared/hooks/useAggregatorCategories'

const useGameReorder = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, games, casinoCategories, subCategories } = useSelector((state) => state.tenantCasino)
  const [reOrderedGame, setReorderedGame] = useState({ rows: [], count: 0 })
  const [casinoGames, setCasinoGames] = useState({ rows: [], count: 0 })
  const [categoryFilter, setCategoryFilter] = useState('')
  const [casinoCategoryId, setCasinoCategoryId] = useState(null)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const totalPages = Math.ceil(games?.count / limit)

  const {
    aggregatorsOptions,
    selectedAggregatorId,
    setSelectedAggregatorId
  } = useAggregatorList({
    tenantId: getTenantId()
  })

  const {
    aggregatorsCategoryOptions,
    selectedAggregatorCategoryId,
    setSelectedAggregatorCategoryId
  } = useAggregatorCategories({
    gameAggregatorId: selectedAggregatorId,
    search
  })

  useEffect(() => {
    if (games && games.length) {
      if (reOrderedGame?.rows) {
        const filteredArray = games?.filter((item) => {
          const exist = reOrderedGame?.rows.find(common => common.categoryGameId === item.categoryGameId)
          if (exist) {
            return false
          } else {
            return true
          }
        })
        setCasinoGames({ rows: filteredArray, count: games?.length })
      } else {
        setCasinoGames({ rows: games, count: games?.length })
      }
    }
  }, [games])

  useEffect(() => {
    dispatch(getAllCasinoCategoriesStart({ limit: '', pageNo: '' }))
  }, [])

  const reorder = (reorderItem, startIndex, endIndex) => {
    const result = Array.from(reorderItem)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const rows = reorder(
      reOrderedGame.rows,
      result.source.index,
      result.destination.index
    )
    setReorderedGame({ rows, count: rows.length })
  }

  const handleAddGame = (item) => {
    setReorderedGame((oldItem) => {
      const newArray = [...oldItem.rows, item]
      return { rows: newArray, count: newArray.length }
    })
    setCasinoGames((oldItem) => {
      const newArray = oldItem?.rows.filter((gameItem) => gameItem.categoryGameId !== item.categoryGameId)
      return { rows: newArray, count: newArray.length }
    })
  }

  const handRemoveGame = (item) => {
    setCasinoGames((oldItem) => {
      const newArray = [...oldItem.rows, item]
      return { rows: newArray, count: newArray.length }
    })
    setReorderedGame((oldItem) => {
      const newArray = oldItem?.rows.filter((gameItem) => gameItem.categoryGameId !== item.categoryGameId)
      return { rows: newArray, count: newArray.length }
    })
  }

  const handleSave = async () => {
    const orderedGames = []
    const unOrderedGames = []
    reOrderedGame && reOrderedGame.rows.map((list) => orderedGames.push(list.categoryGameId))
    casinoGames && casinoGames.rows.map((list) => unOrderedGames.push(list.categoryGameId))

    const data = {
      order: [...orderedGames, ...unOrderedGames],
      tenantGameSubCategoryId: casinoCategoryId,
      tenantId: getItem('tenant-id')
    }
    await dispatch(updateReorderGamesStart({
      data,
      casinoCategoryId,
      aggregatorId: selectedAggregatorId,
      aggregatorCategoryId: selectedAggregatorCategoryId
    }))
    setReorderedGame({ rows: [], count: 0 })
  }

  useEffect(() => {
    if (casinoCategoryId) {
      dispatch(
        getAllGamesStart({
          limit: '',
          pageNo: '',
          casinoCategoryId,
          isActive: '',
          orderBy: '',
          sort: '',
          providerId: '',
          order: true,
          aggregatorId: selectedAggregatorId,
          aggregatorCategoryId: selectedAggregatorCategoryId
        })
      )
    }
  }, [casinoCategoryId, selectedAggregatorId, selectedAggregatorCategoryId])

  useEffect(() => {
    if (categoryFilter) {
      dispatch(getAllSubCategoriesStart({
        limit: '',
        pageNo: '',
        categoryId: categoryFilter,
        search: '',
        isActive: '',
        orderBy: '',
        sort: ''
      }))
    }
  }, [categoryFilter])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (categoryFilter) {
        dispatch(
          getAllGamesStart({
            limit: '',
            pageNo: '',
            casinoCategoryId,
            isActive: '',
            orderBy: '',
            sort: '',
            providerId: '',
            search,
            order: true,
            aggregatorId: selectedAggregatorId,
            aggregatorCategoryId: selectedAggregatorCategoryId
          })
        )
        setPage(1)
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    loading,
    reOrderedGame,
    onDragEnd,
    handleSave,
    navigate,
    casinoGames,
    handRemoveGame,
    handleAddGame,
    casinoCategories,
    categoryFilter,
    setCategoryFilter,
    totalPages,
    setLimit,
    setPage,
    limit,
    page,
    subCategories,
    casinoCategoryId,
    setCasinoCategoryId,
    setReorderedGame,
    search,
    setSearch,
    aggregatorsOptions,
    selectedAggregatorId,
    setSelectedAggregatorId,
    aggregatorsCategoryOptions,
    selectedAggregatorCategoryId,
    setSelectedAggregatorCategoryId
  }
}

export default useGameReorder
