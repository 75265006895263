import * as Yup from 'yup'

export const licenseFormValidationSchema = Yup.object().shape({
  ownerId: Yup.string()
    .required('This field is required'),

  tenantId: Yup.string()
    .required('Select At Least One Portal'),

  licenseId: Yup.string()
    .required('This field is required'),

  countryCodes: Yup.array()
    .of(Yup.string().required('Country code is required'))
    .min(1, 'At least one country code is required')
})

export const addGlobalLicenseSchema = Yup.object().shape({
  name: Yup.string()
    .required('License name is required')
    .min(2, 'License name must be at least 2 characters long')
})
