import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getItem, getLoginToken } from '../../../../utils/storageUtils'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getKPIReportStart } from '../../../../store/redux-slices/dashboard'
import { getAdminUserId, getAllTenantOptionsSelected, checkIsTenant, getKPIReportSortedValue, getTenantId } from '../../../../utils/constants'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'

const useKPIReport = ({ selectedPortal = '', selectedClient = '', accordionState }) => {
  const dispatch = useDispatch()
  const isTenant = checkIsTenant()

  const [dateOptions, setDateOptions] = useState('today')
  const [selectedTab, setSelectedTab] = useState('game')
  const { kpiReportLoading: loading, KPIReport } = useSelector((state) => state.dashboard)
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [kpiReportData, setKpiReportData] = useState(KPIReport)
  const [sortBy, setSortBy] = useState({
    isSortingField: null,
    ggr: '',
    deltaGgr: '',
    realBet: '',
    realWin: '',
    bonusBet: '',
    bonusWin: '',
    bonusGgr: '',
    totalGgr: '',
    totalBets: '',
    deltaTotalGgr: ''
  })
  const [hover, setHover] = useState({
    ggr: false,
    deltaGgr: false,
    realBet: false,
    realWin: false,
    bonusBet: false,
    bonusWin: false,
    bonusGgr: false,
    totalGgr: false,
    totalBets: false,
    deltaTotalGgr: false
  })
  const isInitialRender = useDidMountEffect()

  const loadKPIReport = () => {
    accordionState.kpiReport && dispatch(getKPIReportStart({
      isTenant,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      tenantId: isTenant ? getAllTenantOptionsSelected() ? '' : getTenantId() : selectedPortal,
      adminId: isTenant ? getAdminUserId() : selectedClient,
      dateOptions,
      selectedTab
    }))
  }

  useEffect(() => {
    loadKPIReport()
  }, [state, selectedClient, selectedPortal, selectedTab, accordionState.kpiReport])

  useEffect(() => {
    if (dateOptions !== 'custom' && !isInitialRender) loadKPIReport()
  }, [dateOptions])

  useEffect(() => {
    const orderBy = getKPIReportSortedValue[sortBy.isSortingField] || ''
    const { ggr = '', deltaGgr = '', realBet = '', realWin = '', bonusBet = '', bonusWin = '', bonusGgr = '', totalGgr = '', totalBets = '', deltaTotalGgr = '' } = sortBy
    const sort = ggr || deltaGgr || realBet || realWin || bonusBet || bonusWin || bonusGgr || totalGgr || totalBets || deltaTotalGgr

    if (KPIReport && orderBy && sort) {
      const sortedKPIReport = Object.entries(KPIReport).sort((a, b) => {
        const valueA = parseFloat(a[1][orderBy]) || 0
        const valueB = parseFloat(b[1][orderBy]) || 0
        if (sort === 'desc') return valueA - valueB
        else return valueB - valueA
      })

      const sortedKPIReportObject = Object.fromEntries(sortedKPIReport)
      setKpiReportData(sortedKPIReportObject)
    } else setKpiReportData(KPIReport)
  }, [sortBy, KPIReport])

  const getCsvDownloadUrl = () => {
    return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/kpi?${!isTenant ? `adminId=${selectedClient}&` : ''}tab=${selectedTab}&dateOptions=${dateOptions}&tenantId=${isTenant ? getItem('tenant-id') : selectedPortal}&customStartDate=${formatDateYMD(state.map(a => a.startDate))}&customEndDate=${formatDateYMD(state.map(a => a.endDate))}&csvDownload=true&token=${getLoginToken()}`
  }

  return {
    loadKPIReport,
    loading,
    setState,
    state,
    getCsvDownloadUrl,
    KPIReport,
    dateOptions,
    setDateOptions,
    selectedTab,
    setSelectedTab,
    sortBy,
    setSortBy,
    hover,
    setHover,
    kpiReportData,
    setKpiReportData
  }
}

export default useKPIReport
