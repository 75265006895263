import * as Yup from 'yup'

const keysToExclude = ['footerImageOne', 'footerImageTwo', 'footerImageThree', 'cashbackTableHeaderFour',
  'loyaltyTableHeaderFour', 'loyaltyTableHeaderFive', 'loyaltyTableHeaderSix', 'loyaltyTableHeaderSeven']

export const uploadCSVSchema = () => Yup.object().shape({
  languageCsv: Yup.mixed().required('File Required')
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['xls', 'csv', 'xlsx'].includes(getExtension(value?.name))))
    .required('File Required')
})

const getExtension = (filename) => {
  return filename.split('.').pop()
}

export const languageWiseSchema = (obj, allKeys) => Yup.object().shape({
  languageKeys: Yup.object().shape(languageWiseValidate(obj, allKeys))
})

const languageWiseValidate = (obj, allKeys) => {
  const validationObject = {}
  for (const file in obj) {
    if (!keysToExclude.includes(file) && allKeys?.includes(file)) {
      validationObject[file] = Yup.string().required('Value Required').nullable()
    }
  }
  return validationObject
}

export const keysWiseSchema = (key, data) => Yup.object().shape({
  [key]: Yup.object().shape(keyWiseValidate(data, key))
})

const keyWiseValidate = (obj, key) => {
  const validationObject = {}
  for (const file in obj) {
    if (!keysToExclude.includes(key)) {
      validationObject[file] = Yup.string().required('Value Required').nullable()
    }
  }
  return validationObject
}

export const addKeySchema = (data) => Yup.object().shape({
  langKey: Yup.string().required('Key Required').matches(/^\S+$/, 'Only String without any spaces required').nullable(),
  langData: Yup.object().shape(addKeyWiseValidate(data))
})

const addKeyWiseValidate = (obj) => {
  const validationObject = {}
  for (const file in obj) {
    if (file === 'EN') { validationObject[file] = Yup.string().required('Value Required').nullable() } else { validationObject[file] = Yup.string().nullable() }
  }
  return validationObject
}
