import React from 'react'
import { toast } from '../Toast'
import useUploadGallery from './useUploadGallery'
import '../react-upload-gallery-master/src/style.scss'
import RUG from '../react-upload-gallery-master/src/RUG'
import { DeleteConfirmationModal } from '../ConfirmationModal'
import { Col, Row, Tab, Tabs } from '@themesberg/react-bootstrap'
import ClientFilter from '../ClientFilter'
import PortalFilter from '../PortalFilter'

export default ({ isTenant = false }) => {
  const {
    isHidden,
    selectedTab,
    initialState,
    customRequest,
    selectedClient,
    setSelectedTab,
    selectedPortal,
    setImageDelete,
    deleteModalShow,
    handleDeleteYes,
    setSelectedClient,
    setSelectedPortal,
    setDeleteModalShow
  } = useUploadGallery({ isTenant })

  const tabData = initialState?.filter((image) => selectedTab === 'common' ? (!image?.imageBucketType || image?.imageBucketType === selectedTab) : image?.imageBucketType === selectedTab)

  const RUGComponent = () => {
    return (
      <>
        {initialState?.length > 0 &&
          <>
            {tabData?.length < 1 && !isTenant && <div className='text-danger d-flex justify-content-center align-items-center mt-3'><b>No Images Found</b></div>}
            <RUG
              className='m-3'
              isHidden={isHidden}
              initialState={tabData}
              customRequest={customRequest}
              hideUpload={isTenant ? selectedTab === 'common' : selectedTab !== 'common'}
              onConfirmDelete={(currentImage) => {
                if (!isHidden({ module: { key: 'ImageGallery', value: 'D' } })) {
                  setDeleteModalShow(true)
                  setImageDelete(currentImage)
                } else {
                  toast('Delete Permission Not Granted', 'error')
                }
              }}
              ssrSupport
              rules={{
                size: 1024
              }}

              accept={['jpg', 'jpeg', 'png', 'webp']}

              onWarning={(type, rules) => {
                switch (type) {
                  case 'accept':
                    toast(`Only ${rules.accept.join(', ')} Extensions Allowed`, 'error')
                    break

                  case 'size':
                    toast(`Size of the image must be <= ${rules.size / 1024}MB`, 'error')
                    break

                  default:
                }
              }}
            />
          </>}

        {initialState?.length < 1 &&
          <RUG
            ssrSupport
            className='m-3'
            initialState={[]}
            isHidden={isHidden}
            customRequest={customRequest}
            hideUpload={isTenant ? selectedTab === 'common' : selectedTab !== 'common'}
            accept={['jpg', 'jpeg', 'png', 'webp']}
          />}
      </>
    )
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap'>
        <h3 className='m-2'>Gallery</h3>
        {!isTenant &&
          <Row className='mb-4'>
            <Col className='d-flex align-items-center'>
              <ClientFilter
                setSelectedClient={setSelectedClient}
                setSelectedPortal={setSelectedPortal}
                selectedClient={selectedClient}
                hasAllOptions
              />

              {selectedClient &&
                <PortalFilter
                  setSelectedPortal={setSelectedPortal}
                  selectedPortal={selectedPortal}
                  selectedClient={selectedClient}
                  hasAllOptions={false}
                />}
            </Col>
          </Row>}
      </div>

      <Tabs
        activeKey={selectedTab}
        onSelect={(tab) => {
          setSelectedTab(tab)
        }}
        className='nav-light mt-1 mb-1 ms-1'
        mountOnEnter
        unmountOnExit
      >
        {((isTenant || selectedPortal) ? ['common', 'admin', 'user'] : ['common'])?.map((item) => {
          return (
            <Tab eventKey={item} title={item?.toUpperCase()} key={item}>
              <RUGComponent />
            </Tab>
          )
        })}
      </Tabs>

      {deleteModalShow &&
        <DeleteConfirmationModal
          handleDeleteYes={handleDeleteYes}
          setDeleteModalShow={setDeleteModalShow}
          deleteModalShow={deleteModalShow}
        />}
    </>
  )
}
