import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  Form,
  Card
} from '@themesberg/react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './GameReorder.scss'
import useGameReorder from './hooks/useGameReorder'
import AggregatorList from '../../../shared/AggregatorList'
import AggregatorCategories from '../../../shared/AggregatorCategories'

export default () => {
  const {
    loading,
    reOrderedGame,
    onDragEnd,
    handleSave,
    casinoGames,
    handRemoveGame,
    handleAddGame,
    casinoCategories,
    categoryFilter,
    setCategoryFilter,
    setCasinoCategoryId,
    subCategories,
    casinoCategoryId,
    setReorderedGame,
    search,
    setSearch,
    aggregatorsOptions,
    selectedAggregatorId,
    setSelectedAggregatorId,
    aggregatorsCategoryOptions,
    selectedAggregatorCategoryId,
    setSelectedAggregatorCategoryId
  } = useGameReorder()
  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>Game Reorder</h3>
        </Col>
        <Col>
          <div className='text-right mb-3'>
            <Button
              variant='outline-success mt-1'
              size='sm'
              onClick={() => handleSave()}
              disabled={reOrderedGame?.count === 0}
            >
              Update
            </Button>
          </div>
        </Col>
      </Row>
      <div className='game-reordering-container'>
        <Card className='p-2 game-reordering-subcontainer'>
          <Row>
            <Col>
              <div className='d-flex justify-content-start align-items-center w-100 gap-3 flex-wrap'>
                <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                  <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                    Category
                  </Form.Label>

                  <Form.Select
                    value={categoryFilter}
                    onChange={(e) => {
                      setReorderedGame({ rows: [], count: 0 })
                      setCategoryFilter(e.target.value)
                    }}
                    size='sm'
                    style={{ maxWidth: '230px' }}
                  >
                    <option value=''>Select Category</option>
                    {casinoCategories && casinoCategories?.rows?.map((c) => (
                      <option key={c?.tenantGameCategoryId} value={c?.tenantGameCategoryId}>{c?.name.EN}</option>
                    ))}
                  </Form.Select>
                </div>
                {categoryFilter &&
                  <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                    <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginLeft: '15px', minWidth: 'fit-content' }}>
                      Sub Category
                    </Form.Label>
                    <Form.Select
                      style={{ marginBottom: '0', marginRight: '15px', maxWidth: '230px' }}
                      size='sm'
                      onChange={(e) => {
                        setCasinoCategoryId(e.target.value)
                        if (reOrderedGame.rows) {
                          setReorderedGame({ rows: [], count: 0 })
                        }
                      }}
                    >
                      <option value=''>All</option>

                      {subCategories && subCategories?.rows?.map((c) => (
                        <option key={c?.tenantGameSubCategoryId} value={c?.tenantGameSubCategoryId}>{c?.name?.EN}</option>
                      ))}
                    </Form.Select>
                  </div>}
                {categoryFilter &&
                  <Col xs='auto' className='d-flex align-items-center' style={{ gap: '10px' }}>
                    <AggregatorList
                      aggregatorOptions={aggregatorsOptions}
                      selectedAggregatorId={selectedAggregatorId}
                      setSelectedAggregatorId={setSelectedAggregatorId}
                    />
                  </Col>}

                {categoryFilter && casinoCategoryId &&

                  <Col className='d-flex mt-1' xs='auto'>
                    <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                      Search
                    </Form.Label>

                    <Form.Control
                      type='search'
                      value={search}
                      placeholder='Search Games'
                      size='sm'
                      style={{ maxWidth: '230px' }}
                      onChange={(event) => setSearch(event.target.value)}
                    />
                  </Col>}

                {categoryFilter &&
                  <Col xs='auto' className='d-flex align-items-center' style={{ gap: '10px' }}>
                    <AggregatorCategories
                      categoriesOptions={aggregatorsCategoryOptions}
                      selectedCategoryId={selectedAggregatorCategoryId}
                      setSelectedCategoryId={setSelectedAggregatorCategoryId}
                    />
                  </Col>}

              </div>
            </Col>
          </Row>

          <div style={{ overflow: 'auto' }}>
            {casinoCategoryId
              ? (
                <>
                  <Table bordered striped hover size='sm' className='text-center mt-4'>
                    <thead className='thead-dark'>
                      <tr>
                        <th>Order ID</th>
                        <th>Game Name (ID)</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id={loading ? 'table-spinner' : ''}>
                      {!loading && casinoGames?.rows?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.name} ({item.categoryGameId})</td>
                            <td>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='success'
                                onClick={() => handleAddGame(item)}
                              >+
                              </Button>
                            </td>
                          </tr>
                        )
                      })}

                      {casinoGames?.rows?.length === 0 && !loading && (
                        <tr><td className='text-danger' colSpan={10}>No Data Found.</td></tr>
                      )}
                    </tbody>

                  </Table>
                </>
                )
              : <p className='text-danger text-center mt-7'> Select Category & Sub Category First </p>}
          </div>
        </Card>
        <Card className='p-2 game-reordering-subcontainer'>
          {reOrderedGame?.count !== 0
            ? (
              <div className='game-reorder'>
                <div className='game-reorder-heading'>
                  {[
                    'ID',
                    'Game Name',
                    'Action'
                  ].map((h, idx) => (
                    <p className={`game-heading-${idx}`} key={h}>{h}</p>
                  ))}
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='list'>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {/* <QuoteList quotes={state.quotes} /> */}
                        {reOrderedGame?.rows?.map(
                          (item, idx) => (
                            <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                              {provided => (
                                <div
                                  className='game-reorder-content'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <p className='game-id'>{idx + 1}</p>
                                  <p className='game-name'>{item.name}</p>
                                  <Button
                                    className='m-1 game-button'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => handRemoveGame(item)}
                                  >X
                                  </Button>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              )
            : <p className='text-danger text-center mt-7'>Game Not Selected</p>}
        </Card>

      </div>

    </>
  )
}
