import { useEffect, useRef, useState } from 'react'
import { getItem } from '../../../utils/storageUtils'
import { useDispatch, useSelector } from 'react-redux'
import { checkIsTenant } from '../../../utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import useCheckPermission from '../../../utils/checkPermission'
import { getCMSLanguagesStart, updateLanguageSupportStart } from '../../../store/redux-slices/languages'
import useLicenseList from '../../../shared/hooks/useLicenseList'

const useAddKeys = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const isTenant = checkIsTenant()
  const tenantId = getItem('tenant-id')
  const { isHidden } = useCheckPermission()
  const { tenantDetails } = useSelector(state => state.login)
  const [availableLanguages, setAvailableLanguages] = useState({})
  const { loading, tenantLanguages } = useSelector(state => state.languages)
  const [selectedClient, setSelectedClient] = useState(state?.myAdminId || '')
  const [selectedPortal, setSelectedPortal] = useState(
    state?.myTenantId ?? (isTenant ? tenantId : '')
  )
  const isInitialRender = useRef(true)

  const { licenseListOptions, loadingLicenseList, selectedLicense, setSelectedLicense } = useLicenseList(selectedPortal)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return // Skip the first render
    }
    setSelectedLicense('')
  }, [selectedPortal, selectedClient])

  useEffect(() => {
    !isTenant && dispatch(getCMSLanguagesStart({ adminId: selectedClient, tenantId: selectedPortal }))
  }, [selectedPortal])

  const setData = () => {
    const lang = {}
    if (isTenant && tenantDetails?.tenantConfig?.allowedLanguages) {
      tenantDetails.tenantConfig.allowedLanguages.forEach(code => { lang[code] = '' })
    } else if (tenantLanguages?.length) {
      tenantLanguages.forEach(code => { lang[code] = '' })
    }
    setAvailableLanguages(lang)
  }

  useEffect(() => setData(), [tenantDetails, tenantLanguages])

  const updateData = (data) => {
    dispatch(updateLanguageSupportStart({ data, isTenant, tenantId: isTenant ? tenantId : selectedPortal || '', licenseId: selectedLicense, navigate, errorCodes: false, state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicense: selectedLicense } }))
  }

  return {
    loading,
    tenantId,
    isHidden,
    navigate,
    updateData,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    availableLanguages,
    licenseListOptions,
    loadingLicenseList,
    selectedLicense,
    setSelectedLicense
  }
}

export default useAddKeys
