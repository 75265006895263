import { useSelector, useDispatch } from 'react-redux'
import { checkIsTenant } from '../../../../utils/constants'
import useAllCountry from '../../../../shared/hooks/useAllCountry'
import useAllOwner from '../../../../shared/hooks/useAllOwner'
import useTenantOwner from '../../../../shared/hooks/useTenantOwner'
import { useEffect, useMemo } from 'react'
import { setRedirectionDetail } from '../../../../store/redux-slices/redirectionManagement'
import { find } from 'lodash'
const useRedirectionModal = ({ ownerId, tenantId }) => {
  const isTenant = checkIsTenant()
  const dispatch = useDispatch()
  const { redirectionDetail } = useSelector((state) => state.redirectionManagement)

  const { ownerOptions, selectedOwner, setSelectedOwner } = useAllOwner({ fetchOwners: !isTenant })
  const { tenantOptions, selectedTenant, setSelectedTenant } = useTenantOwner({
    fetchTenant: selectedOwner?.value,
    adminId: selectedOwner?.value
  })
  const { countryOptions, selectedCountry, setSelectedCountry } = useAllCountry({
    tenantId: selectedTenant?.value || ''
  })

  // Sync state with incoming ownerId, tenantId, and redirectionDetail values
  useEffect(() => {
    if (ownerId) {
      setSelectedOwner(find(ownerOptions, option => String(option.value) === String(ownerId)))
    }
    if (tenantId) {
      setSelectedTenant(find(tenantOptions, option => String(option.value) === String(tenantId)))
    }

    if (redirectionDetail?.countryCodes?.length > 0) {
      setSelectedCountry(find(countryOptions, option => String(option.value) === String(redirectionDetail?.countryCodes)))
    }
    return () => {
      dispatch(setRedirectionDetail({
        ownerId: '', // Reset values
        tenantId: '',
        countryCodes: [],
        redirectionUrl: ''
      }))
    }
  }, [redirectionDetail])

  const initialValue = {
    ownerId: ownerId || redirectionDetail?.ownerId || '',
    tenantId: tenantId || redirectionDetail?.tenantId || '',
    countryCodes: redirectionDetail?.countryCodes?.length > 0 ? [redirectionDetail.countryCodes] : [],
    redirectionUrl: redirectionDetail?.redirectionUrl || '',
    isActive: redirectionDetail?.isActive
  }

  const handleOwnerSelection = ({ selectedValue, name, formik }) => {
    setSelectedOwner(selectedValue)
    setSelectedTenant([])
    formik && formik.setFieldValue(name, selectedValue.value)
    formik && formik.setFieldValue('tenantId', '')
  }

  const handleTenantSelection = ({ selectedValue, name, formik }) => {
    setSelectedTenant(selectedValue)
    formik && formik.setFieldValue(name, selectedValue.value)
  }

  const handleCountrySelection = ({ selectedValue, name, formik }) => {
    setSelectedCountry(selectedValue)
    formik && formik.setFieldValue(name, selectedValue.map(item => item.value))
  }

  // Define the form fields dynamically based on current state and redirection details
  const formFields = useMemo(
    () => [
      {
        label: 'Owner',
        name: 'ownerId',
        control: 'react-select',
        placeholder: 'Select Owner',
        containerClassName: 'form-field-container mt-3',
        labelClassName: 'form-field-label',
        labelRequiredClassName: 'text-danger',
        options: ownerOptions,
        value: selectedOwner,
        onChange: handleOwnerSelection,
        isFieldRequired: !isTenant,
        isDisabled: redirectionDetail?.isView || redirectionDetail?.isEdit
      },
      {
        label: 'Portals',
        name: 'tenantId',
        control: 'react-select',
        placeholder: 'Select Portals',
        containerClassName: 'form-field-container mt-3',
        labelClassName: 'form-field-label',
        labelRequiredClassName: 'text-danger',
        options: tenantOptions,
        value: selectedTenant,
        onChange: handleTenantSelection,
        isFieldRequired: true,
        isDisabled: redirectionDetail?.isView || redirectionDetail?.isEdit
      },
      {
        label: 'Country',
        name: 'countryCodes',
        control: 'react-select',
        placeholder: 'Select Country ',
        containerClassName: 'form-field-container mt-3',
        labelClassName: 'form-field-label',
        labelRequiredClassName: 'text-danger',
        options: countryOptions,
        value: selectedCountry,
        onChange: handleCountrySelection,
        isFieldRequired: true,
        isDisabled: redirectionDetail?.isView,
        isMulti: true
      },
      {
        label: 'Redirection URL',
        type: 'text',
        name: 'redirectionUrl',
        control: 'input',
        placeholder: 'Enter the redirection URL',
        fieldClassName: 'form-control',
        containerClassName: 'form-field-container mt-3',
        labelClassName: 'form-field-label',
        labelRequiredClassName: 'text-danger',
        isFieldRequired: true,
        disabled: redirectionDetail?.isView
      }
    ],
    [
      ownerOptions,
      selectedOwner,
      tenantOptions,
      selectedTenant,
      countryOptions,
      selectedCountry,
      redirectionDetail,
      isTenant
    ]
  )

  return { initialValue, formFields, redirectionDetail }
}

export default useRedirectionModal
