import React from 'react'
import Preloader from '../../../components/Preloader'
import useCustomPayment from './hooks/useCustomPayment'
import AddMethodModal from './components/AddMethodModal'
import { providerCategory } from './components/constants'
import { Button, Form } from '@themesberg/react-bootstrap'
import CategoryReorder from './components/CategoryReorder'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import useCheckPermission from '../../../utils/checkPermission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmationModal from '../../../components/ConfirmationModal'
import PaymentProvidersList from '../../../components/PaymentProvidersList'
import { faMoneyCheckDollarPen, faPlus, faSort, faPaste } from '@fortawesome/pro-solid-svg-icons'
import CopyModal from './components/CopyModal'

export default () => {
  const {
    name,
    data,
    type,
    active,
    status,
    search,
    setType,
    loading,
    category,
    isTenant,
    navigate,
    handleYes,
    showModal,
    setStatus,
    setSearch,
    setCategory,
    reorderModal,
    setShowModal,
    tenantDetails,
    selectedClient,
    selectedPortal,
    newMethodModal,
    copyModal,
    setReorderModal,
    handleShowModal,
    setSelectedClient,
    setSelectedPortal,
    setNewMethodModal,
    setCopyModal,
    handleAggregatorStatusToggle,
    getAggregatorStatus,
    statusToggleLoader
  } = useCustomPayment()

  const { isHidden } = useCheckPermission()

  return (
    <>
      <div>
        <h3 className='me-3'><FontAwesomeIcon icon={faMoneyCheckDollarPen} />&nbsp;Cashier Management</h3>
        <div className='d-flex align-items-center flex-wrap custom-input-group'>

          {!isTenant &&
            <div className='m-1 label-input-block'>
              <ClientFilter
                setSelectedClient={setSelectedClient}
                setSelectedPortal={setSelectedPortal}
                selectedClient={selectedClient}
              />
            </div>}

          {selectedClient && !isTenant && (
            <div className='m-1 label-input-block'>
              <PortalFilter
                setSelectedPortal={setSelectedPortal}
                selectedPortal={selectedPortal}
                selectedClient={selectedClient}
              />
            </div>
          )}

          <div className='m-1 label-input-block'>
            <Form.Label className='m-1'>Select Type</Form.Label>
            <Form.Select
              size='sm'
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value=''>All</option>
              <option value='deposit'>Deposit</option>
              <option value='withdraw'>Withdraw</option>
              <option value='both'>Both</option>
            </Form.Select>
          </div>

          <div className='m-1 label-input-block'>
            <Form.Label className='m-1'>Category</Form.Label>
            <Form.Select
              size='sm'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value=''>All</option>
              {Object.keys(providerCategory).map(categoryKey =>
                <option value={categoryKey} key={categoryKey}>{providerCategory[categoryKey]}</option>)}
            </Form.Select>
          </div>

          <div className='m-1 label-input-block'>
            <Form.Label className='m-1'>Status</Form.Label>
            <Form.Select
              size='sm'
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value=''>All</option>
              <option value='true'>Active</option>
              <option value='false'>In-Active</option>
            </Form.Select>
          </div>

          <div className='m-1 label-input-block'>
            <Form.Label className='m-1'>Search</Form.Label>
            <Form.Control
              type='search'
              placeholder='Search Provider'
              size='sm'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <Button
          size='sm'
          className='mb-2 mt-2 mx-2 align-self-end'
          variant='success'
          hidden={isHidden({ module: { key: 'CashierManagement', value: 'C' } })}
          onClick={() => {
            setNewMethodModal(true)
            !isTenant && setSelectedClient('')
            !isTenant && setSelectedPortal('')
          }}
        >Add Payment Method <FontAwesomeIcon icon={faPlus} />
        </Button>

        <Button
          size='sm'
          className='mb-2 mt-2 align-self-end'
          variant='success'
          hidden={isHidden({ module: { key: 'CashierManagement', value: 'CP' } })}
          onClick={() => {
            setCopyModal(true)
            !isTenant && setSelectedClient('')
            !isTenant && setSelectedPortal('')
          }}
        >Copy Settings <FontAwesomeIcon icon={faPaste} />
        </Button>

        {(isTenant ? true : selectedPortal) && (
          <div className='d-flex align-self-stretch flex-wrap mt-2 mb-2'>
            <Button
              size='sm'
              className='m-1 align-self-end'
              variant='success'
              hidden={isHidden({ module: { key: 'CashierManagement', value: 'U' } })}
              onClick={() => setReorderModal(true)}
            >Category Order <FontAwesomeIcon icon={faSort} />
            </Button>
            <Button
              size='sm'
              className='m-1 align-self-end'
              variant='success'
              hidden={isHidden({
                module: { key: 'CashierManagement', value: 'U' }
              })}
              onClick={() =>
                navigate(
                  `/${isTenant ? 'tenant' : 'super-admin'}/cashier-management/reorder/${isTenant ? tenantDetails?.adminUserId : selectedClient}/${isTenant ? tenantDetails?.tenantId : selectedPortal}`,
                  {
                    state: {
                      type,
                      status,
                      search,
                      category,
                      adminId: selectedClient,
                      tenantId: selectedPortal
                    }
                  }
                )}
            >
              Provider Order <FontAwesomeIcon icon={faSort} />
            </Button>
          </div>
        )}
      </div>

      {loading
        ? (
          <Preloader />
          )
        : (
          <PaymentProvidersList
            data={data}
            state={{
              type,
              status,
              search,
              category,
              adminId: selectedClient,
              tenantId: selectedPortal
            }}
            isTenant={isTenant}
            navigate={navigate}
            selectedPortal={selectedPortal}
            selectedClient={selectedClient}
            handleShowModal={handleShowModal}
            handleAggregatorStatusToggle={handleAggregatorStatusToggle}
            getAggregatorStatus={getAggregatorStatus}
            statusToggleLoader={statusToggleLoader}
          />
          )}

      {showModal && (
        <ConfirmationModal
          setShow={setShowModal}
          show={showModal}
          handleYes={handleYes}
          active={active}
          name={name}
        />
      )}

      {reorderModal && (
        <CategoryReorder
          setShow={setReorderModal}
          show={reorderModal}
          adminId={isTenant ? tenantDetails?.adminUserId : selectedClient}
          tenantId={isTenant ? tenantDetails?.tenantId : selectedPortal}
        />
      )}

      {newMethodModal && (
        <AddMethodModal setShow={setNewMethodModal} show={newMethodModal} />
      )}

      {copyModal && (
        <CopyModal setShow={setCopyModal} show={copyModal} />
      )}
    </>
  )
}
