import { faEdit, faEye, faWindowClose, faCheckSquare } from '@fortawesome/pro-solid-svg-icons'
import { Button, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getLicenseListStart, getLicenseListSuccess, startSettingDefaultLicense } from '../../../../store/redux-slices/licenseManagement'
import { SuperAdminRoutes, TenantRoutes } from '../../../../routes'
import { checkIsTenant } from '../../../../utils/constants'
import { getCountriesStart } from '../../../../store/redux-slices/fetchData'
import Trigger from '../../../../components/OverlayTrigger'

// Custom hook for license management
const useLicenseManagement = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [data, setData] = useState({})

  const { tenantDetails } = useSelector((state) => state.login)
  const { loadingLicenseList, licenseList, licenseListCount } = useSelector((state) => state.licenseManagement)

  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState(tenantDetails?.tenantId || '')

  // Pagination and search state
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState(search)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [defaultLicenseId, setDefaultLicenseId] = useState('')
  const { countries: allCountries } = useSelector((state) => state.fetch)
  // Calculate total pages
  const totalPages = useMemo(() => Math.ceil(licenseListCount / limit), [licenseList, limit])

  // Update selected portal when tenant details change
  useEffect(() => {
    if (tenantDetails?.tenantId) {
      setSelectedPortal(tenantDetails?.tenantId)
    }
  }, [tenantDetails])

  // Debounce search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search)
    }, 500)

    return () => clearTimeout(handler)
  }, [search])

  // Fetch license list based on filters
  useEffect(() => {
    dispatch(
      getLicenseListStart({
        tenantId: selectedPortal || '',
        limit,
        page,
        search: debouncedSearch
      })
    )

    return () => dispatch(getLicenseListSuccess(null))
  }, [selectedPortal, limit, page, debouncedSearch])

  // Fetch license list based on filters
  const fetchLicenseList = () => {
    setShowConfirmationModal(false)
    dispatch(
      getLicenseListStart({
        tenantId: selectedPortal || '',
        limit,
        page,
        search: debouncedSearch
      })
    )
  }

  // Memoize actions array
  const actions = useMemo(
    () => [
      {
        label: 'Edit',
        icon: faEye,
        variant: 'info',
        handler: (row) => {
          const viewLicenseUrl = checkIsTenant()
            ? `${TenantRoutes.ViewLicense.replace(':licenseId', row.id)}`
            : `${SuperAdminRoutes.ViewLicense.replace(':licenseId', row.id)}`
          navigate(viewLicenseUrl, { state: { licenseId: row.id || '', tenantId: row.tenantId } })
        }
      },
      {
        label: 'Edit',
        icon: faEdit,
        variant: 'warning',
        handler: (row) => {
          const editLicenseUrl = checkIsTenant()
            ? `${TenantRoutes.EditLicense.replace(':licenseId', row.id)}`
            : `${SuperAdminRoutes.EditLicense.replace(':licenseId', row.id)}`
          navigate(editLicenseUrl, { state: { licenseId: row.id || '', tenantId: row.tenantId } })
        }
      }
    ],
    [navigate]
  )
  useEffect(() => {
    dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '', kycMethod: '' }))
  }, [])

  const getCountryNames = (codes) => {
    if (!Array.isArray(codes)) return 'N/A' // Handle invalid input

    return codes
      .map((code) => {
        const country = allCountries?.find((c) => c.code === code)
        return country?.name?.EN || code // Use country name if found, otherwise fallback to code
      })
      .join(', ')
  }

  const CountryRenderer = ({ codes }) => {
    return <span>{allCountries?.length > 0 ? getCountryNames(codes) : ''}</span>
  }

  const handleSetLicenseAsDefault = () => {
    const data = {
      licenseId: String(defaultLicenseId),
      isDefault: true,
      tenantId: selectedPortal || ''
    }
    dispatch(startSettingDefaultLicense({ data, fetchLicenseList }))
  }

  // Memoize columns configuration
  const columns = useMemo(
    () => [
      { key: 'licenseNo', label: 'License No', isTruncated: true, width: '120px' },
      { key: 'name', label: 'License Name', isTruncated: true, width: '150px' },
      {
        key: 'Tenant',
        label: 'Tenant',
        isTruncated: true,
        width: '150px',
        render: (rowData) => rowData?.Tenant?.name
      },
      {
        key: 'country',
        label: 'Country',
        isTruncated: true,
        width: '150px',
        render: (rowData) => <CountryRenderer codes={rowData?.countryCodes || []} />
      },
      {
        key: 'default',
        label: 'Default',
        width: '120px',
        render: (rowData) => (
          <ButtonGroup>
            {rowData?.isDefault
              ? (
                <Trigger message='Default License'>
                  <Button className='m-1' size='sm' variant='success'>
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </Button>
                </Trigger>
                )
              : (
                <Trigger message='Set Default License'>
                  <Button
                    className='m-1'
                    size='sm'
                    variant='danger'
                    onClick={() => {
                      setDefaultLicenseId(rowData.id)
                      setShowConfirmationModal(true)
                    }}
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                  </Button>
                </Trigger>
                )}
          </ButtonGroup>
        )
      },
      {
        key: 'actions',
        label: 'Actions',
        render: (rowData) => (
          <ButtonGroup>
            {actions.map((action, idx) => (
              <Button
                key={idx}
                className='m-1'
                size='sm'
                variant={action.variant}
                onClick={() => action.handler(rowData)}
              >
                <FontAwesomeIcon icon={action.icon} />
              </Button>
            ))}
          </ButtonGroup>
        ),
        width: '150px'
      }
    ],
    [actions, allCountries]
  )

  const actionsGlobal = useMemo(
    () => [
      {
        label: 'Edit',
        icon: faEdit,
        variant: 'warning',
        handler: (row) => {
          setData(row)
          setShow(true)
        }
      }
    ],
    [navigate]
  )

  const columnsGlobal = useMemo(
    () => [
      { key: 'id', label: 'ID', isTruncated: true, width: '120px' },
      { key: 'name', label: 'License Name', isTruncated: true, width: '150px' },
      {
        key: 'depositLimitExclusion',
        label: 'Deposit Limit Exclusion',
        isTruncated: true,
        width: '150px',
        render: ({ depositLimitExclusion }) => (
          <p>{depositLimitExclusion ? 'Yes' : 'No'}</p>
        )
      },
      {
        key: 'actions',
        label: 'Actions',
        render: (rowData) => (
          <ButtonGroup>
            {actionsGlobal.map((action, idx) => (
              <Button
                key={idx}
                className='m-1'
                size='sm'
                variant={action.variant}
                onClick={() => action.handler(rowData)}
              >
                <FontAwesomeIcon icon={action.icon} />
              </Button>
            ))}
          </ButtonGroup>
        ),
        width: '150px'
      }
    ],
    [actionsGlobal]
  )

  return {
    columns: ((selectedClient && selectedPortal) || checkIsTenant()) ? columns : columnsGlobal,
    loadingLicenseList,
    licenseList,
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    totalPages,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    navigate,
    show,
    setShow,
    data,
    setData,
    licenseListCount,
    showConfirmationModal,
    setShowConfirmationModal,
    handleSetLicenseAsDefault,
    defaultLicenseId,
    setDefaultLicenseId
  }
}

export default useLicenseManagement
