import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import map from '@highcharts/map-collection/custom/world.geo.json'
import { getAdminUserId, getAllTenantOptionsSelected, checkIsTenant, getDemographicSortedValue, getTenantId } from '../../../../utils/constants'
import { countryFilter } from '../../../../utils/countryFilter'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getItem, getLoginToken } from '../../../../utils/storageUtils'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { getDemographicReportStart } from '../../../../store/redux-slices/dashboard'

const useDemographicReport = ({ selectedPortal = '', selectedClient = '', accordionState }) => {
  const dispatch = useDispatch()
  const { demogLoading: loading, demogData } = useSelector((state) => state.dashboard)
  const [formatedData, setFormatedData] = useState([])
  const [dateOptions, setDateOptions] = useState('today')
  const isInitialRender = useDidMountEffect()
  const [demographData, setDemographData] = useState(demogData)
  const [sortBy, setSortBy] = useState({
    signUpCount: '',
    newDepositors: '',
    acquisition: '',
    reDepositors: '',
    totalDepositors: '',
    totalDeposit: '',
    isSortingField: null
  })
  const isTenant = checkIsTenant()

  const [hover, setHover] = useState({
    signUpCount: false,
    newDepositors: false,
    acquisition: false,
    reDepositors: false,
    totalDepositors: false,
    totalDeposit: false
  })

  const getColor = (value) => {
    if (value <= 1000) return '#b4ecff'
    else if (value <= 100000) return 'rgb(0, 217, 255)'
    else return '#00a2ff'
  }

  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const formatDataHandler = (list) => {
    const data = []

    list && list.map((item) => {
      const { countryName } = countryFilter(item.country_code)
      const row = {
        z: item.signUpCount,
        code: item.country_code,
        countryName,
        color: getColor(item.signUpCount)
      }
      data.push(row)
      return null
    })

    setFormatedData(data)
  }

  useEffect(() => {
    if (demogData) formatDataHandler(demogData)
  }, [demogData])

  useEffect(() => {
    const orderBy = getDemographicSortedValue[sortBy.isSortingField] || ''
    const { signUpCount = '', newDepositors = '', acquisition = '', reDepositors = '', totalDepositors = '', totalDeposit = '' } = sortBy
    const sort = signUpCount || newDepositors || acquisition || reDepositors || totalDepositors || totalDeposit

    if (demogData && orderBy && sort) {
      const tableData = JSON.parse(JSON.stringify(demogData))
      const dynamicSort = (property) => (a, b) => {
        if (sort === 'desc') {
          return parseFloat(b[property]) - parseFloat(a[property])
        } else {
          return parseFloat(a[property]) - parseFloat(b[property])
        }
      }
      const sortedTableData = tableData.sort(dynamicSort(orderBy))
      setDemographData(sortedTableData)
    } else setDemographData(demogData)
  }, [sortBy, demogData])

  const loadDemogData = () => {
    accordionState.demographicReport && dispatch(getDemographicReportStart({
      isTenant,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      tenantId: isTenant ? getAllTenantOptionsSelected() ? '' : getTenantId() : selectedPortal,
      adminId: isTenant ? getAdminUserId() : selectedClient,
      dateOptions
    }))
  }

  useEffect(() => {
    loadDemogData()
  }, [state, selectedClient, selectedPortal, accordionState.demographicReport])

  useEffect(() => {
    if (dateOptions !== 'custom' && !isInitialRender) {
      loadDemogData()
    }
  }, [dateOptions])

  const getCsvDownloadUrl = () => {
    return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/demographic?${!isTenant ? `adminId=${selectedClient}&` : ''}startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&csvDownload=true&token=${getLoginToken()}&dateOptions=${dateOptions}&tenantId=${isTenant ? getItem('tenant-id') : selectedPortal}`
  }

  const options = {
    chart: {
      map
    },

    title: {
      text: ''
    },
    series: [
      {
        name: 'Signups Report',
        data: formatedData,
        joinBy: ['iso-a2', 'code'],
        tooltip: {
          pointFormat: '{point.properties.hc-a2}: {point.countryName} Users'
        }
      }
    ]
  }

  return {
    hover,
    state,
    sortBy,
    options,
    loading,
    setHover,
    setState,
    setSortBy,
    demogData,
    dateOptions,
    formatedData,
    loadDemogData,
    demographData,
    setDateOptions,
    setDemographData,
    getCsvDownloadUrl
  }
}

export default useDemographicReport
