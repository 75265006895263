import { Col, Form as BForm, Row, Table, Button } from '@themesberg/react-bootstrap'
import debounce from 'lodash/debounce'
import React from 'react'
import useAffiliateTable from './useAffiliateTable'

/**
 * @param {BannedGamesAffiliatesProps} props
 */
const AffiliateTable = ({ details, providerDetails, tenantId, updateData }) => {
  const {
    affiliates,
    setSearch,
    selectedAffiliate,
    handleSubmit,
    onChangeAffiliateSelectAll,
    getOnSelectChangeCallback
  } = useAffiliateTable(tenantId, providerDetails, updateData)

  const onSearchChange = React.useRef(
    debounce((event) => {
      setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))
    }, 500)
  ).current

  return (
    <>

      <Row>
        <Col xs='auto'>
          <BForm.Label>
            Search Affiliates
          </BForm.Label>
          <BForm.Control
            type='search'
            placeholder='Search Username'
            size='sm'
            style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
            onChange={onSearchChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='banned-games-table'>
            <Table bordered striped responsive hover size='sm' className='text-center scrollable'>
              <thead className='thead-dark'>
                <tr>
                  {[
                    'ID',
                    'Username',
                    'First Name',
                    'Last Name',
                    'Email',
                    'Active'
                  ].map((h) => (
                    h === 'ID'
                      ? (
                        <th key={h}>
                          {affiliates && affiliates?.length > 0 &&
                            <input
                              className='banned-games-checkbox' type='checkbox'
                              onChange={onChangeAffiliateSelectAll}
                              checked={affiliates.length === selectedAffiliate.length}
                            />}{h}
                        </th>)
                      : <th key={h}>{h}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {
                  affiliates && affiliates.length
                    ? affiliates.map(({
                      username,
                      affiliateId,
                      firstName,
                      lastName,
                      email,
                      isActive,
                      userIdAffiliate
                    }) => {
                      return (
                        <tr key={affiliateId}>
                          <td>
                            <input
                              className='banned-games-checkbox'
                              type='checkbox'
                              checked={Boolean(selectedAffiliate.find((selectedAffiliateId) => selectedAffiliateId === String(affiliateId)))}
                              name={`affiliate-${affiliateId}`}
                              onChange={getOnSelectChangeCallback(String(affiliateId))}
                            />
                            {userIdAffiliate}
                          </td>
                          <td>{username || 'NA'}</td>
                          <td>{firstName || 'NA'}</td>
                          <td>{lastName || 'NA'}</td>
                          <td>{email || 'NA'}</td>
                          <td>{isActive ? 'Yes' : 'No'}</td>
                        </tr>
                      )
                    })
                    : (
                      <tr>
                        <td colSpan={7} className='text-danger text-center'>
                          No data found
                        </td>
                      </tr>)
                }
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      <Row>
        <div
          className='mt-4 d-flex justify-content-end align-items-center'
        >
          <div>
            <Button
              variant='outline-success'
              onClick={handleSubmit}
              hidden={details}
            >
              Submit
            </Button>
          </div>
        </div>
      </Row>

    </>
  )
}

export default AffiliateTable
