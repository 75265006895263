import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getPaymentAggregatorsStart,
    getPaymentAggregatorsSuccess,
    getPaymentAggregatorsFailure,
    getPaymentProviderStart,
    getPaymentProviderSuccess,
    getPaymentProviderFailure,
    getProviderDetailsStart,
    getProviderDetailsSuccess,
    getProviderDetailsFailure,
    configProviderSettingsStart,
    configProviderSettingsComplete,
    updatePaymentProviderStatusStart,
    updatePaymentProviderStatusComplete,
    reOrderPaymentProvidersStart,
    reOrderPaymentProvidersComplete,
    getPaymentCategoriesStart,
    getPaymentCategoriesSuccess,
    getPaymentCategoriesFailure,
    reOrderPaymentCategoriesStart,
    reOrderPaymentCategoriesComplete,
    getTransactionPaymentCategoriesStart,
    getTransactionPaymentCategoriesSuccess,
    getTransactionPaymentCategoriesFailure,
    getTransactionReportingPaymentCategoriesStart,
    getTransactionReportingPaymentCategoriesSuccess,
    getTransactionReportingPaymentCategoriesFailure,
    addPaymentMethodStart,
    addPaymentMethodComplete,
    copySettingsStart,
    copySettingsComplete,
    getPaymentGroupsStart,
    getPaymentGroupsComplete,
    restrictPlayerMethodsStart,
    restrictPlayerMethodsComplete,
    clearPaymentMethods,
    updateAggregatorStatusStart,
    updateAggregatorStatusComplete
  },
  reducer
} = createSlice({
  name: 'payment',
  initialState: {
    loading: false,
    providerLoading: false,
    paymentAggregators: {},
    paymentProviders: [],
    providerDetails: {},
    detailsLoading: false,
    paymentCategories: [],
    paymentCategoriesLoading: false,
    transactionPaymentCategories: null,
    transactionReportingPaymentCategories: null,
    paymentGroups: null,
    formLoading: false
  },
  reducers: {
    getPaymentAggregatorsStart: (state) => ({
      ...state,
      loading: true
    }),
    getPaymentAggregatorsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      paymentAggregators: payload
    }),
    getPaymentAggregatorsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getPaymentProviderStart: (state) => ({
      ...state,
      providerLoading: true
    }),
    getPaymentProviderSuccess: (state, { payload }) => ({
      ...state,
      providerLoading: false,
      paymentProviders: payload
    }),
    getPaymentProviderFailure: (state) => ({
      ...state,
      providerLoading: false
    }),
    getProviderDetailsStart: (state) => ({
      ...state,
      detailsLoading: true
    }),
    getProviderDetailsSuccess: (state, { payload }) => ({
      ...state,
      detailsLoading: false,
      providerDetails: payload
    }),
    getProviderDetailsFailure: (state) => ({
      ...state,
      detailsLoading: false
    }),
    configProviderSettingsStart: (state) => ({
      ...state,
      loading: true
    }),
    configProviderSettingsComplete: (state) => ({
      ...state,
      loading: false
    }),
    updatePaymentProviderStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updatePaymentProviderStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    reOrderPaymentProvidersStart: (state) => ({
      ...state,
      loading: true
    }),
    reOrderPaymentProvidersComplete: (state) => ({
      ...state,
      loading: false
    }),
    getPaymentCategoriesStart: (state) => ({
      ...state,
      paymentCategoriesLoading: true
    }),
    getPaymentCategoriesSuccess: (state, { payload }) => ({
      ...state,
      paymentCategoriesLoading: false,
      paymentCategories: payload
    }),
    getPaymentCategoriesFailure: (state) => ({
      ...state,
      paymentCategoriesLoading: false
    }),
    reOrderPaymentCategoriesStart: (state) => ({
      ...state,
      paymentCategoriesLoading: true
    }),
    reOrderPaymentCategoriesComplete: (state) => ({
      ...state,
      paymentCategoriesLoading: false
    }),
    getTransactionPaymentCategoriesStart: (state) => ({
      ...state,
      loading: true
    }),
    getTransactionPaymentCategoriesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      transactionPaymentCategories: payload
    }),
    getTransactionPaymentCategoriesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTransactionReportingPaymentCategoriesStart: (state) => ({
      ...state,
      loading: true
    }),
    getTransactionReportingPaymentCategoriesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      transactionReportingPaymentCategories: payload
    }),
    getTransactionReportingPaymentCategoriesFailure: (state) => ({
      ...state,
      loading: false
    }),
    addPaymentMethodStart: (state) => ({
      ...state,
      formLoading: true
    }),
    copySettingsStart: (state) => ({
      ...state,
      formLoading: true
    }),
    addPaymentMethodComplete: (state) => ({
      ...state,
      formLoading: false
    }),
    copySettingsComplete: (state) => ({
      ...state,
      formLoading: false
    }),
    getPaymentGroupsStart: (state) => ({
      ...state,
      formLoading: true
    }),
    getPaymentGroupsComplete: (state, { payload }) => ({
      ...state,
      paymentGroups: payload,
      formLoading: false
    }),
    restrictPlayerMethodsStart: (state) => ({
      ...state,
      providerLoading: true
    }),
    restrictPlayerMethodsComplete: (state) => ({
      ...state,
      providerLoading: false
    }),
    clearPaymentMethods: (state) => ({
      ...state,
      paymentProviders: []
    }),
    updateAggregatorStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateAggregatorStatusComplete: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getPaymentAggregatorsStart,
  getPaymentAggregatorsSuccess,
  getPaymentAggregatorsFailure,
  getPaymentProviderStart,
  getPaymentProviderSuccess,
  getPaymentProviderFailure,
  getProviderDetailsStart,
  getProviderDetailsSuccess,
  getProviderDetailsFailure,
  configProviderSettingsStart,
  configProviderSettingsComplete,
  updatePaymentProviderStatusStart,
  updatePaymentProviderStatusComplete,
  reOrderPaymentProvidersStart,
  reOrderPaymentProvidersComplete,
  getPaymentCategoriesStart,
  getPaymentCategoriesSuccess,
  getPaymentCategoriesFailure,
  reOrderPaymentCategoriesStart,
  reOrderPaymentCategoriesComplete,
  getTransactionPaymentCategoriesStart,
  getTransactionPaymentCategoriesSuccess,
  getTransactionPaymentCategoriesFailure,
  getTransactionReportingPaymentCategoriesStart,
  getTransactionReportingPaymentCategoriesSuccess,
  getTransactionReportingPaymentCategoriesFailure,
  addPaymentMethodStart,
  addPaymentMethodComplete,
  copySettingsStart,
  copySettingsComplete,
  getPaymentGroupsStart,
  getPaymentGroupsComplete,
  restrictPlayerMethodsStart,
  restrictPlayerMethodsComplete,
  clearPaymentMethods,
  updateAggregatorStatusStart,
  updateAggregatorStatusComplete
}
