import React from 'react'
import CustomSelect from '../../shared/CustomSelect'
const AggregatorList = ({ aggregatorOptions = '', selectedAggregatorId = '', setSelectedAggregatorId, labelClassName = '' }) => {
  return (
    <CustomSelect
      label='Aggregator'
      size='sm'
      options={aggregatorOptions || []}
      value={selectedAggregatorId}
      onChange={setSelectedAggregatorId}
      placeholder='Please Select Aggregator'
      noOptionsMessage='No Aggregator Found'
      labelClassName={labelClassName}
    />
  )
}

export default AggregatorList
