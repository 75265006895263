import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPortalsStart } from '../../../../store/redux-slices/tenants'
import { isTenant } from '../../../../utils/constants'
import { getPaymentAggregatorsStart } from '../../../../store/redux-slices/payment'

const useAddPaymentMethod = () => {
  const { clientsFilterData, portalsFilterData: tenantData, tenantsList } = useSelector(state => state.tenants)
  const { tenantDetails } = useSelector(state => state.login)
  const { paymentGroups, paymentAggregators } = useSelector(state => state.payment)
  const [portalOptions, setPortalOptions] = useState([])
  const [selectedClient, setSelectedClient] = useState(isTenant ? tenantDetails?.adminUserId : '')
  const dispatch = useDispatch()
  const [groupOptions, setGroupOptions] = useState([])
  const [proceed, setProceed] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({})
  const portalsFilterData = isTenant ? tenantsList : tenantData
  const [selectedPortals, setSelectedPortals] = useState([])

  useEffect(() => {
    if (selectedPortals.length) {
      dispatch(getPaymentAggregatorsStart({ isTenant, tenantId: selectedPortals, isMultiTenant: true }))
    }
  }, [selectedPortals])

  useEffect(() => {
    let options = []
    if (portalsFilterData && (selectedClient || isTenant)) {
      options = portalsFilterData.rows.map((portal) => { return ({ label: portal.name, value: portal.tenantId }) })
      setPortalOptions(options)
    }
  }, [portalsFilterData, selectedClient])

  useEffect(() => {
    if (selectedClient && !isTenant) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }))
    }
  }, [selectedClient])

  useEffect(() => {
    if (paymentGroups?.length > 0) {
      const options = []
      for (const g of paymentGroups) {
        if (g !== '' && g !== null) {
          options.push({ label: g, value: g })
        }
      }
      setGroupOptions(options)
    }
  }, [paymentGroups])

  return {
    clientsFilterData,
    portalOptions,
    setSelectedClient,
    selectedClient,
    isTenant,
    dispatch,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup,
    adminId: tenantDetails?.adminUserId,
    proceed,
    setProceed,
    selectedPortals,
    setSelectedPortals,
    paymentAggregators
  }
}

export default useAddPaymentMethod
