import React from 'react'
import { Form } from '@themesberg/react-bootstrap'
import useClientFilter from './useClientFilter'

const ClientFilter = ({
  cms = false,
  setFieldValue,
  selectedClient,
  refetch = true,
  cmsData = false,
  disabled = false,
  setSelectedClient,
  hasAllOptions = true,
  setSelectedPortal = false,
  fieldName = 'adminId',
  label = 'All'
}) => {
  const { clientsFilterData } = useClientFilter({ refetch })

  return (
    <>
      <Form.Label className='m-1'>Owner</Form.Label>
      <Form.Select
        onChange={(e) => {
          setSelectedClient(e.target.value)
          setSelectedPortal && setSelectedPortal('')
          setFieldValue && setFieldValue(fieldName, e.target.value)
        }}
        style={{ width: '220px' }}
        value={selectedClient}
        size={cmsData ? '' : 'sm'}
        disabled={cms || disabled}
      >
        <option value='' disabled={!hasAllOptions}>
          {hasAllOptions ? label : 'Choose Tenant Owner'}
        </option>

        {clientsFilterData?.count === 0 && (
          <option value='' disabled>
            No Tenant Owners Available
          </option>
        )}

        {clientsFilterData?.rows?.map(
          ({ adminUserId, firstName, lastName }) => (
            <option key={adminUserId} value={adminUserId}>
              {`${firstName} ${lastName}`}
            </option>
          )
        )}
      </Form.Select>
    </>
  )
}

export default ClientFilter
