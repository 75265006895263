import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCountryListStart } from '../../../../store/redux-slices/licenseManagement'

// Custom hook for managing country-related logic and state
const useCountries = (selectedPortal) => {
  const dispatch = useDispatch()
  const { countryList } = useSelector(state => state.licenseManagement)
  const [selectedCountry, setSelectedCountry] = useState(null)

  useEffect(() => {
    if (selectedPortal) {
      dispatch(getCountryListStart(selectedPortal))
    }
  }, [selectedPortal])

  // Memoized options for country selection
  const countryOptions = useMemo(() => {
    if (countryList?.unlicensedCountries?.length > 0) {
      return countryList.unlicensedCountries.map((countryCode) => ({
        label: countryCode.name, // Country code as label
        value: countryCode.code // Country code as value
      }))
    }
    return []
  }, [countryList])

  return {
    countryOptions,
    selectedCountry,
    setSelectedCountry
  }
}

export default useCountries
