import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAllTenantsListStart } from '../../../store/redux-slices/tenants'
import { debounce } from '../../../utils/debounce'
import { getItem, setItem } from '../../../utils/storageUtils'
import { getLicenseListStart } from '../../../store/redux-slices/licenseManagement'
import { areRoutesIncluded } from '../../../utils/helpers'
import { getAllTenantOptionsSelected, getTenantId, isTenant, routesToCheck } from '../../../utils/constants'

const useHeader = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const allOption = {
    tenantId: 'all',
    name: 'All',
    domain: '',
    isActive: true,
    tenantConfig: { logoUrl: '' }
  }
  const { loading, tenantsList, selectedLicenseId } = useSelector((state) => state.tenants)

  const [tenantDetails, setTenantDetails] = useState()

  const [showAllOptions, setShowAllOptions] = useState(false)

  const location = useLocation()

  const { licenseList } = useSelector((state) => state.licenseManagement)

  const tenantChangeHandler = (id) => {
    setItem('tenant-id', id)
    const details = tenantsList?.rows?.filter((item) => item.tenantId === Number(id))
    setTenantDetails(details?.length > 0 ? details[0] : allOption)
  }

  useEffect(() => {
    setShowAllOptions(areRoutesIncluded(routesToCheck))
  }, [location])

  useEffect(() => {
    if (showAllOptions) {
      if (getItem('allTenantOptionsSelected') === 'all' || !getItem('allTenantOptionsSelected')) {
        setItem('tenant-id', getTenantId() || tenantsList?.rows?.[0]?.tenantId)
        if (!getItem('allTenantOptionsSelected')) {
          setItem('allTenantOptionsSelected', 'all')
        }
        setTenantDetails(allOption)
      } else {
        tenantChangeHandler(getTenantId() || tenantsList?.rows?.[0]?.tenantId)
      }
    } else {
      tenantChangeHandler(getTenantId() || tenantsList?.rows?.[0]?.tenantId)
    }
  }, [tenantsList, showAllOptions])

  useEffect(() => {
    if (tenantsList === null) {
      debounce(dispatch(getAllTenantsListStart({})), 1000)
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname.includes('/tenant/dashboard')) {
      dispatch(
        getLicenseListStart({
          tenantId: isTenant ? getAllTenantOptionsSelected() ? '' : getTenantId() : ''
        })
      )
    }
  }, [tenantDetails])

  const licenseListOptions = useMemo(() => {
    return licenseList
      ? licenseList.map((item) => ({
        value: item.id,
        label: item.name
      }))
      : []
  }, [licenseList])

  return {
    navigate,
    loading,
    tenantsList,
    tenantDetails,
    tenantChangeHandler,
    licenseListOptions,
    selectedLicenseId,
    dispatch,
    showAllOptions
  }
}

export default useHeader
