import React from 'react'

const FieldLabel = ({
  name,
  label,
  required = true,
  labelClassName = null,
  labelRequiredClassName = 'text-danger'
}) => {
  return (
    <>
      <label htmlFor={name} className={labelClassName}>
        {label}
        {required && (
          <span className={labelRequiredClassName}> *</span>
        )}
      </label>
    </>
  )
}

export default FieldLabel
