import React from 'react'
import { Form } from '@themesberg/react-bootstrap'

const BootStrapSelect = ({
  options = [],
  onChange,
  value = '',
  disabled = false,
  placeholder = '',
  label = '',
  noOptionsMessage = '',
  labelClassName = 'm-1',
  selectClassName = '',
  size = 'sm',
  style // Allow parent to pass custom style
}) => {
  const defaultStyle = { width: '220px' } // Default style for the select

  return (
    <>
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <Form.Select
        onChange={onChange}
        value={value}
        disabled={disabled}
        className={selectClassName}
        size={size}
        style={style || defaultStyle} // Use style from props or fallback to default
      >
        {/* Default placeholder option */}
        <option value=''>{placeholder}</option>

        {/* No options available message */}
        {options.length === 0 && (
          <option value='' disabled>
            {noOptionsMessage}
          </option>
        )}

        {/* Render dynamic options */}
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Form.Select>
    </>
  )
}

export default BootStrapSelect
