import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCasinoGameStart, getAllCasinoProvidersStart, updateCasinoGameStart } from '../../../../store/redux-slices/superAdminCasinoManagement'

const useCreateCasinoGame = ({
  aggregatorId,
  aggregatorCategoryId
}) => {
  const dispatch = useDispatch()
  const { casinoProvidersData, loading } = useSelector(
    (state) => state.superAdminCasino
  )

  useEffect(() => {
    dispatch(getAllCasinoProvidersStart({ limit: '', pageNo: '', aggregatorId: '' }))
  }, [])

  const updateCasinoGame = ({
    data,
    casinoProviderId,
    limit,
    pageNo
  }) => dispatch(
    updateCasinoGameStart({
      data,
      casinoProviderId,
      limit,
      pageNo,
      aggregatorId,
      aggregatorCategoryId
    })
  )

  const createCasinoGame = ({
    data,
    casinoProviderId,
    limit,
    pageNo
  }) =>
    dispatch(
      createCasinoGameStart({
        data,
        casinoProviderId,
        limit,
        pageNo,
        aggregatorId,
        aggregatorCategoryId
      })
    )

  return {
    casinoProvidersData, loading, updateCasinoGame, createCasinoGame
  }
}

export default useCreateCasinoGame
