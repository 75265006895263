import React from 'react'

function FieldError ({ children }) {
  return (
    <div className='error-container'>
      <div className='text-danger'>{children}</div>
    </div>
  )
}

export default FieldError
