export const getBaseLicenseFormFields = (
  selectedOwner,
  selectedPortal,
  selectedCountry,
  selectedLicense,
  ownerOptions,
  portalOptions,
  countryOptions,
  licenseListOptions,
  setSelectedOwner,
  setSelectedPortal,
  setSelectedCountry,
  handleLicenseSelection,
  isTenant,
  isLicenseView,
  isEdit = false
) => ([
  {
    label: 'Owner',
    type: 'select',
    name: 'ownerId',
    control: 'select',
    placeholder: 'Select Owner',
    options: ownerOptions,
    value: selectedOwner,
    onChange: setSelectedOwner,
    isFieldRequired: !isTenant,
    disabled: isLicenseView || isEdit
  },
  {
    label: 'Portals',
    type: 'select',
    name: 'tenantId',
    control: 'select',
    placeholder: 'Select Portals',
    options: portalOptions,
    value: selectedPortal,
    onChange: setSelectedPortal,
    isFieldRequired: !isTenant,
    disabled: isLicenseView || isEdit
  },
  {
    label: 'License Number',
    type: 'text',
    name: 'licenseNo',
    control: 'input',
    placeholder: 'Enter your License Number',
    required: false,
    isFieldRequired: true,
    disabled: isLicenseView
  },
  {
    label: 'License Name',
    type: 'select',
    name: 'licenseId',
    control: 'createSelect',
    placeholder: 'Enter License Name',
    options: licenseListOptions,
    value: selectedLicense,
    onChange: handleLicenseSelection,
    isFieldRequired: !isEdit,
    disabled: isLicenseView
  },
  {
    label: 'License Name',
    type: 'text',
    name: 'name',
    control: 'input',
    placeholder: 'Enter License Name',
    isFieldRequired: isEdit,
    disabled: isLicenseView || isEdit
  },
  {
    label: 'Country Codes',
    type: 'select',
    name: 'countryCodes',
    control: 'select',
    placeholder: 'Select Country Codes',
    options: countryOptions,
    value: selectedCountry,
    onChange: setSelectedCountry,
    isMulti: true,
    isFieldRequired: true,
    disabled: isLicenseView
  },
  {
    label: 'HTML Content',
    type: 'textarea',
    name: 'html',
    control: 'textarea',
    placeholder: 'Enter HTML Content',
    as: 'textarea',
    required: false,
    isFieldRequired: true,
    disabled: isLicenseView
  },
  {
    label: 'Script',
    type: 'textarea',
    name: 'script',
    control: 'textarea',
    placeholder: 'Enter Script',
    as: 'textarea',
    required: false,
    isFieldRequired: true,
    disabled: isLicenseView
  },
  {
    label: 'Description',
    type: 'textarea',
    name: 'description',
    control: 'textEditor',
    placeholder: 'Enter Description',
    required: false,
    isFieldRequired: true,
    disabled: isLicenseView
  }
])

export const formFieldClasses = {
  fieldClassName: 'form-control', // Class for form control
  containerClassName: 'form-field-container mt-3', // Class for form field container
  labelClassName: 'form-field-label', // Class for label
  labelRequiredClassName: 'text-danger' // Class for required label
}

const createField = (label, name, placeholder, disabled) => ({
  label,
  type: 'text',
  name,
  control: 'input',
  placeholder,
  required: false,
  isFieldRequired: true,
  disabled,
  ...formFieldClasses
})

const paymentConfigurations = [
  {
    header: 'PAYMENTIQ',
    keys: [
      { label: 'PaymentIQ Merchant Id', name: 'credDetails.piqDetails.PAYMENTIQ_MERCHANT_ID', placeholder: 'Enter your Merchant Id' },
      { label: 'PaymentIQ Client Id', name: 'credDetails.piqDetails.PAYMENTIQ_CLIENT_ID', placeholder: 'Enter Your Client Id' },
      { label: 'PaymentIQ Client Secret', name: 'credDetails.piqDetails.PAYMENTIQ_CLIENT_SECRET', placeholder: 'Enter Your Client Secret' }
    ]
  },
  {
    header: 'PRAXIS',
    keys: [
      { label: 'Praxis Merchant Id', name: 'credDetails.praxisDetails.PRAXIS_MERCHANT_ID', placeholder: 'Enter your Merchant Id' },
      { label: 'Praxis Merchant Secret', name: 'credDetails.praxisDetails.PRAXIS_MERCHANT_SECRET', placeholder: 'Enter Your Merchant Secret' },
      { label: 'Praxis Application Key', name: 'credDetails.praxisDetails.PRAXIS_APPLICATION_KEY', placeholder: 'Enter Your Application Key' }
    ]
  },
  {
    header: 'SIQURO',
    keys: [
      { label: 'Siquro Token', name: 'credDetails.siquroDetails.SIQURO_TOKEN', placeholder: 'Enter your Token' },
      { label: 'Siquro Brand Id', name: 'credDetails.siquroDetails.SIQURO_BRAND_ID', placeholder: 'Enter Your Brand Id' }
    ]
  },
  {
    header: 'FLAXPAY',
    keys: [
      { label: 'Flaxpay User Id', name: 'credDetails.flaxpayDetails.FLAXPAY_USER_ID', placeholder: 'Enter your User Id' },
      { label: 'Flaxpay Password', name: 'credDetails.flaxpayDetails.FLAXPAY_PASSWORD', placeholder: 'Enter Your Password' },
      { label: 'Flaxpay API Key', name: 'credDetails.flaxpayDetails.FLAXPAY_API_KEY', placeholder: 'Enter Your API Key' }
    ]
  },
  {
    header: 'NODA PAY',
    keys: [
      { label: 'Noda Pay API Key', name: 'credDetails.nodapayDetails.NODA_PAY_API_KEY', placeholder: 'Enter your API Key' },
      { label: 'Noda Pay Secret Key', name: 'credDetails.nodapayDetails.NODA_PAY_SECRET_KEY', placeholder: 'Enter Your Secret Key' },
      { label: 'Noda Pay Shop Id', name: 'credDetails.nodapayDetails.NODA_PAY_SHOP_ID', placeholder: 'Enter Your Shop Id' }
    ]
  },
  {
    header: 'COINSPAID',
    keys: [
      { label: 'Coinspaid Public Key', name: 'credDetails.coinspaidDetails.COINSPAID_PUBLIC_KEY', placeholder: 'Enter your Public Key' },
      { label: 'Coinspaid Secret Key', name: 'credDetails.coinspaidDetails.COINSPAID_SECRET_KEY', placeholder: 'Enter Your Secret Key' }
    ]
  },
  {
    header: 'UTORG',
    keys: [
      { label: 'Utorg Public Key', name: 'credDetails.utorgDetails.UTORG_PUBLIC_KEY', placeholder: 'Enter your Public Key' },
      { label: 'Utorg Id', name: 'credDetails.utorgDetails.UTORG_ID', placeholder: 'Enter Your ID' }
    ]
  },
  {
    header: 'INTERKASSA',
    keys: [
      { label: 'Interkassa User Id', name: 'credDetails.interkassaDetails.INTERKASSA_USER_ID', placeholder: 'Enter your User Id' },
      { label: 'Interkassa API Key', name: 'credDetails.interkassaDetails.INTERKASSA_API_KEY', placeholder: 'Enter Your API Key' },
      { label: 'Interkassa Account Id', name: 'credDetails.interkassaDetails.INTERKASSA_ACCOUNT_ID', placeholder: 'Enter Your Account Id' }
    ]
  }
]

export const getBasePaymentsFormFields = (isLicenseView) =>
  paymentConfigurations.map((payment) => ({
    header: payment.header,
    keys: payment.keys.map((key) =>
      createField(key.label, key.name, key.placeholder, isLicenseView)
    )
  }))
