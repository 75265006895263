import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from '../../../../components/Toast'
import { createWageringTemplateStart, getWageringTemplateDetailsStart } from '../../../../store/redux-slices/bonus'
import { getAllCasinoGamesStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getTenantId } from '../../../../utils/constants'
import useAggregatorList from '../../../../shared/hooks/useAggregatorList'
import useAggregatorCategories from '../../../../shared/hooks/useAggregatorCategories'

const useCreateWegringTemplate = () => {
  const [selectedProvider, setSelectedProvider] = useState('')
  const { loading, wageringTemplateDetail } = useSelector((state) => state.bonus)
  const { casinoGamesData } = useSelector((state) => state.superAdminCasino)
  const [gameContribution, setGameContribution] = useState({})
  const [customValue, setCustomValue] = useState()
  const [adminIds, setAdminIds] = useState([])
  const [isClone, setIsClone] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState('')
  const [contributionDefaultValue, setContributionDefaultValue] = useState({})
  const [state, setState] = useState({ name: '', customValue: '' })
  const isInitialRender = useDidMountEffect()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const {
    aggregatorsOptions,
    selectedAggregatorId,
    setSelectedAggregatorId
  } = useAggregatorList({
    tenantId: getTenantId()
  })

  const {
    aggregatorsCategoryOptions,
    selectedAggregatorCategoryId,
    setSelectedAggregatorCategoryId
  } = useAggregatorCategories({
    gameAggregatorId: selectedAggregatorId,
    search
  })

  const totalPages = Math.ceil(casinoGamesData?.count / limit)

  const customValueHandler = (value) => {
    setCustomValue(value)
    setState({
      ...state,
      customValue: value
    })
    const newGameContribution = {}
    for (const key in gameContribution) {
      newGameContribution[key] = value
    }
    setGameContribution(newGameContribution)
  }

  const formSubmitHandler = (formValue) => {
    const templateData = {
      name: formValue.name,
      gameContribution: { ...contributionDefaultValue, ...gameContribution },
      adminIds
    }
    if (Object.keys(gameContribution).length < 1) {
      toast('Select At Least One Game', 'error')
    } else {
      dispatch(createWageringTemplateStart({ isTenant: false, templateData, navigate }))
    }
  }

  const multiAdminHandler = (options) => {
    const adminId = []
    options.map((item) => adminId.push(item.adminUserId))
    setAdminIds(adminId)
  }

  const fetchDetails = (wageringTemplateId) => {
    dispatch(getWageringTemplateDetailsStart({
      isTenant: false,
      wageringTemplateId,
      limit: 15,
      pageNo: 1,
      providerId: selectedProvider
    }))
  }

  useEffect(() => {
    if (location?.state?.isClone) {
      setIsClone(true)
      fetchDetails(location.state.wageringTemplateId)
    } else {
      setGameContribution({})
    }
  }, [location])

  useEffect(() => {
    if (location?.state?.isClone && wageringTemplateDetail) {
      setContributionDefaultValue(wageringTemplateDetail?.gameContribution)
    }
  }, [wageringTemplateDetail])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllCasinoGamesStart({
              limit,
              pageNo: page,
              casinoCategoryId: '',
              search,
              isActive: '',
              tenantId: '',
              selectedProvider,
              aggregatorId: selectedAggregatorId,
              aggregatorCategoryId: selectedAggregatorCategoryId
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(
      getAllCasinoGamesStart({
        limit,
        pageNo: page,
        casinoCategoryId: '',
        search,
        isActive: '',
        tenantId: '',
        selectedProvider,
        aggregatorId: selectedAggregatorId,
        aggregatorCategoryId: selectedAggregatorCategoryId
      })
    )
  }, [selectedProvider, page, limit, selectedAggregatorId, selectedAggregatorCategoryId])

  return {
    loading,
    navigate,
    page,
    limit,
    setLimit,
    setPage,
    totalPages,
    selectedProvider,
    setSelectedProvider,
    gameContribution: gameContribution || {},
    casinoGamesData,
    setGameContribution,
    customValue,
    customValueHandler,
    formSubmitHandler,
    multiAdminHandler,
    isClone,
    wageringTemplateDetail,
    state,
    setState,
    search,
    setSearch,
    setContributionDefaultValue,
    contributionDefaultValue,
    aggregatorsOptions,
    selectedAggregatorId,
    setSelectedAggregatorId,
    aggregatorsCategoryOptions,
    selectedAggregatorCategoryId,
    setSelectedAggregatorCategoryId
  }
}

export default useCreateWegringTemplate
