import React from 'react'
import useTournaments from './hooks/useTournaments'
import { Row, Col, Button, Table } from '@themesberg/react-bootstrap'
// import ClientFilter from '../ClientFilter'
// import PortalFilter from '../PortalFilter'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import PaginationComponent from '../Pagination'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCopy, faEdit, faEye, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import ConfirmationModal, { DeleteConfirmationModal } from '../ConfirmationModal'
import { isTenant } from '../../utils/constants'

const Tournaments = () => {
  const {
    allTournaments,
    loading,
    // selectedClient,
    // selectedPortal,
    // setSelectedClient,
    // setSelectedPortal,
    // search,
    // setSearch,
    isHidden,
    navigate,
    page,
    setPage,
    limit,
    setLimit,
    totalPages,
    // active,
    // setActive,
    handleStatusShow,
    handleYes,
    setStatusShow,
    statusShow,
    status,
    name,
    deleteShow,
    setDeleteShow,
    handleDeleteYes
  } = useTournaments({ isTenant })

  return (
    <>
      <Row>
        <Col>
          <h3>Tournaments</h3>
        </Col>

        <Col xs='auto'>
          {/* <div className='d-flex justify-content-end align-items-center'>
            {!isTenant &&
              <ClientFilter
                setSelectedClient={setSelectedClient}
                setSelectedPortal={setSelectedPortal}
                selectedClient={selectedClient}
              />}

            {selectedClient && !isTenant && (
              <PortalFilter
                setSelectedPortal={setSelectedPortal}
                selectedPortal={selectedPortal}
                selectedClient={selectedClient}
              />
            )}

            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px' }}
              value={search}
              placeholder='Search ID'
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />

            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              Status
            </Form.Label>

            <Form.Select
              size='sm'
              style={{ maxWidth: '200px', marginRight: '10px' }}
              value={active}
              onChange={(event) =>
                setActive(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            >
              <option key='' value=''>All</option>
              <option key='true' value>Active</option>
              <option key='false' value={false}>In-Active</option>
            </Form.Select> */}

          <Button
            variant='outline-success'
            className='f-right'
            size='sm'
            onClick={() => navigate(isTenant ? TenantRoutes.CreateTournaments : SuperAdminRoutes.CreateTournaments)}
            hidden={isHidden({ module: { key: 'Tournament', value: 'C' } })}
          >
            Create
          </Button>
          {/* </div> */}
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['Id', 'Title', 'Status', 'Action'].map((h) => (
              <th key={h}>{h}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(allTournaments) &&
                    allTournaments?.rows?.map(
                      ({ title, isActive, tenantId, adminId, tournamentId }, index) => {
                        return (
                          <tr key={index}>
                            <td>{tournamentId}</td>

                            <td>
                              <Trigger message={title}>
                                <span
                                  style={{
                                    width: '150px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/super-admin/tournaments/view/${tournamentId}`
                                    )}
                                  className='text-link d-inline-block text-truncate'
                                >
                                  {title}
                                </span>
                              </Trigger>
                            </td>

                            <td>
                              {isActive
                                ? (
                                  <span className='text-success'>Active</span>
                                  )
                                : (
                                  <span className='text-danger'>In Active</span>
                                  )}
                            </td>

                            <td>
                              <Trigger message='Edit'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='warning'
                                  onClick={() =>
                                    navigate(
                                      `/${isTenant ? 'tenant' : 'super-admin'}/tournaments/edit/${tournamentId}`
                                    )}
                                  hidden={isHidden({ module: { key: 'Tournament', value: 'U' } })}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </Trigger>

                              <Trigger message='View Details'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='info'
                                  onClick={() =>
                                    navigate(
                                      `/${isTenant ? 'tenant' : 'super-admin'}/tournaments/view/${tournamentId}`
                                    )}
                                  hidden={isHidden({ module: { key: 'Tournament', value: 'R' } })}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </Button>
                              </Trigger>

                              <Trigger message='Clone'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  onClick={() =>
                                    navigate(
                                      `/${isTenant ? 'tenant' : 'super-admin'}/tournaments/clone/${tournamentId}`
                                    )}
                                  hidden={isHidden({ module: { key: 'Tournament', value: 'U' } })}
                                >
                                  <FontAwesomeIcon icon={faCopy} />
                                </Button>
                              </Trigger>

                              {!isActive
                                ? (
                                  <Trigger message='Set Status Active'>
                                    <Button
                                      className='m-1'
                                      size='sm'
                                      variant='success'
                                      onClick={() =>
                                        handleStatusShow(tournamentId, isActive, title, true)}
                                      hidden={isHidden({ module: { key: 'Tournament', value: 'T' } })}
                                    >
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </Button>
                                  </Trigger>
                                  )
                                : (
                                  <Trigger message='Set Status In-Active'>
                                    <Button
                                      className='m-1'
                                      size='sm'
                                      variant='danger'
                                      onClick={() =>
                                        handleStatusShow(tournamentId, isActive, title, true)}
                                      hidden={isHidden({ module: { key: 'Tournament', value: 'T' } })}
                                    >
                                      <FontAwesomeIcon icon={faWindowClose} />
                                    </Button>
                                  </Trigger>
                                  )}

                              <Trigger message='Delete'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  onClick={() =>
                                    handleStatusShow(tournamentId, isActive, title, false)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </Trigger>
                            </td>
                          </tr>
                        )
                      }
                    )}

          {allTournaments?.count === 0 && !loading && (
            <tr>
              <td colSpan={6} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {allTournaments?.count !== 0 && !loading && (
        <PaginationComponent
          page={allTournaments?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />

      {deleteShow &&
        <DeleteConfirmationModal
          deleteModalShow={deleteShow}
          setDeleteModalShow={setDeleteShow}
          handleDeleteYes={handleDeleteYes}
          name={name}
        />}
    </>
  )
}

export default Tournaments
