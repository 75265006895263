import React from 'react'
import Select from 'react-select' // Assuming you're using the 'react-select' library

const CustomReactSelect = ({
  name,
  options, // Array of options for the select dropdown (label-value pairs)
  value, // Selected value(s) from the options (single or multi-select)
  onChange, // Function to update the selected value(s)
  selectClassName = '', // CSS class applied to the Select component
  selectClassPrefix = 'select', // Class prefix for styling React Select (default is 'select')
  disabled = false,
  isMulti = false,
  setFieldValue = true,
  formik // Formik object, if passed
}) => {
  // Modified onChange handler to include formik.setFieldValue if formik is available
  const getSelectedOptions = () => {
    if (isMulti) {
      // If multi-select is enabled, filter options that match selectedValues (an array)
      return Array.isArray(value)
        ? options.filter(option => value.includes(option.value)) // Returns matching options
        : []
    } else {
      // For single select, find the matching option in selectOptions
      return options.find(option => option.value === value) || null
    }
  }

  /**
   * handleSelectChange - Handles the selection change event
   * @param {object|Array} selectedOptions - The selected option(s) from the React Select component
   */
  const handleSelectChange = (selectedOptions) => {
    if (isMulti) {
      // When multi-select is enabled, map the selectedOptions to an array of values
      const newSelectedValues = selectedOptions
        ? selectedOptions.map(option => option.value)
        : [] // If no options are selected, set an empty array
      // Update the selected values and set the form value using Formik's setFieldValue
      onChange(newSelectedValues)
      setFieldValue && formik && formik.setFieldValue(name, newSelectedValues)
      if (name === 'ownerId' && formik?.setFieldValue) {
        formik.setFieldValue('tenantId', '')
        formik.setFieldValue('countryCodes', [])
      }
      if (name === 'tenantId' && setFieldValue) {
        formik.setFieldValue('countryCodes', [])
      }
    } else {
      // For single select, set the selected value (or null if none is selected)
      const newSelectedValue = selectedOptions ? selectedOptions.value : null

      if (name === 'ownerId' && setFieldValue) {
        formik.setFieldValue('tenantId', '')
        formik.setFieldValue('countryCodes', [])
      }
      if (name === 'tenantId' && setFieldValue) {
        formik.setFieldValue('countryCodes', [])
      }
      // Update the selected value and form value in Formik
      onChange(newSelectedValue)
      setFieldValue && formik && formik.setFieldValue(name, newSelectedValue)
    }
  }

  return (
    <>
      {/* Render the React Select dropdown */}
      <Select
        isMulti={isMulti} // Enables multi-select if allowMultiple is true
        isClearable={false} // Prevents the user from clearing all selected options
        value={getSelectedOptions()}// Set the current selected value(s)
        options={options} // Available options for selection
        className={selectClassName} // CSS class for styling
        classNamePrefix={selectClassPrefix} // Prefix for internal styling classes
        onChange={handleSelectChange} // Use the custom handleChange function
        isDisabled={disabled}
      />
    </>
  )
}

export default CustomReactSelect
