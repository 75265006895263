import { Row, Form as BForm, Button, Col, Modal } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selfExclusionSchema } from './schema'

import { getLicenseListStart } from '../../store/redux-slices/licenseManagement'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TooltipTrigger from '../../shared/TooltipTrigger'
const SelfExclusion = ({
  limit,
  show,
  setShow,
  updateLimit
}) => {
  const { userData } = useSelector(state => state.fetch)
  const { user } = useSelector(state => state.tenantUsers)
  const { licenseList } = useSelector((state) => state.licenseManagement)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getLicenseListStart({
        tenantId: ''
      })
    )
  }, [])

  const licenseListOptions = useMemo(() => {
    return licenseList
      ? licenseList.map((item) => ({
        value: item.id,
        label: item.name
      }))
      : []
  }, [licenseList])

  const selfExclusionMessage = 'The player will be self-excluded for all portals for the selected License'

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <h5>Self Exclusion {user ? `for '${user?.firstName} ${user?.lastName}'` : userData ? `for '${userData?.firstName} ${userData?.lastName}'` : ''}</h5>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            portal: limit?.portal || 'current',
            days: limit?.days === -1 ? '1' : limit?.days,
            permanent: limit?.days === -1 ? 'true' : 'false',
            parentLicenseId: userData?.userLimit?.selfExclusionType === 'current' ? userData?.license?.parentLicenseId : ''
          }}
          validationSchema={selfExclusionSchema}
          onSubmit={(formValues) => {
            formValues.parentLicenseId = String(formValues.parentLicenseId)
            updateLimit({ formValues, reset: false, type: 'SELF_EXCLUSION' })
            setShow(false)
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            errors
          }) => (
            <Form className='m-3'>
              <div>
                <Row className='mt-2'>
                  <Col className='d-flex justify-content-between align-items-center'>
                    <Col className='d-flex align-items-center gap-2'>
                      <BForm.Label>License</BForm.Label>
                      <TooltipTrigger message={selfExclusionMessage}>
                        <span className='fontSize-14'>
                          <FontAwesomeIcon icon={faCircleInfo} />
                        </span>
                      </TooltipTrigger>

                    </Col>

                    <Col>
                      <BForm.Select
                        type='number'
                        name='portal'
                        value={values.parentLicenseId}
                        onChange={e =>
                          setFieldValue('parentLicenseId', e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
                        onBlur={handleBlur}
                      >
                        <option value=''>Select a License</option>
                        {licenseListOptions?.length > 0 && licenseListOptions.map(({ value, label }) => {
                          return <option key={value} value={value}>{label}</option>
                        })}
                      </BForm.Select>
                    </Col>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col className='d-flex justify-content-between align-items-center'>
                    <Col>
                      <BForm.Label>Portal</BForm.Label>
                    </Col>

                    <Col>
                      <BForm.Select
                        type='number'
                        name='portal'
                        value={values.portal}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option key='current' value='current'>Current</option>
                        <option key='all' value='all'>All</option>
                      </BForm.Select>
                    </Col>
                  </Col>
                </Row>

                <Row className='mt-2'>
                  <Col className='d-flex justify-content-between align-items-center'>
                    <Col>
                      <BForm.Label>Time Period</BForm.Label>
                    </Col>

                    <Col>
                      <BForm.Select
                        type='number'
                        name='permanent'
                        value={values.permanent}
                        onChange={(e) => {
                          if (e.target.value === 'true') {
                            setFieldValue('days', 1)
                          } else {
                            setFieldValue('days', limit?.days === -1 ? '' : limit?.days)
                          }
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                      >
                        <option key='permanent' value='true'>Permanent</option>
                        <option key='custom' value='false'>Custom Value</option>
                      </BForm.Select>
                    </Col>
                  </Col>
                </Row>

                {values?.permanent === 'false' &&
                  <Row className='mt-2'>
                    <Col className='d-flex justify-content-between align-items-center'>
                      <Col>
                        <BForm.Label>Months</BForm.Label>
                      </Col>

                      <Col>
                        <BForm.Control
                          type='number'
                          name='days'
                          placeholder='Enter Months'
                          value={values.days}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component='div'
                          name='days'
                          className='text-danger'
                        />
                      </Col>
                    </Col>
                  </Row>}

              </div>

              <div className='mt-3 d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => {
                    setShow(false)
                  }}
                  className='ml-2'
                >
                  Cancel
                </Button>

                <Button
                  variant='outline-success'
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Confirm
                </Button>

              </div>

            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default SelfExclusion
