import {
  Button,
  Col,
  Form as BForm,
  Form,
  Row
} from '@themesberg/react-bootstrap'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import React from 'react'
import { ErrorMessage, Formik } from 'formik'
import { languageCode } from '../../../pages/Tenant/TenantConfigurations/Languages/constants'
import Preloader from '../../Preloader'
// import { GalleryModal } from '../../ConfirmationModal'
// import Trigger from '../../OverlayTrigger'
// import { faImages } from '@fortawesome/pro-regular-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClientFilter from '../../ClientFilter'
import PortalFilter from '../../PortalFilter'
import useAddKeys from '../hooks/useAddKeys'
import { checkIsTenant } from '../../../utils/constants'
import { addKeySchema } from './schema'
import CustomSelect from '../../../shared/CustomSelect'

const AddKeys = () => {
  const {
    // isHidden,
    loading,
    navigate,
    tenantId,
    updateData,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    availableLanguages,
    licenseListOptions,
    selectedLicense,
    setSelectedLicense
  } = useAddKeys()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <div>
              <div className='d-flex justify-content-between mb-2 align-items-center'>
                <h3>Language Management</h3>
              </div>

              <div className='d-flex align-items-center flex-wrap custom-input-group'>
                {!checkIsTenant() && (
                  <>
                    <div className='m-1 label-input-block' style={{ maxWidth: '350px' }}>
                      <ClientFilter
                        setSelectedClient={setSelectedClient}
                        setSelectedPortal={setSelectedPortal}
                        selectedClient={selectedClient}
                      />
                    </div>
                    <div className='m-1 label-input-block' style={{ maxWidth: '350px' }}>
                      <PortalFilter
                        setSelectedPortal={setSelectedPortal}
                        selectedPortal={selectedPortal}
                        selectedClient={selectedClient}
                        cms={selectedClient === ''}
                        hasAllOptions={false}
                      />
                    </div>
                  </>
                )}
                <div className='m-1 label-input-block' style={{ maxWidth: '350px' }}>
                  <CustomSelect
                    label='License'
                    size='sm'
                    options={licenseListOptions || []}
                    value={selectedLicense}
                    onChange={setSelectedLicense}
                    hasAllOptions
                    disabled={!selectedPortal}
                    fieldName=''
                    placeholder='Please Select License'
                    noOptionsMessage='No License Found'
                  />
                </div>
              </div>
            </div>

            <>
              <Formik
                enableReinitialize
                initialValues={{
                  langKey: '',
                  licenseId: selectedLicense,
                  langData: { ...availableLanguages }
                }}
                validationSchema={addKeySchema(availableLanguages)}
                onSubmit={(formValues) => {
                  updateData({ data: { [formValues?.langKey]: formValues.langData }, tenantId: checkIsTenant() ? tenantId : selectedPortal || '', type: 'addKey', licenseId: selectedLicense })
                }}
              >
                {({ values, handleChange, handleSubmit, handleBlur }) => (
                  <Form>
                    <Row className='mt-3 mb-3'>
                      <div className='m-1 label-input-block'>
                        <BForm.Label className='m-1'>Key <span className='text-danger'>*</span></BForm.Label>
                        <BForm.Control
                          name='langKey'
                          placeholder='Enter Key Name'
                          value={values.langKey}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component='div'
                          name='langKey'
                          className='text-danger'
                        />
                      </div>
                      {Object.keys(values.langData)?.map((lang) => {
                        return (
                          <Col sm={6} key={lang} className='mb-3'>
                            <label className='fs-8' htmlFor={lang}>
                              {languageCode[lang]}&nbsp;
                              {lang === 'EN' && (
                                <span className='text-danger'>*</span>
                              )}
                            </label>
                            <BForm.Control
                              name={`langData[${lang}]`}
                              as='textarea'
                              placeholder='Enter Value'
                              value={values?.langData?.[lang]}
                              onInput={handleChange}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e)
                              }}
                            />
                            <ErrorMessage
                              component='div'
                              name={`langData[${lang}]`}
                              className='text-danger'
                            />
                          </Col>
                        )
                      })}
                    </Row>
                    <div className='mt-4 d-flex justify-content-between'>
                      <Button
                        variant='outline-warning'
                        onClick={() =>
                          checkIsTenant()
                            ? navigate(TenantRoutes.LanguageManagement, {
                              state: {
                                myAdminId: selectedClient || '',
                                myTenantId: selectedPortal || '',
                                myLicenseId: selectedLicense || ''
                              }
                            })
                            : navigate(SuperAdminRoutes.LanguageManagement,
                              {
                                state: {
                                  myAdminId: selectedClient || '',
                                  myTenantId: selectedPortal || '',
                                  myLicenseId: selectedLicense || ''
                                }
                              }
                            )}
                        className='ml-2'
                      >
                        Cancel
                      </Button>
                      <Button
                        className='ml-2'
                        variant='outline-success'
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          </>
          )}

      {/* {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
          isTenant={checkIsTenant()}
        />} */}
    </>
  )
}

export default AddKeys
