import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRedirectionListStart, setRedirectionDetail, updateRedirectionRecordStart } from '../../../../store/redux-slices/redirectionManagement'
import { faCheckSquare, faEdit, faEye, faWindowClose } from '@fortawesome/pro-solid-svg-icons'
import { Button, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAllCountry from '../../../../shared/hooks/useAllCountry'
import useAllOwner from '../../../../shared/hooks/useAllOwner'
import useTenantOwner from '../../../../shared/hooks/useTenantOwner'
import { checkIsTenant } from '../../../../utils/constants'
import useCheckPermission from '../../../../utils/checkPermission'
import Trigger from '../../../../components/OverlayTrigger'

const useRedirectionManagement = () => {
  // ** State & Permissions **
  const isTenant = checkIsTenant()
  const { isHidden } = useCheckPermission()
  const dispatch = useDispatch()
  const { redirectionList, loadingRedirectionList } = useSelector((state) => state.redirectionManagement)

  // ** Owners and Tenants Hooks **
  const { ownerOptions, selectedOwner, setSelectedOwner } = useAllOwner({ fetchOwners: !isTenant })
  const { tenantOptions, selectedTenant, setSelectedTenant } = useTenantOwner({
    fetchTenant: selectedOwner && !isTenant,
    adminId: selectedOwner
  })

  const [selectedRedirectionDetail, setSelectedRedirectionDetail] = useState('')

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  // ** Country Options Hook **
  const { countryOptions } = useAllCountry({ tenantId: '' })

  // ** Modal State **
  const [showRedirectionModal, setShowRedirectionModal] = useState(false)

  // ** Fetch Redirection List **
  const fetchRedirectionList = useCallback(() => {
    if (selectedTenant) {
      dispatch(getRedirectionListStart({ tenantId: selectedTenant }))
    }
  }, [selectedTenant])

  useEffect(() => {
    fetchRedirectionList()
  }, [selectedTenant])

  // ** Actions Configuration (Memoized) **
  const actions = useMemo(
    () => [
      {
        label: 'View',
        icon: faEye,
        variant: 'info',
        handler: (rowData) => {
          dispatch(
            setRedirectionDetail({
              ownerId: selectedOwner,
              tenantId: selectedTenant,
              countryCodes: rowData.countryCode,
              redirectionUrl: rowData.url,
              isActive: rowData?.isActive,
              isEdit: false,
              isView: true
            })
          )
          setShowRedirectionModal(true)
        }
      },
      {
        label: 'Edit',
        icon: faEdit,
        variant: 'warning',
        handler: (rowData) => {
          dispatch(
            setRedirectionDetail({
              ownerId: selectedOwner,
              tenantId: selectedTenant,
              countryCodes: rowData.countryCode,
              redirectionUrl: rowData.url,
              isActive: rowData?.isActive,
              isEdit: true,
              isView: false
            })
          )
          setShowRedirectionModal(true)
        }
      }
    ],
    [dispatch, selectedOwner, selectedTenant]
  )

  // ** Columns Configuration (Memoized) **
  const columns = useMemo(
    () => [
      { key: 'countryName', label: 'Country', isTruncated: true, width: '120px' },
      { key: 'url', label: 'Redirection URL', width: '150px' },
      {
        key: 'actions',
        label: 'Actions',
        render: (rowData) => (
          <ButtonGroup>
            {actions.map((action, idx) => (
              <Button
                key={idx}
                className='m-1'
                size='sm'
                variant={action.variant}
                onClick={() => action.handler(rowData)}
              >
                <FontAwesomeIcon icon={action.icon} />
              </Button>
            ))}

            {!rowData?.isActive
              ? (
                <Trigger message='Set Status Active'>
                  <Button
                    className='m-1' size='sm' variant='success' onClick={() => {
                      setSelectedRedirectionDetail(rowData)
                      setShowConfirmationModal(true)
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </Button>
                </Trigger>
                )
              : (
                <Trigger message='Set Status In-Active'>
                  <Button
                    className='m-1'
                    size='sm'
                    variant='danger'
                    onClick={() => {
                      setSelectedRedirectionDetail(rowData)
                      setShowConfirmationModal(true)
                    }}
                  >
                    <FontAwesomeIcon icon={faWindowClose} />
                  </Button>
                </Trigger>
                )}

          </ButtonGroup>
        ),
        width: '150px'
      }
    ],
    [actions]
  )

  const prepareRedirectionList = (input, countryOptions) => {
    if (!input || typeof input !== 'object') return []
    return Object.entries(input).map(([countryCode, config]) => {
      const country = countryOptions.find((option) => option.value === countryCode)
      return {
        countryCode,
        countryName: country?.label || 'Unknown',
        url: config.url,
        isActive: config.isActive
      }
    })
  }

  // ** Transformed Data for Table Rows **
  const updatedRedirectionList = useMemo(
    () => prepareRedirectionList(redirectionList || {}, countryOptions),
    [redirectionList, countryOptions]
  )

  // ** Transformations **
  const transformToRedirectionConfig = (formValues) => {
  // Extract the required values from the input
    const { tenantId, countryCodes, redirectionUrl, isActive = true } = formValues
    // Transform the data to the desired format
    const transformed = {
      tenantId,
      redirectionConfig: countryCodes.reduce((config, code) => {
        config[code] = {
          url: redirectionUrl,
          isActive // Default to true if not provided
        }
        return config
      }, {})
    }

    return transformed
  }

  const prepareRedirectionConfig = (item, tenantId) => {
    if (!item || typeof item !== 'object') return { tenantId, redirectionConfig: {} }

    return {
      tenantId,
      redirectionConfig: {
        [item.countryCode]: {
          url: item.url,
          isActive: !item.isActive
        }
      }
    }
  }

  // ** Modal Close Handler **
  const closeModal = () => {
    setShowRedirectionModal(false)
    setShowConfirmationModal(false)
    setRedirectionDetail('')
    fetchRedirectionList()
  }

  // ** Update Redirection URL **
  const updateRedirectionUrl = (formValues) => {
    const updatedFormValues = transformToRedirectionConfig(formValues)
    dispatch(updateRedirectionRecordStart({ redirectionFormValues: updatedFormValues, closeModal }))
  }

  const handleConfirmationModal = () => {
    const updatedFormValues = prepareRedirectionConfig(selectedRedirectionDetail, selectedTenant)
    dispatch(updateRedirectionRecordStart({ redirectionFormValues: updatedFormValues, closeModal }))
  }

  return {
    showRedirectionModal,
    setShowRedirectionModal,
    columns,
    rowData: updatedRedirectionList,
    ownerOptions,
    tenantOptions,
    selectedOwner,
    setSelectedOwner,
    selectedTenant,
    setSelectedTenant,
    loading: loadingRedirectionList,
    updateRedirectionUrl,
    isHidden,
    selectedRedirectionDetail,
    showConfirmationModal,
    setShowConfirmationModal,
    handleConfirmationModal
  }
}

export default useRedirectionManagement
