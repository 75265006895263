import React from 'react'
import {
  Navbar,
  Image,
  Col,
  Row,
  Dropdown
} from '@themesberg/react-bootstrap'
import './Header.css'
import useHeader from './hooks/useHeader'
import { updateSelectedLicenseId } from '../../store/redux-slices/tenants'
import { setItem } from '../../utils/storageUtils'
const Header = () => {
  const {
    navigate,
    tenantsList,
    tenantDetails,
    tenantChangeHandler,
    licenseListOptions,
    selectedLicenseId,
    dispatch,
    showAllOptions
  } = useHeader()

  return (
    <Row className='mb-2'>
      <Col>
        <Navbar.Brand>
          {tenantDetails?.tenantConfig?.logoUrl &&
            <Image
              thumbnail
              alt=''
              src={tenantDetails.tenantConfig?.logoUrl}
              className='d-inline-block align-top mx-2'
            />}
          {tenantDetails?.name}
        </Navbar.Brand>
      </Col>
      <Col className='align-self-right'>
        {tenantsList && tenantsList.rows && tenantDetails?.name && !window.location.pathname.includes('/tenant/cashier-management/edit') &&
          <Dropdown className='d-inline'>
            <Dropdown.Toggle id='dropdown-autoclose-outside' className='text-wrap' variant='white' style={{ minWidth: '150px' }}>
              <button
                size='sm'
                className={`tenant-circle-button ${tenantDetails?.isActive ? 'active-button' : 'inactive-button'}`}
              />&nbsp;{`${tenantDetails?.name}${tenantDetails?.domain ? ` (${tenantDetails.domain})` : ''}`}
            </Dropdown.Toggle>

            <Dropdown.Menu className='user-dropdown tenant-dropdown'>
              {
                 showAllOptions &&
                   <Dropdown.Item
                     onClick={(e) => {
                       setItem('allTenantOptionsSelected', 'all')
                       navigate(0)
                     }}
                   >
                     <button
                       size='sm'
                       className='tenant-circle-button active-button'
                     />&nbsp;All
                   </Dropdown.Item>
              }
              {tenantsList.rows.map(({ name, tenantId, domain, isActive }) => {
                return (
                  <Dropdown.Item
                    key={name}
                    onClick={(e) => {
                      setItem('allTenantOptionsSelected', tenantId)
                      tenantChangeHandler(tenantId)
                      navigate(0)
                    }}
                  >
                    <button
                      size='sm'
                      className={`tenant-circle-button ${isActive ? 'active-button' : 'inactive-button'}`}
                    />&nbsp;{name}({domain})
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>}

        {window.location.pathname.includes('/tenant/dashboard') && tenantsList &&
          <Dropdown className='d-inline '>
            <Dropdown.Toggle id='dropdown-autoclose-outside' className='text-wrap' variant='white' style={{ marginLeft: '30px', minWidth: '190px' }}>
              <button
                size='sm'
                className='tenant-circle-button active-button'
              />&nbsp;
              {
              !selectedLicenseId ? 'Select a License' : licenseListOptions.find((license) => license.value === selectedLicenseId)?.label
              }
            </Dropdown.Toggle>
            <Dropdown.Menu className='user-dropdown tenant-dropdown'>
              <Dropdown.Item
                key={1}
                onClick={(e) => {
                  dispatch(
                    updateSelectedLicenseId('')
                  )
                }}
              >
                <button
                  size='sm'
                  className='tenant-circle-button active-button'
                />&nbsp;{licenseListOptions.length > 0 ? 'Select a License' : 'No License Found'}
              </Dropdown.Item>
              {licenseListOptions.map(({ label, value }) => {
                return (
                  <Dropdown.Item
                    key={value}
                    onClick={(e) => {
                      dispatch(
                        updateSelectedLicenseId(value)
                      )
                    }}
                  >
                    <button
                      size='sm'
                      className='tenant-circle-button active-button'
                    />&nbsp;{label}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>}
      </Col>
    </Row>
  )
}

export default Header
