import { Button, Tabs, Tab, Col, Form } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import useLanguageWise from '../hooks/useLanguageWise'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import Trigger from '../../OverlayTrigger'
import EditLangWise from './EditLangWise'
import Preloader from '../../Preloader'
import { languageCode } from '../../../pages/Tenant/TenantConfigurations/Languages/constants'
import { ChangeLangConfirmationModal, GalleryModal } from '../../ConfirmationModal'
import { faImages } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClientFilter from '../../ClientFilter'
import PortalFilter from '../../PortalFilter'

const LanguageWise = ({ errorCodes = false }) => {
  const {
    navigate,
    isTenant,
    languages,
    languageKeys,
    selectedTab,
    myKeys,
    languageTabs,
    selectedKeyTab,
    setSelectedKeyTab,
    loading,
    updateData,
    isError,
    setIsError,
    check,
    show,
    setShow,
    handleYes,
    setMySelectedTab,
    galleryModal,
    setGalleryModal,
    myKeyData,
    myLangauges,
    isHidden,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    licenseListOptions,
    selectedLicense,
    setSelectedLicense
  } = useLanguageWise({ errorCodes })

  const [keysData, setKeysData] = useState({})
  useEffect(() => {
    if (errorCodes) {
      const keyData = {}
      Object.keys(myKeyData).forEach((key) => {
        keyData[key] = myKeyData[key][selectedTab]
      })
      setKeysData(keyData)
    }
  }, [myKeyData, errorCodes, selectedTab])

  return (
    <>
      {(!selectedKeyTab || loading)
        ? <Preloader />
        : (
          <>
            <div className='d-flex justify-content-between mb-2 align-items-center'>
              <h3>{errorCodes ? 'Error' : 'Language'} Management {errorCodes ? '(PaymentIQ)' : ''}</h3>
              <div className='d-flex justify-content-between gap-2'>
                <Trigger message='Gallery'>
                  <Button
                    style={{ padding: '5px 10px', fontSize: '13px', lineHeight: '110%' }}
                    hidden={isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
                    onClick={() => setGalleryModal(true)}
                    variant='outline-secondary'
                  >
                    <FontAwesomeIcon icon={faImages} />
                  </Button>
                </Trigger>
                <Button
                  style={{ padding: '5px 10px', fontSize: '13px', lineHeight: '110%' }}
                  variant='warning'
                  onClick={() => !isTenant
                    ? navigate(errorCodes ? SuperAdminRoutes.EditErrSupportLanguageKeys : SuperAdminRoutes.EditSupportLanguageKeys, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense } })
                    : navigate(errorCodes ? TenantRoutes.EditErrSupportLanguageKeys : TenantRoutes.EditSupportLanguageKeys, { state: { myLicenseId: selectedLicense } })}
                >
                  Edit by Key
                </Button>
              </div>
            </div>

            <div className='d-flex align-items-center flex-wrap custom-input-group'>
              {!isTenant &&
                <>
                  <div className='m-1 label-input-block'>
                    <ClientFilter
                      setSelectedClient={setSelectedClient}
                      setSelectedPortal={setSelectedPortal}
                      selectedClient={selectedClient}
                    />
                  </div>
                  <div className='m-1 label-input-block'>
                    <PortalFilter
                      setSelectedPortal={setSelectedPortal}
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                      cms={selectedClient === ''}
                      hasAllOptions={false}
                    />
                  </div>
                </>}
              <Col xs='auto' className='m-1 label-input-block'>
                <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                  License
                </Form.Label>
                <Form.Select
                  size='sm'
                  disabled={!selectedPortal}
                  style={{ maxWidth: '230px' }}
                  value={selectedLicense}
                  onChange={e =>
                    setSelectedLicense(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
                >
                  <option value=''>Select a License</option>
                  {licenseListOptions.length > 0 && licenseListOptions.map(({ label, value }) => {
                    return <option key={value} value={value}>{label}</option>
                  })}
                </Form.Select>
              </Col>
            </div>

            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => {
                setShow(true)
                setMySelectedTab(tab)
              }}
              className='nav-light'
            >
              <Tab
                eventKey='EN'
                tabClassName={selectedTab !== 'EN' ? 'email' : 'email-active'}
                title={
                  <Trigger message='English'>
                    <span>EN</span>
                  </Trigger>
                }
              >
                <div className='mt-5'>
                  <EditLangWise
                    languageKeyObj={errorCodes ? keysData : languageKeys?.find(obj => obj.language === 'EN')}
                    selectedTab={selectedTab}
                    myKeys={myKeys}
                    isTenant={isTenant}
                    languageTabs={languageTabs}
                    selectedKeyTab={selectedKeyTab}
                    setSelectedKeyTab={setSelectedKeyTab}
                    updateData={updateData}
                    isError={isError}
                    setIsError={setIsError}
                    check={check}
                    errorCodes={errorCodes}
                    myKeyData={myKeyData}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    selectedPortal={selectedPortal}
                    setSelectedPortal={setSelectedPortal}
                    selectedLicense={selectedLicense}
                  />
                </div>
              </Tab>
              {!isTenant
                ? languages?.count && languages?.rows?.map(({ languageName, code }) => {
                  return code !== 'EN' && (errorCodes ? myLangauges.has(code) : languageKeys?.find(obj => obj.language === code)) && (
                    <Tab
                      eventKey={code}
                      title={
                        <Trigger message={languageName}>
                          <span>{code}</span>
                        </Trigger>
                }
                      key={code}
                      mountOnEnter
                      tabClassName={selectedTab !== code ? (errorCodes ? myLangauges.has(code) : languageKeys?.find(obj => obj.language === code)) ? 'email' : '' : 'email-active'}
                    >
                      <div className='mt-5'>
                        <EditLangWise
                          languageKeyObj={errorCodes ? keysData : languageKeys?.find(obj => obj.language === selectedTab)}
                          selectedTab={selectedTab}
                          myKeys={myKeys}
                          isTenant={isTenant}
                          languageTabs={languageTabs}
                          selectedKeyTab={selectedKeyTab}
                          setSelectedKeyTab={setSelectedKeyTab}
                          updateData={updateData}
                          isError={isError}
                          setIsError={setIsError}
                          check={check}
                          errorCodes={errorCodes}
                          myKeyData={myKeyData}
                          selectedClient={selectedClient}
                          setSelectedClient={setSelectedClient}
                          selectedPortal={selectedPortal}
                          setSelectedPortal={setSelectedPortal}
                          selectedLicense={selectedLicense}
                        />
                      </div>
                    </Tab>
                  )
                })
                : languages?.length && languages?.map((code) => {
                  return code !== 'EN' && (errorCodes ? myLangauges.has(code) : languageKeys?.find(obj => obj.language === code)) && (
                    <Tab
                      eventKey={code}
                      title={
                        <Trigger message={languageCode[code]}>
                          <span>{code}</span>
                        </Trigger>
                }
                      key={code}
                      mountOnEnter
                      tabClassName={selectedTab !== code ? (errorCodes ? myLangauges.has(code) : languageKeys?.find(obj => obj.language === code)) ? 'email' : '' : 'email-active'}
                    >
                      <div className='mt-5'>
                        <EditLangWise
                          languageKeyObj={errorCodes ? keysData : languageKeys?.find(obj => obj.language === selectedTab)}
                          selectedTab={selectedTab}
                          myKeys={myKeys}
                          isTenant={isTenant}
                          languageTabs={languageTabs}
                          selectedKeyTab={selectedKeyTab}
                          setSelectedKeyTab={setSelectedKeyTab}
                          updateData={updateData}
                          isError={isError}
                          setIsError={setIsError}
                          check={check}
                          errorCodes={errorCodes}
                          myKeyData={myKeyData}
                          selectedClient={selectedClient}
                          setSelectedClient={setSelectedClient}
                          selectedPortal={selectedPortal}
                          setSelectedPortal={setSelectedPortal}
                          selectedLicense={selectedLicense}
                        />
                      </div>
                    </Tab>
                  )
                })}
            </Tabs>
          </>
          )}
      <ChangeLangConfirmationModal
        show={show}
        setShow={setShow}
        handleYes={handleYes}
      />

      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
          isTenant={isTenant}
        />}
    </>
  )
}

export default LanguageWise
