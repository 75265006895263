import React from 'react'
import { Button, Form } from 'react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import PaginationComponent from '../../../components/Pagination'
import PortalFilter from '../../../components/PortalFilter'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import DataTable from '../../../shared/DataTable'
import useLicenseManagement from './hooks/useLicenseManagement'
import './scss/licenseManagement.scss'
import useCheckPermission from '../../../utils/checkPermission'
import CreateGlobalLicense from '../../../components/LicenseManagement/CreateGlobalLicense'
import { checkIsTenant } from '../../../utils/constants'
import { CustomConfirmationModal } from '../../../components/ConfirmationModal'

const LicenseManagement = ({ isTenant }) => {
  const {
    columns,
    loadingLicenseList,
    licenseList,
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    totalPages,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    navigate,
    show,
    setShow,
    data,
    setData,
    licenseListCount,
    showConfirmationModal,
    setShowConfirmationModal,
    handleSetLicenseAsDefault
  } = useLicenseManagement()

  const { isHidden } = useCheckPermission()

  return (
    <>
      <div className='license-header-container'>
        <div className='license-header'>
          <h3>
            License Management
          </h3>
        </div>
        <div className='license-filters'>
          {!isTenant && (
            <div className='license-filter-container'>
              <ClientFilter
                label='Global'
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                setSelectedPortal={setSelectedPortal}
              />
            </div>
          )}

          {selectedClient && (
            <div className='license-filter-container'>
              <PortalFilter
                selectedClient={selectedClient}
                selectedPortal={selectedPortal}
                setSelectedPortal={setSelectedPortal}
              />
            </div>
          )}

          <div className=' license-filter-container'>
            <Form.Label
              style={{
                marginBottom: '0',
                marginRight: '5px',
                marginTop: '5px'
              }}
            >
              Search
            </Form.Label>
            <Form.Control
              type='search'
              placeholder='Search'
              size='sm'
              value={search}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />
          </div>
          {!isHidden({ module: { key: 'TenantMultiLicense', value: 'C' } }) &&
            <div className='d-flex'>
              <Button
                variant='outline-success'
                size='sm'
                onClick={() => {
                  if (checkIsTenant() || selectedPortal) {
                    navigate(
                      isTenant
                        ? TenantRoutes.CreateLicense
                        : SuperAdminRoutes.CreateLicense
                    )
                  } else {
                    setData({})
                    setShow(true)
                  }
                }}
              >
                Create
              </Button>
            </div>}
        </div>
      </div>

      <DataTable
        columns={columns}
        loading={loadingLicenseList}
        rowData={licenseList || []}
      />

      {licenseListCount
        ? (
          <PaginationComponent
            page={licenseListCount < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalCount={licenseListCount}
          />
          )
        : null}

      {show && <CreateGlobalLicense show={show} setShow={setShow} data={data} search={search} />}

      {
        showConfirmationModal &&
          <CustomConfirmationModal
            isVisible={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            onConfirm={handleSetLicenseAsDefault}
          >
            <div className='fs-5'>
              Are you sure you want to set this license as the default license? Once set, all new users will be linked to this license if no license is associated with their country.
            </div>
          </CustomConfirmationModal>
      }
    </>
  )
}

export default LicenseManagement
