import { takeLatest, put, select } from 'redux-saga/effects'
import {
  getAdmin,
  getAdminChildren,
  getAllAdmins,
  getAllBonus,
  getSuperAdminDetails,
  getTenantOwnerDetails,
  superAdminViewToggleStatus,
  tenantViewToggleStatus,
  reorderBonus,
  getGroups,
  getGroupDetails,
  updateGroup,
  createGroup,
  getRealityCheckData,
  setRealityCheckData,
  updateKYCTriggerSetting,
  createParentGroup,
  updateParentGroup,
  updateUserTags
} from '../../utils/apiCalls'

import {
  getAllAdminsStart,
  getAllAdminsSuccess,
  getAllAdminsFailure,
  getAdminDetailsStart,
  getAdminDetailsSuccess,
  getAdminDetailsFailure,
  getSuperAdminDetailsStart,
  getSuperAdminDetailsSuccess,
  getSuperAdminDetailsFailure,
  updateTenantAdminStatusStart,
  updateTenantAdminStatusComplete,
  getAllBonusStart,
  getAllBonusComplete,
  getAllBonusFailure,
  getAdminChildrenStart,
  getAdminChildrenSuccess,
  getAdminChildrenFailure,
  getAdminDataStart,
  getAdminDataSuccess,
  getAdminDataFailure,
  updateReorderBonusStart,
  updateReorderBonusComplete,
  getLoggedSADetailsSuccess,
  getGroupsStart,
  getGroupsSuccess,
  getGroupsFailure,
  updateGroupStart,
  updateGroupComplete,
  getGroupDetailStart,
  getGroupDetailSuccess,
  getGroupDetailFailure,
  getRealityCheckDataStart,
  getRealityCheckDataComplete,
  setRealityCheckDataStart,
  setRealityCheckDataComplete,
  updateKYCTriggerSettingStart,
  updateKYCTriggerSettingComplete,
  createParentGroupStart,
  createParentGroupFailure,
  createParentGroupSuccess,
  updateParentGroupStart,
  updateParentGroupSuccess,
  updateParentGroupFailure,
  updateUserTagsStart,
  updateUserTagsComplete
} from '../redux-slices/admins'

import { toast } from '../../components/Toast'
import { getAllClientsStart, getTenantStart } from '../redux-slices/tenants'
import { getAllTenantAdminsStart } from '../redux-slices/tenantsFetchData'
import { cloneDeep } from 'lodash'
import { getAllTABonusStart } from '../redux-slices/bonus'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { getAffiliateKeysSuccess } from '../redux-slices/tenantcredentials'
import { checkIsTenant } from '../../utils/constants'
import { getAllAggregatorsSuccess } from '../redux-slices/tenantCasino'
import { fetchTenantDetailsStart } from '../redux-slices/login'

export default function * adminssWatcher () {
  yield takeLatest(getAllAdminsStart.type, getAllAdminsWorker)
  yield takeLatest(getAdminDetailsStart.type, getAdminDetailsWorker)
  yield takeLatest(getSuperAdminDetailsStart.type, getSuperAdminDetailsWorker)
  yield takeLatest(updateTenantAdminStatusStart.type, updateTenantAdminStatusWorker)
  yield takeLatest(getAllBonusStart.type, getBonusListingWorker)
  yield takeLatest(getAdminChildrenStart.type, getAdminChildrenWorker)
  yield takeLatest(getAdminDataStart.type, getAdminDataWorker)
  yield takeLatest(updateUserTagsStart.type, updateUserTagsWorker)
  yield takeLatest(updateReorderBonusStart.type, updateReorderBonusWorker)
  yield takeLatest(getGroupDetailStart.type, getGroupDetailWorker)
  yield takeLatest(updateGroupStart.type, updateGroupWorker)
  yield takeLatest(getGroupsStart.type, getGroupsWorker)
  yield takeLatest(getRealityCheckDataStart.type, getRealityCheckDataWorker)
  yield takeLatest(setRealityCheckDataStart.type, setRealityCheckDataWorker)
  yield takeLatest(updateKYCTriggerSettingStart.type, updateKYCTriggerSettingWorker)
  yield takeLatest(createParentGroupStart.type, createParentGroupWorker)
  yield takeLatest(updateParentGroupStart.type, updateParentGroupWorker)
}
const getAdminState = (state) => state.admins

function * getAllAdminsWorker (action) {
  try {
    const { limit, pageNo, orderBy, search, sort, superAdminId, superRoleId, status = '' } =
      action && action.payload

    const { data } = yield getAllAdmins({
      limit,
      pageNo,
      orderBy,
      search,
      sort,
      superAdminId,
      superRoleId,
      status
    })

    yield put(getAllAdminsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllAdminsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAdminDetailsWorker (action) {
  try {
    const { adminId } = action && action.payload

    const { data } = yield getAdmin({ adminId })

    yield put(getAdminDetailsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getSuperAdminDetailsWorker (action) {
  try {
    const { isTenant = false, onStart = false } = action && action.payload
    const { data } = isTenant ? yield getTenantOwnerDetails() : yield getSuperAdminDetails()

    yield put(getSuperAdminDetailsSuccess(data?.data?.adminDetails))
    if (checkIsTenant()) yield put(getAllAggregatorsSuccess(data?.data?.aggregatorDetail))
    if (data?.data?.keys) yield put(getAffiliateKeysSuccess(data?.data?.keys))
    if (onStart) yield put(getLoggedSADetailsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSuperAdminDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAdminDataWorker (action) {
  try {
    const { adminId } = action && action.payload

    const { data } = yield getAdmin({ adminId })

    yield put(getAdminDataSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTenantAdminStatusWorker (action) {
  try {
    const {
      adminId,
      status,
      limit,
      pageNo,
      orderBy,
      sort,
      search,
      isTenant = false,
      active = ''
    } = action && action.payload

    isTenant
      ? yield tenantViewToggleStatus({ adminId, status, code: 'ADMIN' })
      : yield superAdminViewToggleStatus({ adminId, status, code: 'ADMIN' })

    yield put(updateTenantAdminStatusComplete())

    isTenant
      ? yield put(getAllTenantAdminsStart({ limit, pageNo, sort, orderBy, search, status: active }))
      : yield put(
        getAllClientsStart({ limit, pageNo, sort, orderBy, search, status: active })
      )

    yield toast('Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateTenantAdminStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getBonusListingWorker (action) {
  try {
    const { adminId = '', tenantId = '', limit = '', pageNo = '', bonusType = '', isActive = '', search = '', userId = '', reorder = false, bonusId = '', isTenant = false } = action && action.payload

    const { data } = yield getAllBonus({ adminId, tenantId, limit, pageNo, bonusType, isActive, search, userId, reorder, bonusId, isTenant })

    yield put(getAllBonusComplete(data?.data?.bonus))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllBonusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAdminChildrenWorker (action) {
  try {
    const { superAdminId, superRoleId } =
      action && action.payload
    const { adminChildren } = yield select(getAdminState)

    const addChildrenToAdmin = (newAdminChildren, id, children) => {
      if (newAdminChildren?.id === id) {
        return (newAdminChildren.children = [...children])
      }

      if (newAdminChildren?.children?.length) {
        for (const admin of newAdminChildren.children) {
          addChildrenToAdmin(admin, id, children)
        }
      }
    }

    const { data } = yield getAdminChildren({ superAdminId, superRoleId })

    const newAdminChildren = cloneDeep(adminChildren)
    const children = data?.data?.adminDetails?.map((item) => {
      return {
        id: item.superAdminUserId,
        name: `${item.firstName || ''} (${item.childCount})`,
        children: [],
        data: item
      }
    })
    yield addChildrenToAdmin(newAdminChildren, superAdminId, children)

    yield put(getAdminChildrenSuccess(newAdminChildren))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getAdminChildrenFailure())
  }
}

function * updateUserTagsWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    const res = yield updateUserTags({ data, isTenant })
    yield toast(res.data.message, 'success')
    yield put(updateUserTagsComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateUserTagsComplete())
  }
}

function * updateReorderBonusWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    const res = yield reorderBonus({ data, isTenant })
    yield toast(res?.data?.message, 'success')
    if (data?.adminId) {
      yield put(getAllBonusStart({ adminId: data?.adminId, tenantId: data?.tenantId, reorder: true }))
    } else {
      yield put(getAllTABonusStart({
        limit: '',
        pageNo: '',
        search: '',
        bonusType: '',
        isActive: '',
        reorder: true
      }))
    }
    yield put(updateReorderBonusComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateReorderBonusComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * getGroupDetailWorker (action) {
  try {
    const { groupId, isTenant, groupName = '' } = action && action.payload

    const res = yield getGroupDetails({ groupId, isTenant, groupName })
    yield put(getGroupDetailSuccess(res?.data?.data?.groupPermDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getGroupDetailFailure())
  }
}

function * updateGroupWorker (action) {
  try {
    const { data, isTenant, type, navigate } = action && action.payload

    const res = type === 'create' ? yield createGroup({ data, isTenant }) : yield updateGroup({ data, isTenant })
    yield toast(res.data.message, 'success')
    yield put(updateGroupComplete())
    if (type === 'create') {
      navigate(isTenant ? TenantRoutes.Groups : SuperAdminRoutes.Groups)
    } else {
      yield put(getGroupDetailStart({ groupId: data?.groupId, isTenant }))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateGroupComplete())
  }
}

function * getGroupsWorker (action) {
  try {
    const { groupName = '', isTenant } = action && action.payload

    const res = yield getGroups({ groupName, isTenant })
    const groups = res?.data?.data?.recordData

    yield put(getGroupsSuccess({ count: groups.length, rows: [...groups] }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getGroupsFailure())
  }
}

function * getRealityCheckDataWorker (action) {
  try {
    const { adminId } = action && action.payload

    const res = yield getRealityCheckData(adminId)
    yield put(getRealityCheckDataComplete(res?.data?.data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getRealityCheckDataComplete({}))
  }
}

function * setRealityCheckDataWorker (action) {
  try {
    const { data } = action && action.payload

    yield setRealityCheckData(data)
    yield put(setRealityCheckDataComplete())
    yield toast('Limit Set Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(setRealityCheckDataComplete())
  }
}

function * updateKYCTriggerSettingWorker (action) {
  try {
    const { data } = action && action.payload

    yield updateKYCTriggerSetting(data)
    yield put(updateKYCTriggerSettingComplete())
    yield toast('Settings Updated Successfully', 'success')

    checkIsTenant() ? yield put(fetchTenantDetailsStart()) : yield put(getTenantStart({ tenantId: data?.tenantId }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateKYCTriggerSettingComplete())
  }
}

/**
 * @param {{ payload: { data: { parentGroupName: String }, isTenant: Boolean, onSuccess: Function }}} action
 */
function * createParentGroupWorker (action) {
  try {
    const { data, isTenant, onSuccess } = action && action.payload

    yield createParentGroup({ data, isTenant })
    yield toast('Parent Group Created Successfully', 'success')

    yield put(createParentGroupSuccess())

    if (typeof onSuccess === 'function') {
      onSuccess()
    }

    yield put(getGroupsStart({ isTenant }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createParentGroupFailure())
  }
}

/**
 * @param {{ payload: { data: { parentId: Number, parentGroupName: String }, isTenant: Boolean, onSuccess: Function }}} action
 */
function * updateParentGroupWorker (action) {
  try {
    const { data, isTenant, onSuccess } = action && action.payload

    yield updateParentGroup({ data, isTenant })
    yield toast('Parent Group Updated Successfully', 'success')

    yield put(updateParentGroupSuccess())

    if (typeof onSuccess === 'function') {
      onSuccess()
    }

    yield put(getGroupsStart({ isTenant }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateParentGroupFailure())
  }
}
