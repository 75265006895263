import React from 'react'
import Form from 'react-bootstrap/Form'

const SwitchInput = ({ id, type, name, formik, disabled = false }) => {
  return (
    <Form.Group controlId={id}>
      <Form.Check
        type={type}
        disabled={disabled}
        id={id}
        name={name}
        checked={formik.values[name]}
        onChange={(e) => {
          formik.setFieldValue(name, e.target.checked)
        }}
        label='' // This hides the default label
      />
    </Form.Group>
  )
}

export default SwitchInput
