import { Button, Col, Modal, Row, Form as BForm } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { addGlobalLicenseSchema } from './ValidationSchema'
import { updateGlobalLicenseStart } from '../../store/redux-slices/licenseManagement'
import { useDispatch } from 'react-redux'

const CreateGlobalLicense = ({ show, setShow, data, search = '' }) => {
  const dispatch = useDispatch()

  return (
    <Modal size='xs' show={show}>
      <Modal.Header className='justify-content-center'>
        <h4>{!data?.id ? 'Create' : 'Edit'} License</h4>
      </Modal.Header>
      <Formik
        initialValues={{
          name: data?.name || '',
          depositLimitExclusion: data?.depositLimitExclusion || false
        }}
        validationSchema={addGlobalLicenseSchema}
        onSubmit={(formValues) => {
          dispatch(updateGlobalLicenseStart({ licenseFormValues: data?.id ? { ...formValues, licenseId: data?.id } : formValues, type: !data?.id ? 'create' : 'edit' }))
          setShow(false)
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur }) => (
          <Form className='align-items-center'>
            <Modal.Body>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Name<span className='text-danger'> *</span>
                  </BForm.Label>

                  <BForm.Control
                    type='text'
                    name='name'
                    placeholder='Enter Name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='name'
                    className='text-danger'
                  />
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col className='d-flex'>
                  <BForm.Label>Deposit Limit Exclusion</BForm.Label>

                  <BForm.Check
                    className='mx-auto'
                    type='checkbox'
                    name='depositLimitExclusion'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.depositLimitExclusion}
                    defaultChecked={values.depositLimitExclusion}
                  />
                </Col>
              </Row>

            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
              <Button
                className='m-2'
                variant='outline-warning'
                onClick={() => {
                  setShow(false)
                }}
              >
                Cancel
              </Button>
              <Button
                className='m-2'
                variant='outline-success'
                onClick={handleSubmit}
              >
                Add
              </Button>
            </Modal.Footer>
          </Form>)}
      </Formik>
    </Modal>
  )
}

export default CreateGlobalLicense
