import React from 'react'
import ReactQuill from 'react-quill'

const TextEditor = ({
  name = 'description',
  placeholder = 'Enter Description',
  value,
  onChange,
  formik,
  disabled
}) => {
  // Custom onChange handler if additional processing is required
  const handleChange = (content) => {
    const processedContent = content.replace(/\s/g, '&nbsp;') // Convert spaces to &nbsp;
    if (onChange) {
      onChange(processedContent) // Call the parent onChange function if provided
    }
    if (formik?.setFieldValue) {
      formik.setFieldValue(name, processedContent) // Set field value with Formik if available
    }
  }

  return (
    <ReactQuill
      name={name}
      placeholder={placeholder}
      value={value || formik?.values[name]}
      onChange={handleChange}
      readOnly={disabled || false}
    />
  )
}

export default TextEditor
