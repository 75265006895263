import { Button, Form as BForm, Modal, Row, Col } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import Select from 'react-select'
import useAddPaymentMethod from '../hooks/useAddPaymentMethod'
import { addMethodSchema } from './schema'
import { addPaymentMethodStart, getPaymentGroupsStart } from '../../../../store/redux-slices/payment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { toast } from '../../../../components/Toast'
import CreatableSelect from 'react-select/creatable'

const AddMethodModal = ({ show, setShow }) => {
  const {
    clientsFilterData,
    portalOptions,
    setSelectedClient,
    selectedClient,
    isTenant,
    paymentGroups,
    dispatch,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup,
    adminId,
    proceed,
    setProceed,
    selectedPortals,
    setSelectedPortals,
    paymentAggregators
  } = useAddPaymentMethod()

  const Rules = () => (
    <ul>
      <li>Name, Group and other information are only required in PaymentIq.</li>
      <li>For Praxis, all the methods available in Praxis dashboard will be automatically added in cashier management.</li>
      <li>Please enter name of method carefully. Name is not editable and will be used as a service parameter in routing rules in PaymentIQ.</li>
      <li>If dropdown needed in required fields, add an array of objects containing keys "name" and "value"<br />
        E.g. <code>{'[{ "name": "PayPay Bank", "value": "0033" }, { "name":"Germany", "value": "01" }, … ]'}</code>
      </li>
      <li>If any extra attributes like bank code are needed to send in request, please add it in the Other section in object format. Key in attributes should be verified from the PSP document.<br />
        Eg: <code>{'{ "attributes": { "bank_code": "revolut" } }'}</code>
      </li>
      <li>If PSP supports both deposits and withdrawals, please check both the checkboxes.</li>
      <li>If you need to add this method in the deposit/withdrawal cashier for users, mark it as active later.</li>
    </ul>
  )

  return (
    <>
      <Formik
        initialValues={{
          adminId: isTenant ? adminId : '',
          tenantIds: [],
          name: '',
          group: '',
          aggregator: '',
          supportsDeposit: true,
          supportsWithdrawal: false,
          redirection: 'cashier_iframe',
          requiredFields: [{ name: 'amount', dataType: 'number', values: '' }],
          other: '',
          providerId: '',
          merchantId: '',
          merchantKey: '',
          closedLoop: false,
          checkoutId: '',
          code: '',
          signature: '',
          purseId: ''
        }}
        validationSchema={addMethodSchema}
        onSubmit={(formValues, { resetForm }) => {
          const tenantIds = formValues?.tenantIds?.map(tenant => tenant?.value)
          const data = formValues?.aggregator === 'paymentiq'
            ? { ...formValues, tenantIds, adminId: parseInt(formValues?.adminId), other: formValues?.other ? JSON.parse(formValues?.other) : {} }
            : formValues?.aggregator === 'nodapay'
              ? {
                  adminId: parseInt(formValues?.adminId),
                  tenantIds,
                  name: formValues?.name,
                  aggregator: formValues?.aggregator,
                  other: { providerId: formValues?.providerId },
                  redirection: formValues?.redirection,
                  supportsDeposit: formValues?.supportsDeposit,
                  supportsWithdrawal: formValues?.supportsWithdrawal,
                  requiredFields: formValues?.requiredFields,
                  closedLoop: formValues?.closedLoop
                }
              : formValues?.aggregator === 'impaya'
                ? { ...formValues, tenantIds, adminId: parseInt(formValues?.adminId), other: formValues?.other ? JSON.parse(formValues?.other) : {} }
                : formValues?.aggregator === 'interkassa'
                  ? {
                      adminId: parseInt(formValues?.adminId),
                      tenantIds,
                      name: formValues?.name,
                      aggregator: formValues?.aggregator,
                      redirection: formValues?.redirection,
                      supportsDeposit: formValues?.supportsDeposit,
                      supportsWithdrawal: formValues?.supportsWithdrawal,
                      closedLoop: formValues?.closedLoop,
                      checkoutId: formValues?.checkoutId,
                      code: formValues?.code,
                      signature: formValues?.signature,
                      purseId: formValues?.purseId
                    }
                  : { adminId: parseInt(formValues?.adminId), tenantIds, aggregator: formValues?.aggregator, closedLoop: formValues?.closedLoop }
          if (!formValues?.supportsWithdrawal) delete data?.closedLoop
          dispatch(addPaymentMethodStart({ data, isTenant, resetForm, setShow }))
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue, errors }) => (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop='static'
            keyboard={false}
            size='xl'
          >
            <Modal.Header closeButton className='d-flex align-items-center'>
              <h4 className='mx-2'>Add New Method </h4>&nbsp;&nbsp;
              {proceed
                ? (
                  <Button size='sm' className='outline-warning' onClick={() => setProceed(!proceed)}>
                    <FontAwesomeIcon icon={faInfo} />
                  </Button>
                  )
                : ''}
            </Modal.Header>
            {!proceed
              ? (
                <>
                  <Modal.Body>
                    <Rules />
                    <Modal.Footer className='d-flex justify-content-center align-items-center'>
                      <Button variant='outline-success' onClick={() => setProceed(true)}>Proceed</Button>
                    </Modal.Footer>
                  </Modal.Body>
                </>
                )
              : (
                <>
                  <Modal.Body className='add-payment-method'>
                    <Row className='flex-wrap'>
                      {!isTenant &&
                        <Col>
                          <label>Owner<span className='text-danger'> *</span></label>
                          <BForm.Select
                            name='adminId'
                            value={values.adminId}
                            onChange={(e) => {
                              handleChange(e)
                              setSelectedClient(e.target.value)
                              setFieldValue('tenantIds', [])
                            }}
                          >
                            <option value='' disabled key=''>
                              Select Owner
                            </option>
                            {clientsFilterData?.rows?.map((admin) => (
                              <option value={admin.adminUserId} key={admin.adminUserId}>
                                {`${admin.email} (${admin?.firstName} ${admin?.lastName})`}
                              </option>
                            ))}
                          </BForm.Select>
                          <ErrorMessage
                            component='div'
                            name='adminId'
                            className='text-danger'
                          />
                        </Col>}

                      <Col>
                        <label>Portals<span className='text-danger'> *</span></label>
                        <Select
                          isDisabled={isTenant ? false : !selectedClient}
                          isMulti={values.bonusType !== 'balance' && values.bonusType !== 'deposit' && values.bonusType !== 'wagering'}
                          isClearable={false}
                          name='tenantIds'
                          value={values.tenantIds}
                          options={portalOptions}
                          classNamePrefix='select'
                          onChange={(option, e) => {
                            setFieldValue('tenantIds', option)
                            const temp = option.map(o => o.value)
                            setSelectedPortals([...temp])
                          }}
                        />
                        <ErrorMessage
                          component='div'
                          name='tenantIds'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={4} className='add-modal-col'>
                        <Col>
                          <label>
                            Aggregator/Provider <span className='text-danger'>*</span>
                          </label>
                        </Col>
                        <Col>
                          <BForm.Select
                            type='text'
                            name='aggregator'
                            value={values.aggregator}
                            disabled={selectedPortals.length < 1}
                            onChange={(e) => {
                              handleChange(e)
                              if (e.target.value === 'paymentiq') {
                                !paymentGroups && dispatch(getPaymentGroupsStart({ isTenant }))
                              }
                              setFieldValue('name', '')
                              setFieldValue('checkoutId', '')
                              setFieldValue('code', '')
                              setFieldValue('purseId', '')
                              setFieldValue('group', '')
                              setFieldValue('merchantId', '')
                              setFieldValue('merchantKey', '')
                              setFieldValue('closedLoop', false)
                              setFieldValue('supportsDeposit', true)
                              setFieldValue('supportsWithdrawal', false)
                              setFieldValue('redirection', 'cashier_iframe')
                              setFieldValue('requiredFields', [{ name: 'amount', dataType: 'number', values: '' }])
                              setSelectedGroup({})
                              setFieldValue('other', '')
                            }}
                            onBlur={handleBlur}
                          >
                            <option value='' disabled>--Select Aggregator/Provider--</option>
                            {paymentAggregators && Object.keys(paymentAggregators).map((item) => (
                              <option value={item} key={item}>{item}</option>
                            ))}
                          </BForm.Select>
                          <ErrorMessage
                            component='div'
                            name='aggregator'
                            className='text-danger'
                          />
                        </Col>
                      </Col>

                      {values.aggregator && ['paymentiq', 'nodapay', 'impaya', 'interkassa'].includes(values.aggregator) &&
                        <>
                          <Col className='add-modal-col'>
                            <Col>
                              <label>
                                Name <span className='text-danger'>*</span>
                              </label>
                            </Col>
                            <Col className='mb-4'>
                              <BForm.Control
                                type='text'
                                name='name'
                                placeholder='Enter Method Name'
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                component='div'
                                name='name'
                                className='text-danger'
                              />
                            </Col>
                          </Col>

                          {['nodapay'].includes(values.aggregator) &&
                            <Col className='add-modal-col'>
                              <Col>
                                <label>
                                  ProviderId
                                </label>
                              </Col>
                              <Col className='mb-4'>
                                <BForm.Control
                                  type='text'
                                  name='providerId'
                                  placeholder='Enter Provider ID'
                                  value={values.providerId}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  component='div'
                                  name='providerId'
                                  className='text-danger'
                                />
                              </Col>
                            </Col>}

                          {['paymentiq'].includes(values.aggregator) &&
                            <Col className='add-modal-col'>
                              <Col>
                                <label>
                                  Group <span className='text-danger'>*</span>
                                </label>
                              </Col>
                              <Col>
                                <CreatableSelect
                                  isClearable
                                  name='group'
                                  placeholder='Select or Create Group'
                                  onCreateOption={(option) => {
                                    groupOptions?.length > 0
                                      ? setGroupOptions([
                                        ...groupOptions,
                                        { label: option, value: option }
                                      ])
                                      : setGroupOptions([
                                        { label: option, value: option }
                                      ])
                                    setSelectedGroup({ label: option, value: option })
                                    setFieldValue('group', option)
                                  }}
                                  options={groupOptions}
                                  value={selectedGroup}
                                  classNamePrefix='select'
                                  onChange={(option, e) => {
                                    if (option === null) {
                                      setSelectedGroup()
                                      setFieldValue('group', '')
                                    } else {
                                      setSelectedGroup({ label: option?.label, value: option?.value })
                                      setFieldValue('group', option?.value)
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  component='div'
                                  name='group'
                                  className='text-danger'
                                />
                              </Col>
                            </Col>}
                        </>}
                    </Row>

                    {['interkassa'].includes(values.aggregator) &&
                      <Row>
                        <Col className='add-modal-col'>
                          <Col>
                            <label>
                              Checkout Id <span className='text-danger'>*</span>
                            </label>
                          </Col>
                          <Col className='mb-4'>
                            <BForm.Control
                              type='text'
                              name='checkoutId'
                              placeholder='Enter Checkout ID'
                              value={values.checkoutId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              component='div'
                              name='checkoutId'
                              className='text-danger'
                            />
                          </Col>
                        </Col>

                        <Col className='add-modal-col'>
                          <Col>
                            <label>
                              Code <span className='text-danger'>*</span>
                            </label>
                          </Col>
                          <Col className='mb-4'>
                            <BForm.Control
                              type='text'
                              name='code'
                              placeholder='Enter Code'
                              value={values.code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              component='div'
                              name='code'
                              className='text-danger'
                            />
                          </Col>
                        </Col>
                      </Row>}

                    {['interkassa'].includes(values.aggregator) &&
                      <Row>
                        <Col className='add-modal-col'>
                          <Col>
                            <label>
                              Signature <span className='text-danger'>*</span>
                            </label>
                          </Col>
                          <Col className='mb-4'>
                            <BForm.Control
                              type='text'
                              name='signature'
                              placeholder='Enter Signature'
                              value={values.signature}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              component='div'
                              name='signature'
                              className='text-danger'
                            />
                          </Col>
                        </Col>

                        {values?.supportsWithdrawal &&
                          <Col className='add-modal-col'>
                            <Col>
                              <label>
                                Purse ID <span className='text-danger'>*</span>
                              </label>
                            </Col>
                            <Col className='mb-4'>
                              <BForm.Control
                                type='text'
                                name='purseId'
                                placeholder='Enter Purse ID'
                                value={values.purseId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                component='div'
                                name='purseId'
                                className='text-danger'
                              />
                            </Col>
                          </Col>}
                      </Row>}

                    {['impaya'].includes(values.aggregator) &&
                      <Row>
                        <Col xs={3} className='add-modal-col'>
                          <Col>
                            <label>
                              Merchant Id <span className='text-danger'>*</span>
                            </label>
                          </Col>
                          <Col className='mb-4'>
                            <BForm.Control
                              type='text'
                              name='merchantId'
                              placeholder='Enter Merchant ID'
                              value={values.merchantId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              component='div'
                              name='merchantId'
                              className='text-danger'
                            />
                          </Col>
                        </Col>

                        <Col className='add-modal-col'>
                          <Col>
                            <label>
                              Merchant Key <span className='text-danger'>*</span>
                            </label>
                          </Col>
                          <Col className='mb-4'>
                            <BForm.Control
                              type='text'
                              name='merchantKey'
                              placeholder='Enter Merchant Key'
                              value={values.merchantKey}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              component='div'
                              name='merchantKey'
                              className='text-danger'
                            />
                          </Col>
                        </Col>
                      </Row>}

                    {values.aggregator && ['paymentiq', 'nodapay', 'impaya', 'interkassa'].includes(values.aggregator) &&
                      <Row>
                        <Col className='add-modal-col'>
                          <label>
                            Redirection
                          </label>
                          <div className='d-flex align-items-center'>
                            <BForm.Check
                              type='radio'
                              className='mx-2 mt-2'
                              name='redirection'
                              label='Cashier IFrame'
                              value='cashier_iframe'
                              checked={values?.redirection === 'cashier_iframe'}
                              onChange={(e) => {
                                setFieldValue('requiredFields', [{ name: 'amount', dataType: 'number', values: '' }])
                                handleChange(e)
                              }}
                            />
                            <BForm.Check
                              type='radio'
                              className='mx-2 mt-2'
                              name='redirection'
                              label='Direct API'
                              value='direct_api'
                              checked={values?.redirection === 'direct_api'}
                              onChange={(e) => {
                                setFieldValue('requiredFields', [{ name: 'amount', dataType: 'number', values: '' }])
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>

                        <Col className='add-modal-col'>
                          <Col>
                            <label>
                              Supports Deposit <span className='text-danger'>*</span>
                            </label>
                          </Col>
                          <Col className='mt-2'>
                            <BForm.Check
                              type='checkbox'
                              name='supportsDeposit'
                              defaultChecked={values.supportsDeposit}
                              value={values.supportsDeposit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Col>

                        <Col className='add-modal-col'>
                          <Col>
                            <label>
                              Supports Withdraw <span className='text-danger'>*</span>
                            </label>
                          </Col>
                          <Col className='mt-2'>
                            <BForm.Check
                              type='checkbox'
                              name='supportsWithdrawal'
                              defaultChecked={values.supportsWithdrawal}
                              value={values.supportsWithdrawal}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Col>

                        {values?.supportsWithdrawal &&
                          <Col className='add-modal-col'>
                            <Col xs='auto'>
                              <Col>
                                <label>
                                  Closed Loop <span className='text-danger'>*</span>
                                </label>
                              </Col>
                              <Col className='mt-2'>
                                <BForm.Check
                                  type='checkbox'
                                  name='closedLoop'
                                  defaultChecked={values.closedLoop}
                                  value={values.closedLoop}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                            </Col>
                          </Col>}
                      </Row>}

                    {values.aggregator && values.aggregator === 'paymentiq' && values.redirection === 'direct_api' &&
                      <Row>
                        <Col className='add-modal-col'>
                          <label className='mb-2'>
                            Required Fields &nbsp;
                            <Button
                              className='m-1'
                              size='sm'
                              variant='success'
                              onClick={() => {
                                if (values.requiredFields.find(({ name, dataType }) => (name === '' || dataType === ''))) {
                                  toast('Please Fill all previous fields to Add New field', 'error')
                                } else {
                                  setFieldValue('requiredFields', [...values.requiredFields, { name: '', dataType: '', values: '' }])
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </label>
                          {values.requiredFields?.map((fieldData, index) => {
                            return (
                              <div className='d-flex align-items-center' key={index}>
                                <div className='mx-2'>
                                  Name
                                  <BForm.Control
                                    type='text'
                                    name={`requiredFields[${index}][name]`}
                                    placeholder='Enter Name'
                                    value={values?.requiredFields?.[index]?.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage
                                    component='div'
                                    name={`requiredFields[${index}][name]`}
                                    className='text-danger'
                                  />
                                </div>
                                <div className='mx-2'>
                                  DataType
                                  <BForm.Control
                                    type='text'
                                    name={`requiredFields[${index}][dataType]`}
                                    placeholder='Enter Data Type'
                                    value={values?.requiredFields?.[index]?.dataType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage
                                    component='div'
                                    name={`requiredFields[${index}][dataType]`}
                                    className='text-danger'
                                  />
                                </div>
                                <div className='mx-2'>
                                  Values
                                  <BForm.Control
                                    type='text'
                                    as='textarea'
                                    name={`requiredFields[${index}][values]`}
                                    placeholder='Enter Values for select options in JSON object'
                                    value={values?.requiredFields?.[index]?.values}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <ErrorMessage
                                    component='div'
                                    name={`requiredFields[${index}][values]`}
                                    className='text-danger'
                                  />
                                </div>
                                <div>
                                  <Button
                                    className='m-1'
                                    variant='danger'
                                    onClick={() => setFieldValue('requiredFields', values.requiredFields.filter(({ name }) => name !== values.requiredFields[index]?.name))}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </div>
                              </div>
                            )
                          })}
                        </Col>

                      </Row>}

                    {values.aggregator && values.aggregator === 'paymentiq' &&
                      <Row>
                        <Col className='add-modal-col'>
                          <label>
                            Other
                          </label>
                          <BForm.Control
                            type='text'
                            as='textarea'
                            name='other'
                            placeholder='Enter Other Values'
                            value={values.other}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component='div'
                            name='other'
                            className='text-danger'
                          />
                        </Col>
                      </Row>}

                  </Modal.Body>
                  <div className='mt-4 '>
                    <Modal.Footer className='d-flex justify-content-between align-items-center'>
                      <Button
                        variant='outline-warning'
                        onClick={() => setShow(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant='outline-success'
                        onClick={handleSubmit}
                        className='ml-2'
                      >
                        Submit
                      </Button>
                    </Modal.Footer>
                  </div>
                </>
                )}
          </Modal>
        )}

      </Formik>
    </>
  )
}

export default AddMethodModal
