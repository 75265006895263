import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCountriesStart } from '../../store/redux-slices/fetchData'
import { getItem } from '../../utils/storageUtils'

// Custom hook for managing country-related logic and state
const useAllCountry = ({ tenantId = '' }) => {
  const dispatch = useDispatch()
  const { countries } = useSelector((state) => state.fetch)
  const [selectedCountry, setSelectedCountry] = useState('')

  useEffect(() => {
    dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '', tenantId: getItem('tenant-id' || '') }))
  }, [tenantId])

  // Memoized options for country selection
  const countryOptions = useMemo(() => {
    if (countries?.length > 0) {
      return countries.map((country) => ({
        label: country?.name?.EN || '', // Safely access name.EN, provide a fallback
        value: country?.code || '' // Safely access code, provide a fallback
      }))
    }
    return []
  }, [countries])

  return {
    countryOptions,
    selectedCountry,
    setSelectedCountry
  }
}

export default useAllCountry
