import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getRedirectionListStart,
    getRedirectionListSuccess,
    getRedirectionListFailure,
    setRedirectionDetail,
    updateRedirectionRecordStart,
    updateRedirectionRecordSuccess,
    updateRedirectionRecordFailure
  },
  reducer
} = createSlice({
  name: 'redirectionManagement',
  initialState: {
    loading: false,
    loadingRedirectionList: false,
    redirectionList: [],
    redirectionDetail: {
      ownerId: '', // Default values for redirectionRowDetail
      tenantId: '',
      countryCodes: [],
      redirectionUrl: ''
    },
    error: null
  },

  reducers: {
    // Get Redirection List
    getRedirectionListStart: (state) => {
      return { ...state, loadingRedirectionList: true, error: null }
    },

    getRedirectionListSuccess: (state, action) => ({ ...state, loadingRedirectionList: false, redirectionList: action.payload }),
    getRedirectionListFailure: (state, action) => ({ ...state, loadingRedirectionList: false, error: action.payload }),

    setRedirectionDetail: (state, action) => ({ ...state, redirectionDetail: action.payload }),

    // Update Redirection Record
    updateRedirectionRecordStart: (state) => ({ ...state, loading: true, error: null }),
    updateRedirectionRecordSuccess: (state) => ({ ...state, loading: false }),
    updateRedirectionRecordFailure: (state, action) => ({ ...state, loading: false, error: action.payload })
  }
})

export default reducer

// Exporting actions
export {
  getRedirectionListStart,
  getRedirectionListSuccess,
  getRedirectionListFailure,
  setRedirectionDetail,
  updateRedirectionRecordStart,
  updateRedirectionRecordSuccess,
  updateRedirectionRecordFailure
}
