import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTenantCasinoTransactionsStart } from '../../../../../store/redux-slices/tenantTransactions'
import { formatDateYMD, getDateDaysAgo } from '../../../../../utils/dateFormatter'
import useDidMountEffect from '../../../../../utils/useDidMountEffect'
import { getItem, getLoginToken } from '../../../../../utils/storageUtils'
import { getCasinoTransactionSortedValue } from '../../../../../utils/constants'
import { getAllTAProvidersStart } from '../../../../../store/redux-slices/tenantCasino'

const useTransactionList = () => {
  const dispatch = useDispatch()
  const isInitialRender = useDidMountEffect()

  const { tenantCasinoTransactions, loading } = useSelector((state) => state.tenantTransactions)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('all')
  const [search, setSearch] = useState('')
  const [gameSearch, setGameSearch] = useState('')
  const [showTotalAmount, setShowTotalAmount] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [amountType, setAmountType] = useState('')
  const [selectedAction, setSelectedAction] = useState('all')
  const { allowedCurrencies } = useSelector((state) => state.login.tenantDetails?.tenantConfig || {})
  const totalPages = Math.ceil(tenantCasinoTransactions?.count / limit)
  const { allProviders } = useSelector((state) => state.tenantCasino)
  const [providers, setProviders] = useState('')
  const [sortBy, setSortBy] = useState({
    bonusAmount: '',
    cashAmount: '',
    id: '',
    afterBalance: '',
    beforeBalance: '',
    userId: '',
    isSortingField: null
  })
  const [over, setOver] = useState({
    bonusAmount: false,
    cashAmount: false,
    afterBalance: false,
    beforeBalance: false,
    userId: false,
    id: false
  })
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  useEffect(() => {
    dispatch(getAllTAProvidersStart())
  }, [])

  useEffect(() => {
    const orderBy = getCasinoTransactionSortedValue[sortBy.isSortingField] || ''
    const { bonusAmount, cashAmount, id, afterBalance, beforeBalance, userId } = sortBy
    const sort = bonusAmount || cashAmount || id || afterBalance || beforeBalance || userId

    !isInitialRender && dispatch(getAllTenantCasinoTransactionsStart({
      limit,
      pageNo: page,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      currencyCode: selectedCurrency,
      searchByUserId: search,
      gameSearch: gameSearch.replace(/\s/g, ''),
      status,
      transactionType: selectedAction,
      userId: '',
      amountType,
      orderBy,
      orderType: sort,
      providers,
      showTotalAmount
    }))
  }, [page])

  useEffect(() => {
    const orderBy = getCasinoTransactionSortedValue[sortBy.isSortingField] || ''
    const { bonusAmount, cashAmount, id, afterBalance, beforeBalance, userId } = sortBy
    const sort = bonusAmount || cashAmount || id || afterBalance || beforeBalance || userId

    setPage(1)
    dispatch(getAllTenantCasinoTransactionsStart({
      limit,
      pageNo: 1,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      currencyCode: selectedCurrency,
      searchByUserId: search,
      gameSearch: gameSearch.replace(/\s/g, ''),
      status,
      transactionType: selectedAction,
      userId: '',
      amountType,
      orderBy,
      orderType: sort,
      providers,
      showTotalAmount
    }))
  }, [sortBy, providers, showTotalAmount, selectedCurrency, selectedAction, state, status, limit, amountType])

  useEffect(() => {
    const orderBy = getCasinoTransactionSortedValue[sortBy.isSortingField] || ''
    const { bonusAmount, cashAmount, id, afterBalance, beforeBalance, userId } = sortBy
    const sort = bonusAmount || cashAmount || id || afterBalance || beforeBalance || userId

    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllTenantCasinoTransactionsStart({
            limit,
            pageNo: page,
            searchByUserId: search,
            gameSearch: gameSearch.replace(/\s/g, ''),
            startDate: formatDateYMD(state.map(a => a.startDate)),
            endDate: formatDateYMD(state.map(a => a.endDate)),
            currencyCode: selectedCurrency,
            transactionType: selectedAction,
            status,
            userId: '',
            amountType,
            orderBy,
            orderType: sort,
            providers,
            showTotalAmount
          }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, gameSearch])

  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/tenant/casino/transactions?csvDownload=true&limit=${limit}&pageNo=${page}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&currencyCode=${selectedCurrency}&transactionType=${selectedAction}&status=${status}&email=${search}&gameSearch=${gameSearch.replace(/\s/g, '')}&token=${getLoginToken()}&tenantId=${getItem('tenant-id')}`

  return {
    allowedCurrencies,
    selectedCurrency,
    selectedAction,
    search,
    state,
    status,
    tenantCasinoTransactions,
    limit,
    page,
    totalPages,
    loading,
    setPage,
    setLimit,
    setStatus,
    setState,
    setSearch,
    setSelectedAction,
    setSelectedCurrency,
    getCsvDownloadUrl,
    sortBy,
    setSortBy,
    over,
    setOver,
    amountType,
    setAmountType,
    gameSearch,
    setGameSearch,
    providers,
    setProviders,
    allProviders,
    showTotalAmount,
    setShowTotalAmount
  }
}

export default useTransactionList
