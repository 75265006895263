import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { bannedGamesTogglerStart, getAllBannedGamesStart, setOwnerBannedGames, setRemoveBannedGameList, setRemoveBannedGames } from '../../../../store/redux-slices/superAdminTransactions'

const useBannedGames = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, bannedGames, activeListId } = useSelector((state) => state.superAdminTransactions)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState('')
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState('true')
  const [gamesDetails, setGamesDetails] = useState({ name: '', id: '' })
  const isInitialRender = useDidMountEffect()
  const url = window.location.href
  const isTenant = url.includes('/tenant/')
  const { tenantDetails: loginTenantDetails } = useSelector((state) => state.login)
  const totalPages = Math.ceil(bannedGames?.count / limit)

  const fetchDetails = () => {
    dispatch(getAllBannedGamesStart({
      adminId: isTenant ? loginTenantDetails?.adminUserId : selectedClient,
      pageNo,
      search,
      status,
      limit,
      tenantId: isTenant ? loginTenantDetails?.tenantId : selectedPortal
    }))
  }

  useEffect(() => {
    if (isInitialRender) {
      return () => {
        dispatch(setRemoveBannedGames([]))
        dispatch(setRemoveBannedGameList([]))
      }
    }
  }, [])

  useEffect(() => {
    if (selectedClient && !selectedPortal) {
      dispatch(setOwnerBannedGames())
    }
  }, [selectedClient])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          fetchDetails()
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, status])

  useEffect(() => {
    if (loginTenantDetails || !isTenant) {
      setPageNo(1)
      fetchDetails()
    }
  }, [limit, selectedPortal, loginTenantDetails, status])

  useEffect(() => {
    !isInitialRender && fetchDetails()
  }, [pageNo])

  const handleYes = () => {
    dispatch(bannedGamesTogglerStart({
      bannedGamesListId: gamesDetails?.id,
      name: gamesDetails?.name,
      gameStatus: !gamesDetails?.isActive,
      callBack: () => fetchDetails()
    }))
    setShow(false)
  }

  return {
    loading,
    bannedGames,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    navigate,
    setLimit,
    setPageNo,
    setSearch,
    totalPages,
    limit,
    search,
    pageNo,
    isTenant,
    dispatch,
    fetchDetails,
    activeListId,
    setShow,
    show,
    handleYes,
    gamesDetails,
    setGamesDetails,
    status,
    setStatus
  }
}

export default useBannedGames
