import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllClientsStart } from '../../store/redux-slices/tenants'
import { checkIsTenant } from '../../utils/constants'

const useOwners = () => {
  const dispatch = useDispatch()
  const isTenant = checkIsTenant()
  const { clientsFilterData } = useSelector(state => state.tenants)
  const { tenantDetails } = useSelector(state => state.login)
  const [selectedOwner, setSelectedOwner] = useState(isTenant ? tenantDetails?.adminUserId : '')

  // Fetch owner list when Super admin login
  useEffect(() => {
    if (!isTenant) {
      dispatch(getAllClientsStart({
        search: '',
        limit: 100,
        pageNo: 1,
        orderBy: 'email',
        sort: 'desc'
      }))
    }
  }, [isTenant, dispatch]) // Dependency array updated

  // Set selectedOwner from tenantDetails if tenant login and no owner is selected
  useEffect(() => {
    if (isTenant && tenantDetails?.adminUserId && !selectedOwner) {
      setSelectedOwner(tenantDetails.adminUserId)
    }
  }, [isTenant, tenantDetails, selectedOwner]) // Added selectedOwner to the dependency array

  // Memoized options for owner selection
  const ownerOptions = useMemo(() => {
    return clientsFilterData?.rows?.length > 0
      ? clientsFilterData.rows.map(row => ({
        label: `${row.firstName} ${row.lastName}`, // Full name as label
        value: row.adminUserId // adminUserId as value
      }))
      : [] // Return an empty array if no rows
  }, [clientsFilterData])

  return {
    ownerOptions,
    selectedOwner,
    setSelectedOwner
  }
}

export default useOwners
