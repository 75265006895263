import React from 'react'
import CustomSelect from '../../shared/CustomSelect'
const AggregatorCategories = ({ categoriesOptions = '', selectedCategoryId = '', setSelectedCategoryId, labelClassName = '' }) => {
  return (
    <CustomSelect
      label='Game Type'
      size='sm'
      options={categoriesOptions || []}
      value={selectedCategoryId}
      onChange={setSelectedCategoryId}
      placeholder='Please Select Game Type'
      noOptionsMessage='No Game Type Found'
      labelClassName={labelClassName}
    />
  )
}

export default AggregatorCategories
