import { Button, Col, Modal, Row, Form as BForm } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { uploadCSVSchema } from './schema'

export default ({ show, setShow, handleYes }) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Upload xls/xlsx File</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Formik
        enableReinitialize
        initialValues={{
          languageCsv: null
        }}
        validationSchema={uploadCSVSchema}
        onSubmit={(formValues) => {
          handleYes(formValues)
          setShow(false)
        }}
      >
        {({
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <Form>
            <Row className='mt-3'>
              <Col className='d-flex align-items-center'>
                <Col>
                  <BForm.Label>Upload File
                    <span className='text-danger'> *</span>
                  </BForm.Label>
                </Col>

                <Col xs={9}>
                  <BForm.Control
                    type='file'
                    name='languageCsv'
                    onChange={(event) => setFieldValue('languageCsv', event.target.files[0])}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='languageCsv'
                    className='text-danger'
                  />
                </Col>
              </Col>
            </Row>

            <div className='mt-4 d-flex justify-content-between align-items-center'>
              <Button
                variant='outline-warning'
                onClick={() => setShow(false)}
              >
                Cancel
              </Button>
              <Button
                variant='outline-success'
                onClick={() => {
                  handleSubmit()
                }}
                className='ml-2'
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal.Body>
  </Modal>
)
