import React from 'react'
import { ErrorMessage } from 'formik'
import FieldError from '../FormFieldError'
import FieldLabel from '../FormFieldLabel'
import FormFieldControl from '../FormFieldControl'
import '../../../scss/formikForm.scss'
const FormFieldContainer = (props) => {
  const { name, containerClassName, fieldClassName } = props

  return (
    <div className={containerClassName}>
      <FieldLabel {...props} />
      <FormFieldControl className={fieldClassName} {...props} />
      <ErrorMessage name={name} component={FieldError} />
    </div>
  )
}

export default FormFieldContainer
