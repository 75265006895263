import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startFetchingAggregatorCategories } from '../../store/redux-slices/superAdminCasinoManagement'

const useAggregatorCategories = ({ gameAggregatorId = '', search = '', setSelectedProvider }) => {
  // Get the state of aggregator categories and loading status from Redux store
  const { aggregatorsCategories, aggregatorCategoriesLoading } = useSelector((state) => state.superAdminCasino)

  // Local state to manage the selected aggregator category ID
  const [selectedAggregatorCategoryId, setSelectedAggregatorCategoryId] = useState('')

  // Dispatch function to trigger Redux actions
  const dispatch = useDispatch()

  useEffect(() => {
    // Fetch aggregator categories if gameAggregatorId is provided
    setSelectedAggregatorCategoryId('')
    if (setSelectedProvider) {
      setSelectedProvider('')
    }
    dispatch(startFetchingAggregatorCategories({
      gameAggregatorId,
      search
    }))
  }, [gameAggregatorId, search, dispatch]) // Dependency array: rerun effect when gameAggregatorId or search changes

  const aggregatorsCategoryOptions = useMemo(() =>
    aggregatorsCategories.map(({ name, gameAggregatorCategoryId }) => ({
      label: name,
      value: gameAggregatorCategoryId
    })),
  [aggregatorsCategories] // Re-run the transformation only if aggregatorsCategories changes
  )

  // Return necessary values and functions for the component that uses this hook
  return {
    aggregatorCategoriesLoading, // Whether the categories are being loaded
    aggregatorsCategories, // The fetched aggregator categories
    aggregatorsCategoryOptions, // Available options for category selection (currently empty array)
    selectedAggregatorCategoryId, // Currently selected aggregator category ID
    setSelectedAggregatorCategoryId // Function to set the selected category ID
  }
}

export default useAggregatorCategories
