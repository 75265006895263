import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBannedExcludedAffiliatesStart } from '../../store/redux-slices/superAdminTransactions'

/**
 * Custom hook to manage affiliate table, including fetching, selection, and updating affiliates.
 */
const useAffiliateTable = (tenantId, providerDetails, updateData) => {
  const dispatch = useDispatch()

  // State for search query.
  const [search, setSearch] = useState('')

  // Determine if the page is in tenant context.
  const url = window.location.href
  const isTenant = url.includes('/tenant/')

  // Fetch list of banned/excluded affiliates from the store.
  const affiliates = useSelector((state) => state.superAdminTransactions.bannedExcludedAffiliates)

  // State to manage selected affiliate IDs.
  const [selectedAffiliate, setSelectedAffiliate] = useState(providerDetails?.affiliateIds || [])

  useEffect(() => {
    if (tenantId) {
      fetchTenantAffiliates()
    }
  }, [search, isTenant, tenantId])

  // Fetch affiliates for the given tenant.
  const fetchTenantAffiliates = () => {
    dispatch(getBannedExcludedAffiliatesStart({ tenantId, search, isTenant }))
  }

  /**
   * Toggle selection of an affiliate by its ID.
   * @param {string} affiliateId - The affiliate ID.
   */
  const onChangeAffiliateSelect = (affiliateId) => {
    const isSelected = selectedAffiliate.includes(affiliateId)
    if (isSelected) {
      const updatedAffiliates = selectedAffiliate.filter(id => id !== affiliateId)
      setSelectedAffiliate(updatedAffiliates)
    } else {
      const selectedAffiliateObj = affiliates?.find(affiliate => String(affiliate.affiliateId) === affiliateId)
      if (selectedAffiliateObj) {
        setSelectedAffiliate(prevSelected => [...prevSelected, String(selectedAffiliateObj.affiliateId)])
      }
    }
  }

  /**
   * Callback for handling affiliate selection change.
   * @param {string} affiliateId - The affiliate ID.
   */
  const getOnSelectChangeCallback = (affiliateId) => () => {
    onChangeAffiliateSelect(affiliateId)
  }

  /**
   * Select or deselect all affiliates.
   */
  const onChangeAffiliateSelectAll = () => {
    if (selectedAffiliate.length === affiliates.length) {
      setSelectedAffiliate([])
    } else {
      setSelectedAffiliate(affiliates.map(affiliate => String(affiliate.affiliateId)))
    }
  }

  /**
   * Submit the selected affiliate IDs.
   */
  const handleSubmit = () => {
    updateData({ data: { affiliateIds: selectedAffiliate } })
  }

  return {
    affiliates,
    setSearch,
    selectedAffiliate,
    onChangeAffiliateSelectAll,
    onChangeAffiliateSelect,
    handleSubmit,
    getOnSelectChangeCallback
  }
}

export default useAffiliateTable
