import React from 'react'
import { Form, Formik } from 'formik'
import { Button, Col, Row, Accordion } from 'react-bootstrap'
import FormFieldContainer from '../../shared/Formik/FormFieldContainer'
import { checkIsTenant } from '../../utils/constants'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { licenseFormValidationSchema } from './ValidationSchema'
import useEditLicense from '../../pages/Super-Admin/LicenseManagement/hooks/useEditLicense'
import { InlineLoader } from '../Preloader'
import { Tabs, Tab } from '@themesberg/react-bootstrap'

const EditLicenseForm = ({ title, disabledField = false }) => {
  const {
    licenseFormFields,
    defaultLicenseFormValues,
    handleFormSubmit,
    navigate,
    isLicenseView,
    loadingLicenseDetails,
    selectedTab,
    setSelectedTab,
    paymentsFormFields
  } = useEditLicense()

  if (loadingLicenseDetails) {
    return <InlineLoader />
  }
  return (
    <>
      <Row>
        <Col>
          <h3>{title}{disabledField} License</h3>
        </Col>
      </Row>
      <Formik
        enableReinitialize
        initialValues={defaultLicenseFormValues}
        validationSchema={licenseFormValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} className='form-grid'>
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              mountOnEnter
              unmountOnExit
              className='nav-light'
            >
              <Tab eventKey='general' title='General'>
                <Row className='mt-4 px-2'>
                  {licenseFormFields.map((props) => (
                    props?.isFieldRequired && (
                      <Col
                        key={props.name}
                        xs={12}
                        md={props?.control === 'textEditor' || props?.control === 'textarea' ? 12 : 6}
                        lg={props?.control === 'textEditor' || props?.control === 'textarea' ? 12 : 4}
                      >
                        <FormFieldContainer formik={formik} {...props} />
                      </Col>
                    )
                  ))}
                </Row>
              </Tab>
              <Tab eventKey='credentials' title='Credentials'>
                <Row className='mt-5 px-2'>
                  <Accordion defaultActiveKey='0'>
                    {paymentsFormFields.map((payment, index) => (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>{payment.header}</Accordion.Header>
                        <Accordion.Body>
                          <Row key={payment.header}>
                            {payment.keys.map((key, keyIndex) => (
                              key?.isFieldRequired && (
                                <Col
                                  key={key.name}
                                  xs={12}
                                  md={key?.control === 'textEditor' || key?.control === 'textarea' ? 12 : 6}
                                  lg={key?.control === 'textEditor' || key?.control === 'textarea' ? 12 : 4}
                                >
                                  <FormFieldContainer
                                    key={keyIndex}
                                    formik={formik}
                                    {...key}
                                  />
                                </Col>
                              )
                            ))}
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Row>

              </Tab>
            </Tabs>

            <div className='mt-4 d-flex justify-content-between'>
              <Button variant='outline-warning' className='ml-2' onClick={() => navigate(checkIsTenant() ? TenantRoutes.Credentials : SuperAdminRoutes.Credentials, { state: 'licenseManagement' })}>
                Cancel
              </Button>
              <Button className='ml-2' variant='outline-success' type='submit' disabled={isLicenseView}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EditLicenseForm
