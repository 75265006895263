import { spawn } from 'redux-saga/effects'
import loginWatcher from './login'
import tenantsWatcher from './tenants'
import adminsWatcher from './admins'
import themeWatcher from './theme'
import currenciesWatcher from './currencies'
import tenantsFetchDataWatcher from './tenantsFetchData'
import fetchWatcher from './fetchData'
import credWatcher from './tenantcredentials'
import tenantUsersWatcher from './tenantUsers'
import adminUserWatcher from './adminUser'
import tenantAffiliatesWatcher from './tenantAffiliate'
import tenantCasinoWatcher from './tenantCasino'
import superAdminCasinoManagementWatcher from './superAdminCasinoManagement'
import superAdminTransactionsWatcher from './superAdminTransactions'
import tenantTransactionsWatcher from './tenantTransactions'
import bonusWatcher from './bonus'
import superAdminSettingsWatcher from './superAdminSettings'
import dashboardWatcher from './dashboard'
import wageringTemplateWatcher from './wageringTemplate'
import EmailTemplateWatcher from './emailTemplate'
import languagesWatcher from './languages'
import paymentWatcher from './payment'
import authWatcher from './auth'
import tournamentWatcher from './tournaments'
import rgModuleWatcher from './rgModule'
import amlModuleWatcher from './amlModule'
import bannedPlayerWatcher from './bannedPlayer'
import licenseManagementWatcher from './licenseManagement'
import redirectionManagementWatcher from './redirectionManagement'
export default function * rootSaga () {
  yield spawn(loginWatcher)
  yield spawn(tenantsWatcher)
  yield spawn(adminsWatcher)
  yield spawn(themeWatcher)
  yield spawn(currenciesWatcher)
  yield spawn(tenantsFetchDataWatcher)
  yield spawn(fetchWatcher)
  yield spawn(credWatcher)
  yield spawn(tenantUsersWatcher)
  yield spawn(adminUserWatcher)
  yield spawn(tenantAffiliatesWatcher)
  yield spawn(tenantCasinoWatcher)
  yield spawn(superAdminCasinoManagementWatcher)
  yield spawn(superAdminTransactionsWatcher)
  yield spawn(tenantTransactionsWatcher)
  yield spawn(bonusWatcher)
  yield spawn(superAdminSettingsWatcher)
  yield spawn(dashboardWatcher)
  yield spawn(wageringTemplateWatcher)
  yield spawn(EmailTemplateWatcher)
  yield spawn(languagesWatcher)
  yield spawn(paymentWatcher)
  yield spawn(authWatcher)
  yield spawn(tournamentWatcher)
  yield spawn(rgModuleWatcher)
  yield spawn(amlModuleWatcher)
  yield spawn(bannedPlayerWatcher)
  yield spawn(licenseManagementWatcher)
  yield spawn(redirectionManagementWatcher)
}
