import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getImageGalleryStart } from '../../../store/redux-slices/emailTemplate'
import { getLanguagesStart, getLanguageSupportKeysStart, updateLanguageSupportStart } from '../../../store/redux-slices/languages'
import { getItem } from '../../../utils/storageUtils'
import { keysNotToBeShown } from './constant'
import useCheckPermission from '../../../utils/checkPermission'
import { getCountriesStart, updateKycMethodStart } from '../../../store/redux-slices/fetchData'
import useLicenseList from '../../../shared/hooks/useLicenseList'
import { checkIsTenant } from '../../../utils/constants'

const useLanguageWise = ({ errorCodes = false }) => {
  const { isHidden } = useCheckPermission()
  const { state } = useLocation()
  const tenantId = getItem('tenant-id')
  const [selectedClient, setSelectedClient] = useState(state?.myAdminId || '')
  const [selectedPortal, setSelectedPortal] = useState(
    state?.myTenantId ?? (checkIsTenant() ? tenantId : '')
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { keys } = useParams()
  const isCountries = window.location.pathname.includes('/countries/')
  const isTenant = !window.location.href.match(/super/g)
  const [selectedTab, setSelectedTab] = useState('EN')
  const [myLangauges, setLanguages] = useState('EN')
  const [isError, setIsError] = useState({})
  const { languageKeys, languages, languageTabs, loading } = useSelector(state => state.languages)
  const { countries } = useSelector((state) => state.fetch)
  const { tenantDetails } = useSelector(state => state.login)
  const [myKeys, setMyKeys] = useState({})
  const [selectedKey, setSelectedKey] = useState()
  const [check, setCheck] = useState(false)
  const [show, setShow] = useState(false)
  const [myKeyData, setMyKeyData] = useState({})
  const [askModal, setAskModal] = useState(false)
  const [selectedKeyTab, setSelectedKeyTab] = useState('')
  const [mySelectedTab, setMySelectedTab] = useState('')
  const [galleryModal, setGalleryModal] = useState(false)
  const { licenseListOptions, loadingLicenseList, selectedLicense, setSelectedLicense } = useLicenseList(selectedPortal)

  const isInitialRender = useRef(true)

  useEffect(() => {
    setCheck(true)
    setTimeout(() => setCheck(false), 100)
  }, [selectedTab])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return // Skip the first render
    }

    setSelectedLicense('')
  }, [selectedPortal, selectedClient])

  useEffect(() => {
    !isTenant && dispatch(getLanguagesStart({ limit: '', pageNo: '', name: '' }))
    !isCountries && dispatch(getLanguageSupportKeysStart({ isTenant, tenantId: isTenant ? tenantId : selectedPortal || '', licenseId: selectedLicense, language: '', errorCodes }))
    !isCountries && !isHidden({ module: { key: 'ImageGallery', value: 'R' } }) && dispatch(getImageGalleryStart({ isTenant, tenantId: isTenant ? tenantId : selectedPortal }))
    isCountries && dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '', tenantId: selectedPortal || getItem('tenant-id' || '') }))
  }, [errorCodes, selectedPortal, selectedLicense])

  useEffect(() => {
    if (languageTabs) {
      setSelectedKeyTab(Object.keys(languageTabs)?.[0])
    }
  }, [languageTabs])

  useEffect(() => {
    setIsError({})
  }, [selectedTab])

  useEffect(() => {
    if (languageKeys && languageKeys?.length && !isCountries) {
      const data = {}
      const data2 = {}
      const set = new Set()
      if (errorCodes) {
        for (const key in languageKeys) {
          const myKey = languageKeys[key].key
          const value = typeof languageKeys[key].value === 'string' ? JSON.parse(languageKeys[key].value) : {}
          const keyData = {}
          data[myKey] = ''
          for (const lang in value) {
            if (lang !== 'PIQ') {
              keyData[lang] = value[lang]
              set.add(lang)
            }
          }
          data2[myKey] = keyData
        }
      } else {
        for (const key in languageKeys[0]) {
          if (!keysNotToBeShown.includes(key)) data[key] = ''
          const keyData = {}
          for (const i in languageKeys) {
            keyData[languageKeys[i].language] = languageKeys[i][key]
          }
          data2[key] = keyData
        }
      }
      setMyKeys(data)
      setMyKeyData(data2)
      setLanguages(set)
      setSelectedKey({
        label: keys === ':keys' ? Object.keys(data)[0] : keys,
        value: keys === ':keys' ? Object.keys(data)[0] : keys
      })
    }
  }, [languageKeys, errorCodes])

  useEffect(() => {
    if (isCountries) {
      if (countries) {
        const data = {}
        const data2 = {}
        const ctry = countries?.rows ? countries?.rows : countries
        ctry.map(({ code, name }) => {
          data[code] = ''
          data2[code] = name
        })

        setMyKeys(data)
        setMyKeyData(data2)
        setSelectedKey({
          label: keys === ':keys' ? Object.keys(data)[0] : keys,
          value: keys === ':keys' ? Object.keys(data)[0] : keys
        })
      } else {
        dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '' }))
      }
    }
  }, [countries])

  const keyOptions = []
  myKeys && keys &&
  Object.keys(myKeys).map(element =>
    keyOptions.push({ label: element, value: element })
  )

  const handleYes = () => {
    setSelectedTab(mySelectedTab)
    setShow(false)
  }

  useEffect(() => {
    setSelectedTab('EN')
  }, [selectedPortal])

  const updateData = (data) =>
    dispatch(updateLanguageSupportStart({ data, isTenant, tenantId: isTenant ? tenantId : selectedPortal || '', licenseId: selectedLicense, navigate, errorCodes, state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '' } }))

  const updateCountryData = (data) => {
    const code = Object.keys(data?.data)?.[0]
    const country = countries?.find(({ code }) => code)
    dispatch(updateKycMethodStart({
      data:
      { countryCode: code, name: data?.data?.[code], tenantId: isTenant ? tenantId : selectedPortal || '', kycMethod: country?.kycMethod, countryId: country?.countryId },
      tenantId: isTenant ? tenantId : selectedPortal || '',
      navigate
    }))
  }

  return {
    languageKeys,
    navigate,
    isTenant,
    languages: isTenant ? tenantDetails?.tenantConfig?.allowedLanguages : languages,
    selectedTab,
    setSelectedTab,
    myKeys,
    keyOptions,
    setSelectedKey,
    selectedKey,
    myKeyData,
    keys,
    askModal,
    setAskModal,
    languageTabs,
    selectedKeyTab,
    setSelectedKeyTab,
    loading,
    updateData: isCountries ? updateCountryData : updateData,
    isError,
    setIsError,
    check,
    show,
    setShow,
    handleYes,
    setMySelectedTab,
    galleryModal,
    setGalleryModal,
    myLangauges,
    isHidden,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    isCountries,
    licenseListOptions,
    loadingLicenseList,
    selectedLicense,
    setSelectedLicense
  }
}

export default useLanguageWise
