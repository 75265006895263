import { takeLatest, put, call } from 'redux-saga/effects'
import {
  updateRedirectionRecord, getRedirectionList
} from '../../utils/apiCalls' // Adjust import as necessary

import {
  updateRedirectionRecordStart,
  updateRedirectionRecordSuccess,
  updateRedirectionRecordFailure, getRedirectionListStart,
  getRedirectionListSuccess,
  getRedirectionListFailure
} from '../redux-slices/redirectionManagement' // Adjust import as necessary
import { toast } from '../../components/Toast'

export default function * redirectionManagementWatcher () {
  yield takeLatest(getRedirectionListStart.type, getRedirectionListWatcher)
  yield takeLatest(updateRedirectionRecordStart.type, updateRedirectionRecordWatcher)
}

// Get Redirection List
function * getRedirectionListWatcher (action) {
  try {
    const response = yield call(getRedirectionList, action.payload)
    yield put(getRedirectionListSuccess(response?.data?.data?.redirectionConfig))
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to fetch redirection list', 'error')
    yield put(getRedirectionListFailure(e?.response?.data?.errors[0]?.description))
  }
}

// Update Redirection Record
function * updateRedirectionRecordWatcher (action) {
  const { redirectionFormValues, closeModal } = action.payload
  try {
    const response = yield call(updateRedirectionRecord, redirectionFormValues)
    yield put(updateRedirectionRecordSuccess(response.data))
    toast('Redirection Updated Successfully', 'success')
    closeModal()
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description || 'Failed to update redirection', 'error')
    yield put(updateRedirectionRecordFailure(e?.response?.data?.errors[0]?.description))
    closeModal()
  }
}
