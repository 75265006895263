import { Col, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'

import Preloader from '../../../../components/Preloader'
import useCreateWageringTemplate from '../hooks/useCreateWageringTemplate'
import ProviderFilter from '../../../../components/ProviderFilter'
import { createWageringTemplate, editWageringTemplate } from './wageringTemplateSchema'
import Select from 'react-select'
import useClientFilter from '../../../../components/ClientFilter/useClientFilter'
import WageringListEdit from './WageringListEdit'
import { toast } from '../../../../components/Toast'
import AggregatorList from '../../../../shared/AggregatorList'
import AggregatorCategories from '../../../../shared/AggregatorCategories'

const CreateWageringTemplate = () => {
  const {
    loading,
    navigate,
    selectedProvider,
    setSelectedProvider,
    gameContribution,
    casinoGamesData,
    setGameContribution,
    customValue,
    customValueHandler,
    formSubmitHandler,
    multiAdminHandler,
    isClone,
    wageringTemplateDetail,
    state,
    setState,
    search,
    setSearch,
    setContributionDefaultValue,
    contributionDefaultValue,
    page,
    limit,
    setLimit,
    setPage,
    totalPages,
    aggregatorsOptions,
    selectedAggregatorId,
    setSelectedAggregatorId,
    aggregatorsCategoryOptions,
    selectedAggregatorCategoryId,
    setSelectedAggregatorCategoryId
  } = useCreateWageringTemplate()
  const { clientsFilterData } = useClientFilter({ refetch: false })

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col>
                <h3>{isClone ? 'Clone' : 'Create'} Wagering Template{isClone && wageringTemplateDetail?.name && `: ${wageringTemplateDetail?.name}`}</h3>
              </Col>
              <Col xs='auto'>
                <Button
                  size='sm'
                  variant='outline-success'
                  onClick={() => {
                    if (Object.keys(gameContribution).length < 1) {
                      toast('select at least one game', 'error')
                    } else if (!customValue) {
                      toast('enter custom value before save', 'error')
                    } else {
                      toast('Contribution Saved Successfully', 'success')
                      setContributionDefaultValue((oldValue) => {
                        return { ...oldValue, ...gameContribution }
                      })
                      setGameContribution({})
                    }
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>

            <Formik
              initialValues={{
                name: state?.name ? state?.name : '',
                customValue: state?.customValue ? state?.customValue : ''
              }}
              validationSchema={isClone ? editWageringTemplate : createWageringTemplate}
              onSubmit={(formValues) => {
                formSubmitHandler(formValues)
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur
              }) => (
                <Form>
                  <Row>
                    <Col xs='auto'>
                      <BForm.Label>
                        Template Name <span className='text-danger'>*</span>
                      </BForm.Label>

                      <BForm.Control
                        type='text'
                        name='name'
                        size='sm'
                        style={{ maxWidth: '400px' }}
                        placeholder='Enter Name'
                        value={state?.name}
                        onChange={(e) => {
                          handleChange(e)
                          setState({
                            ...state,
                            name: e.target.value
                          })
                        }}
                        onBlur={handleBlur}
                      />

                      <ErrorMessage
                        component='div'
                        name='name'
                        className='text-danger small-size-error'
                      />
                    </Col>
                    <Col xs='auto'>
                      <BForm.Label>
                        Custom Value {!isClone && <span className='text-danger'>*</span>}
                      </BForm.Label>

                      <BForm.Control
                        type='number'
                        name='customValue'
                        size='sm'
                        min='0'
                        style={{ maxWidth: '400px' }}
                        placeholder='Custom Value'
                        value={customValue}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            handleChange(e)
                            customValueHandler(e.target.value)
                          }
                        }}
                        onBlur={handleBlur}
                      />

                      <ErrorMessage
                        component='div'
                        name='customValue'
                        className='text-danger small-size-error'
                      />
                    </Col>

                    <Col xs={3}>
                      <BForm.Label>
                        Owner
                      </BForm.Label>
                      <Select
                        isMulti
                        isClearable={false}
                        name='tenantIds'
                        options={clientsFilterData?.rows || []}
                        classNamePrefix='select'
                        value={state.owner}
                        getOptionValue={option => option.adminUserId}
                        getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                        onChange={(option, e) => {
                          multiAdminHandler(option)
                          setState({
                            ...state,
                            owner: option
                          })
                        }}
                      />
                    </Col>
                    <Col xs='auto'>
                      <AggregatorList
                        aggregatorOptions={aggregatorsOptions}
                        selectedAggregatorId={selectedAggregatorId}
                        setSelectedAggregatorId={setSelectedAggregatorId}
                      />
                    </Col>
                    <Col xs='auto' className='align-items-center provider'>
                      <ProviderFilter
                        selectedProvider={selectedProvider}
                        setSelectedProvider={setSelectedProvider}
                        aggregatorId={selectedAggregatorId}
                      />
                    </Col>
                    <Col xs='auto'>
                      <BForm.Label>
                        Search
                      </BForm.Label>

                      <BForm.Control
                        type='search'
                        size='sm'
                        style={{ maxWidth: '400px' }}
                        placeholder='Search Game Name'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onBlur={handleBlur}
                      />
                    </Col>
                    <Col xs='auto'>
                      <AggregatorCategories
                        categoriesOptions={aggregatorsCategoryOptions}
                        selectedCategoryId={selectedAggregatorCategoryId}
                        setSelectedCategoryId={setSelectedAggregatorCategoryId}
                      />
                    </Col>
                  </Row>

                  <Row />

                  <Row>
                    {casinoGamesData &&
                      <WageringListEdit
                        gameContribution={gameContribution}
                        casinoGamesData={casinoGamesData || []}
                        setGameContribution={setGameContribution}
                        customValue={customValue}
                        defaultGames={contributionDefaultValue || {}}
                        page={page}
                        limit={limit}
                        setLimit={setLimit}
                        setPage={setPage}
                        totalPages={totalPages}
                      />}
                  </Row>

                  <div
                    className='mt-4 d-flex justify-content-between align-items-center'
                  >
                    <Button
                      variant='outline-warning'
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>

                    <div>
                      <Button
                        variant='outline-success'
                        onClick={handleSubmit}
                      >
                        Submit
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
          )}
    </>
  )
}

export default CreateWageringTemplate
