import React from 'react'
import { Form } from '@themesberg/react-bootstrap'
const LicenseSelector = ({
  label = 'License',
  options = [],
  value = '',
  onChange,
  placeholder = 'Please select License',
  style = {},
  noOptionsMessage = 'No license Found',
  selectClassName,
  cms,
  disabled = false
}) => {
  return (
    <>
      <Form.Label className='m-1'>{label}</Form.Label>
      <Form.Select
        className={selectClassName}
        style={style}
        value={value}
        onChange={onChange}
        disabled={cms || disabled}
      >
        <option value=''>{placeholder}</option>

        {options.length === 0
          ? (
            <option value='' disabled>
              {noOptionsMessage}
            </option>
            )
          : (
              options.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))
            )}
      </Form.Select>
    </>
  )
}
export default LicenseSelector
