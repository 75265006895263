import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSAProvidersStart } from '../../store/redux-slices/superAdminCasinoManagement'
import { getAllTAProvidersStart } from '../../store/redux-slices/tenantCasino'

const useAllProviderList = ({ isTenantRoute, tenantId = '', tenantIds = '[]', aggregatorId = '' }) => {
  const dispatch = useDispatch()
  const { allProviders } = useSelector((state) => isTenantRoute ? state.tenantCasino : state.superAdminCasino)

  useEffect(() => {
    if (isTenantRoute) {
      dispatch(getAllTAProvidersStart({ aggregatorId }))
    } else {
      dispatch(getAllSAProvidersStart({ tenantId, tenantIds: typeof tenantIds === 'string' ? tenantIds : JSON.stringify(tenantIds), aggregatorId }))
    }
  }, [aggregatorId])

  return {
    allProviders
  }
}

export default useAllProviderList
