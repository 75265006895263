import { useDispatch, useSelector } from 'react-redux'
import { updateTenantCategoryGameStart } from '../../../../store/redux-slices/tenantCasino'

const useEditCasinoGames = ({ aggregatorId, aggregatorCategoryId }) => {
  const { loading } = useSelector((state) => state.tenantCasino)
  const dispatch = useDispatch()

  const editGames = ({
    data,
    limit,
    pageNo,
    casinoCategoryId,
    isActive,
    providerId
  }) =>
    dispatch(updateTenantCategoryGameStart({
      data,
      limit,
      pageNo,
      casinoCategoryId: casinoCategoryId === null ? '' : casinoCategoryId,
      isActive,
      providerId,
      aggregatorId,
      aggregatorCategoryId
    }))

  return {
    editGames,
    loading
  }
}

export default useEditCasinoGames
