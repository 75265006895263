import { Table, Dropdown, Button, Form } from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../OverlayTrigger'
import useLanguageMgmt from './hooks/useLanguageMgmt'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { keysNotToBeShown } from './hooks/constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { faDownload, faLanguage, faPencil, faPlus, faUpload } from '@fortawesome/pro-solid-svg-icons'
import { languageCode } from '../../pages/Tenant/TenantConfigurations/Languages/constants'
import UploadModal from './components/uploadModal'
import './style.css'
import Select from 'react-select'
import Preloader from '../Preloader'
import useCheckPermission from '../../utils/checkPermission'
import { downloadFile } from '../../utils/fileDownloader'
import ClientFilter from '../ClientFilter'
import PortalFilter from '../PortalFilter'
import { checkIsTenant } from '../../utils/constants'
import CustomSelect from '../../shared/CustomSelect'

export default ({
  errorCodes = window.location.href.includes('/err/')
}) => {
  const isTenant = checkIsTenant()
  const {
    search,
    myKeys,
    navigate,
    setSearch,
    myKeyData,
    keyOptions,
    myLanguages,
    uploadModal,
    setKeySearch,
    handleUpload,
    setUploadModal,
    selectedClient,
    selectedPortal,
    handleDownload,
    setSelectedPortal,
    setSelectedClient,
    availableLanguages,
    licenseListOptions,
    loadingLicenseList,
    selectedLicense,
    setSelectedLicense,
    languageKeys
  } = useLanguageMgmt({ isTenant, errorCodes })

  const { isHidden } = useCheckPermission()
  const showData = isTenant ? true : (selectedClient === '' || selectedPortal)
  return (
    <>

      <div>
        <div className='d-flex justify-content-between'>
          <h3><FontAwesomeIcon icon={faLanguage} />&nbsp;{errorCodes ? 'Error' : 'Language'} Management {errorCodes ? '(PaymentIQ)' : ''}</h3>
          <div className='d-flex justify-content-between'>
            {(!errorCodes ? !isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) : !isHidden({ module: { key: 'CashierManagement', value: 'U' } })) &&
              <div className='m-1'>
                <Button
                  size='sm'
                  variant='success'
                  hidden={errorCodes}
                  style={{ lineHeight: '1' }}
                  onClick={() => !isTenant
                    ? navigate(`/super-admin/${errorCodes ? 'err/' : ''}language-management/add/keys`, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense } })
                    : navigate(`/tenant/${errorCodes ? 'err/' : ''}language-management/add/keys`, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense } })}
                >Add Keys
                </Button>&nbsp;
                {/* {!errorCodes && */}
                <Dropdown
                  className='d-inline'
                >
                  <Dropdown.Toggle disabled={!showData} id='dropdown-autoclose-outside' className='float-end btn-success' style={{ padding: '5px 10px', fontSize: '13px', lineHeight: '0' }}>
                    Add Language <FontAwesomeIcon icon={faPlus} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='user-dropdown language'>
                    {availableLanguages?.length
                      ? availableLanguages?.map(lang => {
                        return (
                          <Dropdown.Item
                            key={lang}
                            onClick={() => !isTenant
                              ? navigate(`/super-admin/${errorCodes ? 'err/' : ''}language-management/add-language/${lang}`, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense } })
                              : navigate(`/tenant/${errorCodes ? 'err/' : ''}language-management/add-language/${lang}`, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense } })}
                          >{languageCode[lang]}
                          </Dropdown.Item>
                        )
                      })
                      : (
                        <Dropdown.Item
                          className='text-danger'
                          disabled
                        ><strong>No Languages Available To Add</strong>
                        </Dropdown.Item>
                        )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>}
            {(!errorCodes ? !isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) : !isHidden({ module: { key: 'CashierManagement', value: 'U' } })) &&
              <div className='m-1'>
                <Dropdown
                  className=' d-inline'
                >
                  <Dropdown.Toggle disabled={!showData} id='dropdown-autoclose-outside' className='float-end btn-warning' style={{ padding: '5px 10px', fontSize: '13px', lineHeight: '0' }}>
                    Edit <FontAwesomeIcon icon={faPencil} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='user-dropdown lang'>
                    <Dropdown.Item
                      onClick={() => !isTenant
                        ? navigate(errorCodes ? SuperAdminRoutes.EditErrSupportLanguage : SuperAdminRoutes.EditSupportLanguage, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense } })
                        : navigate(errorCodes ? TenantRoutes.EditErrSupportLanguage : TenantRoutes.EditSupportLanguage, { state: { myLicenseId: selectedLicense } })}
                    >Edit by Position
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => !isTenant
                        ? navigate(errorCodes ? SuperAdminRoutes.EditErrSupportLanguageKeys : SuperAdminRoutes.EditSupportLanguageKeys, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense } })
                        : navigate(errorCodes ? TenantRoutes.EditErrSupportLanguageKeys : TenantRoutes.EditSupportLanguageKeys, { state: { myLicenseId: selectedLicense } })}
                    >Edit By Key
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>}
          </div>
        </div>

        <div className='d-flex align-items-center flex-wrap custom-input-group'>
          {!isTenant &&
            <>
              <div className='m-1 label-input-block'>
                <ClientFilter
                  setSelectedClient={setSelectedClient}
                  setSelectedPortal={setSelectedPortal}
                  selectedClient={selectedClient}
                />
              </div>

              <div className='m-1 label-input-block'>
                <PortalFilter
                  setSelectedPortal={setSelectedPortal}
                  selectedPortal={selectedPortal}
                  selectedClient={selectedClient}
                  cms={selectedClient === ''}
                  hasAllOptions={false}
                />
              </div>
            </>}
          <div className='m-1 label-input-block'>
            <CustomSelect
              label='License'
              size='sm'
              options={licenseListOptions || []}
              value={selectedLicense}
              onChange={setSelectedLicense}
              hasAllOptions
              disabled={!selectedPortal || loadingLicenseList}
              fieldName=''
              placeholder='Please Select License'
              noOptionsMessage='No License Found'
            />
          </div>
          <div className='m-1 label-input-block'>
            <Form.Label className='m-1'>Search Keys : </Form.Label> &nbsp;
            <Select
              name='keys'
              options={keyOptions}
              isDisabled={!showData}
              placeholder='Select Keys'
              defaultValue={{ label: 'All', value: '' }}
              styles={{ container: styles => ({ ...styles, zIndex: '2', width: '250px' }) }}
              className='basic-multi-select zindex-dropdown m-1'
              classNamePrefix='select'
              onChange={(option, e) => {
                setKeySearch(option.value)
              }}
            />
          </div>

          <div className='m-1 label-input-block'>
            <Form.Label className='m-1'>Search : </Form.Label>&nbsp;
            <Form.Control
              name='search'
              disabled={!showData}
              type='search'
              placeholder='Search'
              value={search}
              className=''
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>

          <Trigger message='Download Xls File'>
            <Button
              variant='success'
              hidden={errorCodes}
              disabled={!showData}
              className='m-1'
              onClick={() => { downloadFile(handleDownload()) }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </Trigger>
          <Trigger message='Upload xls/xlsx File'>
            <Button
              variant='success'
              className='m-1'
              disabled={!showData}
              hidden={isHidden({ module: { key: 'MultiLanguage', value: 'C' } }) || errorCodes}
              onClick={() => setUploadModal(true)}
            >
              <FontAwesomeIcon icon={faUpload} />
            </Button>
          </Trigger>
        </div>
      </div>

      {(!myKeys || !Object.keys(myKeys).length)
        ? <Preloader />
        : showData
          ? (
            <>
              <div style={{ overflow: 'auto', maxHeight: '82vh', marginTop: '10px' }}>
                <Table
                  bordered
                  striped
                  size='sm'
                >
                  <thead className='thead-dark fixTableHeadSA-th'>
                    <tr>
                      <th>Keys</th>
                      {(errorCodes ? !isHidden({ module: { key: 'CashierManagement', value: 'U' } }) : !isHidden({ module: { key: 'MultiLanguage', value: 'U' } })) && <th>Action</th>}
                      {Array.from(myLanguages).map(language => {
                        return myLanguages.has(language) && (
                          <th key={language}>{languageCode[language]}</th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {myKeyData && Object.keys(myKeyData).map(key => {
                      return (
                        !keysNotToBeShown.includes(key) && (
                          <tr key={key}>
                            <td className='fw-bold'>{key}</td>
                            {(errorCodes ? !isHidden({ module: { key: 'CashierManagement', value: 'U' } }) : !isHidden({ module: { key: 'MultiLanguage', value: 'U' } })) &&
                              <td style={{ textAlign: 'center', width: '0px' }}>
                                <Button
                                  variant='warning'
                                  size='sm'
                                  onClick={() => {
                                    isTenant
                                      ? navigate(`/tenant/${errorCodes ? 'err/' : ''}language-management/edit/${key}`, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense || (languageKeys?.length ? languageKeys[0]?.LicenseId : '') } })
                                      : navigate(`/super-admin/${errorCodes ? 'err/' : ''}language-management/edit/${key}`, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '', myLicenseId: selectedLicense || (languageKeys?.length ? languageKeys[0]?.LicenseId : '') } })
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                              </td>}
                            {
                 Array.from(myLanguages).map(lang => {
                   return (
                     <td key={lang}>
                       <Trigger message={myKeyData[key][lang]}>
                         <span
                           style={{
                             width: '300px',
                             cursor: 'pointer'
                           }}
                         >
                           {myKeyData[key][lang]}
                         </span>
                       </Trigger>
                     </td>
                   )
                 })
                }
                          </tr>
                        )
                      )
                    })}
                  </tbody>
                </Table>
                {(!myKeyData || !Object.keys(myKeyData)?.length) && (
                  <p className='text-danger text-center'> No data found</p>
                )}
              </div>
            </>)
          : <div className='d-flex justify-content-center text-danger'>Please Select Portal</div>}

      {uploadModal &&
        <UploadModal
          show={uploadModal}
          setShow={setUploadModal}
          handleYes={handleUpload}
        />}
    </>
  )
}
