import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getLicenseListStart } from '../../store/redux-slices/licenseManagement'
const useLicenseList = (selectedPortal, fetchAllLicense) => {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const [selectedLicense, setSelectedLicense] = useState(state?.myLicenseId || '')

  const { loadingLicenseList, licenseList } = useSelector((state) => state.licenseManagement)

  useEffect(() => {
    if (selectedPortal || fetchAllLicense) {
      dispatch(
        getLicenseListStart({
          tenantId: selectedPortal || ''
        })
      )
    }
  }, [selectedPortal])

  const licenseListOptions = useMemo(() => {
    return licenseList
      ? licenseList.map((item) => ({
        value: item.id,
        label: item.name
      }))
      : []
  }, [licenseList])

  return {
    loadingLicenseList,
    licenseListOptions,
    selectedLicense,
    setSelectedLicense
  }
}

export default useLicenseList
