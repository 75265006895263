import React from 'react'
import Select from 'react-select'

const ReactSelect = ({ value, onChange, options, name, formik, ...props }) => {
  return (
    <Select
      value={value}
      options={options}
      onChange={(selectedValue) => {
        onChange({ selectedValue, name, formik })
      }}
      {...props}
    />
  )
}

export default ReactSelect
