import { useMemo, useEffect, useState } from 'react'
import { formFieldClasses, getBaseLicenseFormFields, getBasePaymentsFormFields } from '../constant'
import useOwners from '../../../../shared/hooks/useOwners'
import usePortals from '../../../../shared/hooks/usePortals'
import useCountries from './useCountries'
import { useDispatch, useSelector } from 'react-redux'
import { getLicenseDetailStart, updateLicenseRecordStart } from '../../../../store/redux-slices/licenseManagement'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkIsTenant } from '../../../../utils/constants'
import { getCountriesStart } from '../../../../store/redux-slices/fetchData'
import { getCountryDetailsByCodes } from '../../../../utils/helpers'
import useExcludedLicenseList from '../../../../shared/hooks/useExcludedLicenseList'

const useEditLicense = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isTenant = checkIsTenant()
  const [selectedTab, setSelectedTab] = useState('general')

  // Selectors to get necessary state from the Redux store
  const { licenseDetails, loadingLicenseDetails } = useSelector((state) => state.licenseManagement)
  const { ownerOptions, selectedOwner, setSelectedOwner } = useOwners()
  const { portalOptions, selectedPortal, setSelectedPortal } = usePortals(selectedOwner)
  const { countryOptions, selectedCountry, setSelectedCountry } = useCountries(selectedPortal)
  const { countries: allCountries } = useSelector((state) => state.fetch)
  const isLicenseView = location?.pathname?.includes('license/view')
  const isEdit = true
  const { excludedLicenseListOptions, selectedExcludedLicense, setSelectedExcludedLicense } = useExcludedLicenseList()
  const { excludedLicenseList } = useSelector((state) => state.licenseManagement)

  // Fetch license details when licenseId is present in location state
  useEffect(() => {
    const { licenseId, tenantId } = location?.state || {}
    if (licenseId && tenantId) {
      dispatch(getLicenseDetailStart({ licenseId, tenantId }))
    }
  }, [location, dispatch])

  // Set selected options based on license details
  useEffect(() => {
    if (licenseDetails) {
      setSelectedOwner(licenseDetails?.Tenant?.AdminUser?.adminUserId || '')
      setSelectedPortal(licenseDetails?.tenantId || '')
      setSelectedCountry(licenseDetails.countryCodes || [])
    }
  }, [licenseDetails, setSelectedOwner, setSelectedPortal, setSelectedCountry])

  // Fetch all countries on mount
  useEffect(() => {
    dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '', kycMethod: '' }))
  }, [])

  // Memoized all country options
  const allCountryOptions = useMemo(() => {
    if (Array.isArray(allCountries)) {
      return allCountries
        .map(country => ({
          value: country.code,
          label: country.name?.EN
        }))
        .filter(Boolean)
    }
    return []
  }, [allCountries])

  // Memoized selected country options based on license details
  const selectedCountryOptions = useMemo(() => {
    return licenseDetails?.countryCodes && getCountryDetailsByCodes(allCountryOptions, licenseDetails.countryCodes)
  }, [allCountryOptions, licenseDetails])

  // Combine country options and selected country options safely
  const updatedCountryOptions = useMemo(() => [
    ...(Array.isArray(countryOptions) ? countryOptions : []),
    ...(Array.isArray(selectedCountryOptions) ? selectedCountryOptions : [])
  ], [countryOptions, selectedCountryOptions])

  const defaultEditLicenseFormValues = useMemo(() => ({
    licenseNo: licenseDetails?.licenseNo || '',
    licenseId: licenseDetails?.id || '',
    ownerId: licenseDetails?.Tenant?.AdminUser?.adminUserId || '',
    tenantId: licenseDetails?.tenantId || '',
    name: licenseDetails?.name || '',
    countryCodes: licenseDetails?.countryCodes || [],
    credDetails: {
      piqDetails: {
        PAYMENTIQ_MERCHANT_ID: licenseDetails?.credDetails?.piqDetails?.PAYMENTIQ_MERCHANT_ID || '',
        PAYMENTIQ_CLIENT_ID: licenseDetails?.credDetails?.piqDetails?.PAYMENTIQ_CLIENT_ID || '',
        PAYMENTIQ_CLIENT_SECRET: licenseDetails?.credDetails?.piqDetails?.PAYMENTIQ_CLIENT_SECRET || ''
      },
      nodapayDetails: {
        NODA_PAY_API_KEY: licenseDetails?.credDetails?.nodapayDetails?.NODA_PAY_API_KEY || '',
        NODA_PAY_SECRET_KEY: licenseDetails?.credDetails?.nodapayDetails?.NODA_PAY_SECRET_KEY || '',
        NODA_PAY_SHOP_ID: licenseDetails?.credDetails?.nodapayDetails?.NODA_PAY_SHOP_ID || ''
      },
      praxisDetails: {
        PRAXIS_MERCHANT_ID: licenseDetails?.credDetails?.praxisDetails?.PRAXIS_MERCHANT_ID || '',
        PRAXIS_MERCHANT_SECRET: licenseDetails?.credDetails?.praxisDetails?.PRAXIS_MERCHANT_SECRET || '',
        PRAXIS_APPLICATION_KEY: licenseDetails?.credDetails?.praxisDetails?.PRAXIS_APPLICATION_KEY || ''
      },
      siquroDetails: {
        SIQURO_TOKEN: licenseDetails?.credDetails?.siquroDetails?.SIQURO_TOKEN || '',
        SIQURO_BRAND_ID: licenseDetails?.credDetails?.siquroDetails?.SIQURO_BRAND_ID || ''
      },
      flaxpayDetails: {
        FLAXPAY_USER_ID: licenseDetails?.credDetails?.flaxpayDetails?.FLAXPAY_USER_ID || '',
        FLAXPAY_PASSWORD: licenseDetails?.credDetails?.flaxpayDetails?.FLAXPAY_PASSWORD || '',
        FLAXPAY_API_KEY: licenseDetails?.credDetails?.flaxpayDetails?.FLAXPAY_API_KEY || ''
      },
      coinspaidDetails: {
        COINSPAID_PUBLIC_KEY: licenseDetails?.credDetails?.coinspaidDetails?.COINSPAID_PUBLIC_KEY || '',
        COINSPAID_SECRET_KEY: licenseDetails?.credDetails?.coinspaidDetails?.COINSPAID_SECRET_KEY || ''
      },
      interkassaDetails: {
        INTERKASSA_USER_ID: licenseDetails?.credDetails?.interkassaDetails?.INTERKASSA_USER_ID || '',
        INTERKASSA_API_KEY: licenseDetails?.credDetails?.interkassaDetails?.INTERKASSA_API_KEY || '',
        INTERKASSA_ACCOUNT_ID: licenseDetails?.credDetails?.interkassaDetails?.INTERKASSA_ACCOUNT_ID || ''
      },
      utorgDetails: {
        UTORG_ID: licenseDetails?.credDetails?.utorgDetails?.UTORG_ID || '',
        UTORG_PUBLIC_KEY: licenseDetails?.credDetails?.utorgDetails?.UTORG_PUBLIC_KEY || ''
      }
    },
    isActive: licenseDetails?.isActive || false,
    html: licenseDetails?.html || '',
    script: licenseDetails?.script || '',
    description: licenseDetails?.description || ''
  }), [licenseDetails])

  const handleOwnerSelection = (selectedValues) => {
    setSelectedOwner(selectedValues)
    setSelectedPortal('')
    setSelectedCountry([])
  }

  const handlePortalSelection = (selectedValues) => {
    setSelectedPortal(selectedValues)
    setSelectedCountry([])
  }

  const handleCountrySelection = (selectedValues) => {
    setSelectedCountry(selectedValues)
  }

  const handleExcludedLicenseSelection = (selectedValues) => {
    setSelectedExcludedLicense(selectedValues)
  }

  // Generate form fields for the license form
  const licenseFormFields = useMemo(() => {
    return getBaseLicenseFormFields(
      selectedOwner,
      selectedPortal,
      selectedCountry,
      selectedExcludedLicense,
      ownerOptions,
      portalOptions,
      updatedCountryOptions,
      excludedLicenseListOptions,
      handleOwnerSelection,
      handlePortalSelection,
      handleCountrySelection,
      handleExcludedLicenseSelection,
      isTenant,
      isLicenseView,
      isEdit
    ).map((field) => ({
      ...formFieldClasses,
      ...field
    }))
  }, [selectedOwner, selectedPortal, selectedCountry, ownerOptions, portalOptions, countryOptions, excludedLicenseList, selectedExcludedLicense, updatedCountryOptions, isTenant, isLicenseView])

  const paymentsFormFields = useMemo(() => {
    return getBasePaymentsFormFields(
      isLicenseView
    ).map((field) => ({
      ...field
    }))
  }, [selectedOwner, selectedPortal, selectedCountry])
  // Handle form submission
  const handleFormSubmit = (licenseFormValues) => {
    const updatedValues = {
      ...licenseFormValues,
      licenseId: String(licenseFormValues.licenseId),
      tenantId: String(licenseFormValues.tenantId)
    }
    dispatch(updateLicenseRecordStart({ licenseFormValues: updatedValues, navigate }))
  }

  return {
    licenseFormFields,
    defaultLicenseFormValues: defaultEditLicenseFormValues,
    handleFormSubmit,
    navigate,
    isLicenseView,
    loadingLicenseDetails,
    selectedTab,
    setSelectedTab,
    paymentsFormFields
  }
}

export default useEditLicense
