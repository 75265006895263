import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getExcludedLicenseListStart,
    getExcludedLicenseListSuccess,
    getExcludedLicenseListFailure,
    getLicenseListStart,
    getLicenseListSuccess,
    getLicenseListCountSuccess,
    getLicenseListFailure,
    createLicenseRecordStart,
    createLicenseRecordSuccess,
    createLicenseRecordFailure,
    updateLicenseRecordStart,
    updateLicenseRecordSuccess,
    updateLicenseRecordFailure,
    getLicenseDetailStart,
    getLicenseDetailSuccess,
    getLicenseDetailFailure,
    getCountryListStart,
    getCountryListSuccess,
    getCountryListFailure,
    updateGlobalLicenseStart,
    updateGlobalLicenseComplete,
    startSettingDefaultLicense,
    settingDefaultLicenseSuccess,
    settingDefaultLicenseFailure
  },
  reducer
} = createSlice({
  name: 'licenseManagements',
  initialState: {
    loading: false,
    loadingLicenseList: false,
    loadingLicenseDetails: false,
    licenseDetails: null,
    countryList: [],
    licenseList: [],
    excludedLicenseList: [],
    licenseListCount: null,
    error: null
  },

  reducers: {
    // Get License List
    getLicenseListStart: (state) => ({ ...state, loadingLicenseList: true, error: null }),
    getLicenseListSuccess: (state, action) => ({ ...state, loadingLicenseList: false, licenseList: action.payload }),
    getLicenseListCountSuccess: (state, action) => ({ ...state, loadingLicenseList: false, licenseListCount: action.payload }),
    getLicenseListFailure: (state, action) => ({ ...state, loadingLicenseList: false, error: action.payload }),

    getExcludedLicenseListStart: (state) => ({ ...state, loadingLicenseList: true, error: null }),
    getExcludedLicenseListSuccess: (state, action) => ({ ...state, loadingLicenseList: false, excludedLicenseList: action.payload }),
    getExcludedLicenseListFailure: (state, action) => ({ ...state, loadingLicenseList: false, error: action.payload }),

    // Create License Record
    createLicenseRecordStart: (state) => ({ ...state, loading: true, error: null }),
    createLicenseRecordSuccess: (state) => ({ ...state, loading: false }),
    createLicenseRecordFailure: (state, action) => ({ ...state, loading: false, error: action.payload }),

    // Update License Record
    updateLicenseRecordStart: (state) => ({ ...state, loading: true, error: null }),
    updateLicenseRecordSuccess: (state) => ({ ...state, loading: false }),
    updateLicenseRecordFailure: (state, action) => ({ ...state, loading: false, error: action.payload }),

    // Get License Detail
    getLicenseDetailStart: (state) => ({ ...state, loadingLicenseDetails: true, error: null }),
    getLicenseDetailSuccess: (state, action) => ({ ...state, loadingLicenseDetails: false, licenseDetails: action.payload }),
    getLicenseDetailFailure: (state, action) => ({ ...state, loadingLicenseDetails: false, error: action.payload }),

    // Get Country List
    getCountryListStart: (state) => ({ ...state, error: null }),
    getCountryListSuccess: (state, action) => ({ ...state, countryList: action.payload }),
    getCountryListFailure: (state, action) => ({ ...state, error: action.payload }),

    // Update Global License Record
    updateGlobalLicenseStart: (state) => ({ ...state, loading: true, error: null }),
    updateGlobalLicenseComplete: (state) => ({ ...state, loading: false }),

    startSettingDefaultLicense: (state) => ({ ...state, loadingLicenseList: true, error: null }),
    settingDefaultLicenseSuccess: (state) => ({ ...state, loadingLicenseList: false }),
    settingDefaultLicenseFailure: (state) => ({ ...state, loadingLicenseList: false })
  }
})

export default reducer

// Exporting actions
export {
  getExcludedLicenseListStart,
  getExcludedLicenseListSuccess,
  getExcludedLicenseListFailure,
  getLicenseListStart,
  getLicenseListSuccess,
  getLicenseListCountSuccess,
  getLicenseListFailure,
  createLicenseRecordStart,
  createLicenseRecordSuccess,
  createLicenseRecordFailure,
  updateLicenseRecordStart,
  updateLicenseRecordSuccess,
  updateLicenseRecordFailure,
  getLicenseDetailStart,
  getLicenseDetailSuccess,
  getLicenseDetailFailure,
  getCountryListStart,
  getCountryListSuccess,
  getCountryListFailure,
  updateGlobalLicenseStart,
  updateGlobalLicenseComplete,
  startSettingDefaultLicense,
  settingDefaultLicenseSuccess,
  settingDefaultLicenseFailure
}
