import { useMemo, useState } from 'react'
import { formFieldClasses, getBaseLicenseFormFields, getBasePaymentsFormFields } from '../constant'
import useOwners from '../../../../shared/hooks/useOwners'
import usePortals from '../../../../shared/hooks/usePortals'
import useCountries from './useCountries'
import { useDispatch, useSelector } from 'react-redux'
import { createLicenseRecordStart } from '../../../../store/redux-slices/licenseManagement'
import { useNavigate } from 'react-router-dom'
import { checkIsTenant } from '../../../../utils/constants'
import useExcludedLicenseList from '../../../../shared/hooks/useExcludedLicenseList'

// Custom hook for managing license-related logic and state
const useAddLicense = () => {
  // Use the new hooks for owners and portals
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTenant = checkIsTenant()
  const { ownerOptions, selectedOwner, setSelectedOwner } = useOwners()
  const { portalOptions, selectedPortal, setSelectedPortal } = usePortals(selectedOwner)
  const { countryOptions, selectedCountry, setSelectedCountry } = useCountries(selectedPortal)
  const [selectedTab, setSelectedTab] = useState('general')

  const { excludedLicenseList } = useSelector((state) => state.licenseManagement)
  const { excludedLicenseListOptions, selectedExcludedLicense, setSelectedExcludedLicense } = useExcludedLicenseList(selectedPortal)

  // Default values for Add License Form
  const defaultAddLicenseFormValues = {
    licenseNo: '',
    ownerId: isTenant ? selectedOwner : '',
    tenantId: isTenant ? selectedPortal : '',
    licenseId: '',
    countryCodes: [],
    credDetails: {
      piqDetails: {
        PAYMENTIQ_MERCHANT_ID: '',
        PAYMENTIQ_CLIENT_ID: '',
        PAYMENTIQ_CLIENT_SECRET: ''
      },
      nodapayDetails: {
        NODA_PAY_API_KEY: '',
        NODA_PAY_SECRET_KEY: '',
        NODA_PAY_SHOP_ID: ''
      },
      praxisDetails: {
        PRAXIS_MERCHANT_ID: '',
        PRAXIS_MERCHANT_SECRET: '',
        PRAXIS_APPLICATION_KEY: ''
      },
      siquroDetails: {
        SIQURO_TOKEN: '',
        SIQURO_BRAND_ID: ''
      },
      flaxpayDetails: {
        FLAXPAY_USER_ID: '',
        FLAXPAY_PASSWORD: '',
        FLAXPAY_API_KEY: ''
      },
      coinspaidDetails: {
        COINSPAID_PUBLIC_KEY: '',
        COINSPAID_SECRET_KEY: ''
      },
      interkassaDetails: {
        INTERKASSA_USER_ID: '',
        INTERKASSA_API_KEY: '',
        INTERKASSA_ACCOUNT_ID: ''
      },
      utorgDetails: {
        UTORG_ID: '',
        UTORG_PUBLIC_KEY: ''
      }
    },
    isActive: true,
    html: '',
    script: '',
    description: ''
  }

  const handleOwnerSelection = (selectedValues) => {
    setSelectedOwner(selectedValues)
    setSelectedPortal('')
    setSelectedCountry([])
  }

  const handlePortalSelection = (selectedValues) => {
    setSelectedPortal(selectedValues)
    setSelectedCountry([])
  }

  const handleCountrySelection = (selectedValues) => {
    setSelectedCountry(selectedValues)
  }

  const handleExcludedLicenseSelection = (selectedValues) => {
    setSelectedExcludedLicense(selectedValues)
  }

  // Generate form fields for the license form using useMemo
  const licenseFormFields = useMemo(() => {
    return getBaseLicenseFormFields(
      selectedOwner,
      selectedPortal,
      selectedCountry,
      selectedExcludedLicense,
      ownerOptions,
      portalOptions,
      countryOptions,
      excludedLicenseListOptions,
      handleOwnerSelection,
      handlePortalSelection,
      handleCountrySelection,
      handleExcludedLicenseSelection,
      isTenant
    ).map((field) => ({
      ...formFieldClasses,
      ...field
    }))
  }, [selectedOwner, selectedPortal, selectedCountry, ownerOptions, portalOptions, countryOptions, excludedLicenseList, selectedExcludedLicense])

  const paymentsFormFields = useMemo(() => {
    return getBasePaymentsFormFields(
      false
    ).map((field) => ({
      ...field
    }))
  }, [selectedOwner, selectedPortal, selectedCountry])

  const handleFormSubmit = (licenseFormValues) => {
    licenseFormValues.tenantId = String(licenseFormValues.tenantId)
    const selectedLicenseName = excludedLicenseListOptions?.filter((option) => option.value === licenseFormValues.licenseId)
    licenseFormValues.name = selectedLicenseName?.length > 0 ? selectedLicenseName[0]?.label : ''
    dispatch(createLicenseRecordStart({ licenseFormValues, navigate }))
  }

  return {
    licenseFormFields,
    defaultLicenseFormValues: defaultAddLicenseFormValues,
    handleFormSubmit,
    navigate,
    paymentsFormFields,
    selectedTab,
    setSelectedTab
  }
}

export default useAddLicense
