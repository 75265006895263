import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getAllCasinoGamesStart,
  getCasinoThemesStart,
  getSuperAdminGameSubCategoryStart,
  updateSACasinoGamesStatusStart
} from '../../../../store/redux-slices/superAdminCasinoManagement'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getAllClientsStart } from '../../../../store/redux-slices/tenants'
import { getCountriesStart } from '../../../../store/redux-slices/fetchData'
import useAggregatorList from '../../../../shared/hooks/useAggregatorList'
import useAggregatorCategories from '../../../../shared/hooks/useAggregatorCategories'
import { getTenantId } from '../../../../utils/constants'

const useCasinoGamesListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedSubCategoryId, setSelectedSubCategoryId] =
    useState('')
  const [show, setShow] = useState(false)
  const [data, setData] = useState()
  const [type, setType] = useState('')
  const [search, setSearch] = useState('')
  const [casinoGameId, setCasinoGameId] = useState()
  const [active, setActive] = useState('')
  const [status, setStatus] = useState()
  const [statusShow, setStatusShow] = useState(false)
  const [showGameIds, setShowGameIds] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [name, setName] = useState()

  const {
    aggregatorsOptions,
    selectedAggregatorId,
    setSelectedAggregatorId
  } = useAggregatorList({
    tenantId: getTenantId()
  })

  const {
    aggregatorsCategoryOptions,
    selectedAggregatorCategoryId,
    setSelectedAggregatorCategoryId
  } = useAggregatorCategories({
    gameAggregatorId: selectedAggregatorId,
    search,
    setSelectedProvider
  })

  const { loading, casinoGamesData, gameSubCategory, allProviders } = useSelector(
    (state) => state.superAdminCasino
  )

  const totalPages = Math.ceil(casinoGamesData?.count / limit)

  const handleStatusShow = (id, status, myName) => {
    setCasinoGameId(id)
    setStatus(!status)
    setName(myName)
    setStatusShow(true)
  }

  const getCategoryName = (id) =>
    gameSubCategory?.rows.find((val) => val.masterGameSubCategoryId === id)?.name?.EN

  const getProviderName = (id) => {
    if (!allProviders) return '' // Handle case where allProviders is null or undefined

    // Ensure we handle rows correctly
    const providers = Array.isArray(allProviders?.rows)
      ? allProviders.rows
      : Array.isArray(allProviders)
        ? allProviders
        : []

    return providers.find((val) => val.masterCasinoProviderId === id)?.name || ''
  }

  const handleYes = () => {
    dispatch(
      updateSACasinoGamesStatusStart({
        data: {
          code: 'CASINO_GAME',
          masterCasinoGameId: casinoGameId,
          status
        },
        limit,
        pageNo: page,
        casinoCategoryId: selectedSubCategoryId,
        search,
        isActive: active,
        tenantId: '',
        selectedProvider,
        aggregatorId: selectedAggregatorId,
        aggregatorCategoryId: selectedAggregatorCategoryId
      })
    )
    setStatusShow(false)
  }

  useEffect(() => {
    dispatch(
      getSuperAdminGameSubCategoryStart({
        limit: '',
        pageNo: '',
        gameCategoryId: '',
        search: '',
        isActive: '',
        tenantId: ''
      })
    )
    dispatch(getAllClientsStart({
      search: '',
      limit: 100,
      pageNo: 1,
      orderBy: 'email',
      sort: 'desc'
    }))
    dispatch(getCasinoThemesStart({ isTenant: false }))
    dispatch(getCountriesStart({ limit: '', name: '', pageNo: '' }))
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllCasinoGamesStart({
              limit,
              pageNo: page,
              casinoCategoryId: selectedSubCategoryId,
              search,
              isActive: active,
              tenantId: '',
              selectedProvider,
              aggregatorId: selectedAggregatorId,
              aggregatorCategoryId: selectedAggregatorCategoryId
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    setPage(1)
    dispatch(
      getAllCasinoGamesStart({
        limit,
        pageNo: page,
        casinoCategoryId: selectedSubCategoryId,
        search,
        isActive: active,
        tenantId: '',
        selectedProvider,
        aggregatorId: selectedAggregatorId,
        aggregatorCategoryId: selectedAggregatorCategoryId
      })
    )
  }, [limit, active, selectedSubCategoryId, selectedProvider, selectedAggregatorId, selectedAggregatorCategoryId])

  useEffect(() => {
    !isInitialRender && dispatch(
      getAllCasinoGamesStart({
        limit,
        pageNo: page,
        casinoCategoryId: selectedSubCategoryId,
        search,
        isActive: active,
        tenantId: '',
        selectedProvider,
        aggregatorId: selectedAggregatorId,
        aggregatorCategoryId: selectedAggregatorCategoryId

      })
    )
  }, [page])

  const handleClose = () => setShow(false)
  const handleShow = (type, data) => {
    setType(type)
    setData(data)
    setShow(true)
  }

  return {
    limit,
    page,
    selectedSubCategoryId,
    show,
    data,
    type,
    statusShow,
    setLimit,
    setPage,
    setSelectedSubCategoryId,
    setStatusShow,
    gameSubCategory,
    casinoGamesData,
    totalPages,
    handleStatusShow,
    handleYes,
    handleClose,
    handleShow,
    getCategoryName,
    loading,
    search,
    setSearch,
    active,
    setActive,
    status,
    getProviderName,
    navigate,
    selectedProvider,
    setSelectedProvider,
    name,
    showGameIds,
    setShowGameIds,
    aggregatorsOptions,
    selectedAggregatorId,
    setSelectedAggregatorId,
    aggregatorsCategoryOptions,
    selectedAggregatorCategoryId,
    setSelectedAggregatorCategoryId
  }
}

export default useCasinoGamesListing
