import { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllClientsStart } from '../../store/redux-slices/tenants'
import { checkIsTenant } from '../../utils/constants'

const useAllOwner = ({ fetchOwners = false }) => {
  const dispatch = useDispatch()
  const isTenant = checkIsTenant()
  const { tenantDetails } = useSelector(state => state.login)
  const { clientsFilterData } = useSelector((state) => state.tenants)
  const [selectedOwner, setSelectedOwner] = useState(isTenant ? tenantDetails?.adminUserId : '')

  // Function to fetch owner list
  const fetchOwnerList = useCallback(() => {
    dispatch(
      getAllClientsStart({
        search: '',
        limit: 100,
        pageNo: 1,
        orderBy: 'email',
        sort: 'desc'
      })
    )
  }, [dispatch])

  // Fetch owner list if the `fetchOwners` prop is true
  useEffect(() => {
    if (fetchOwners) {
      fetchOwnerList()
    }
  }, [fetchOwners])

  // Memoized options for owner selection
  const ownerOptions = useMemo(() => {
    return clientsFilterData?.rows?.length > 0
      ? clientsFilterData.rows.map((row) => ({
        label: `${row.firstName} ${row.lastName}`, // Full name as label
        value: row.adminUserId // adminUserId as value
      }))
      : [] // Return an empty array if no rows
  }, [clientsFilterData])

  return {
    ownerOptions,
    selectedOwner,
    setSelectedOwner,
    fetchOwnerList // Expose fetchOwnerList for manual invocation
  }
}

export default useAllOwner
