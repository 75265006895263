import React from 'react'
import Input from '../FormFields/Input'
import CustomReactSelect from '../FormFields/CustomReactSelect'
import SwitchInput from '../FormFields/SwitchInput'
import TextEditor from '../FormFields/TextEditor'
import CustomCreatableSelect from '../FormFields/CustomCreatableSelect'
import ReactSelect from '../../Select/ReactSelect'

const FormFieldControl = (props) => {
  const { control } = props

  switch (control) {
    case 'input':
      return <Input {...props} />
    case 'select':
      return <CustomReactSelect {...props} />
    case 'createSelect':
      return <CustomCreatableSelect {...props} />
    case 'react-select':
      return <ReactSelect {...props} />
    case 'switchInput':
      return <SwitchInput {...props} />
    case 'textEditor':
      return <TextEditor {...props} />
    default:
      return <Input {...props} />
  }
}
export default FormFieldControl
