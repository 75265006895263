import { takeLatest, put, takeEvery, select } from 'redux-saga/effects'
import {
  getAllCasinoProviders,
  getAllCasinoGames,
  createCasinoProvider,
  createCasinoGame,
  updateCasinoGame,
  updateCasinoProvider,
  superAdminViewToggleStatus,
  getSuperAdminAggregators,
  createSuperAdminAggregator,
  getSuperAdminGameCategory,
  getSuperAdminGameSubCategory,
  getAllSAProviders,
  getFreeSpinGames,
  updateReorderGames,
  getCasinoThemes,
  getCasinoGameIds,
  getAllExcludedCasinoGames,
  tenantViewToggleStatus,
  editSuperAdminAggregator,
  addAggregators,
  getBonusPrepaidList,
  getAggregatorCategories
} from '../../utils/apiCalls'
import {
  getAllCasinoProvidersStart,
  getAllCasinoProvidersSuccess,
  getAllCasinoProvidersFailure,
  createCasinoProviderStart,
  createCasinoProviderComplete,
  updateCasinoProviderStart,
  updateCasinoProviderComplete,
  getAllCasinoGamesStart,
  getAllCasinoGamesSuccess,
  getAllCasinoGamesFailure,
  createCasinoGameStart,
  createCasinoGameComplete,
  updateCasinoGameStart,
  updateCasinoGameComplete,
  resetCasinoGameStart,
  resetCasinoGameSuccess,
  updateCasinoStatusStart,
  updateCasinoStatusComplete,
  getSuperAdminAggregatorsStart,
  getSuperAdminAggregatorsSuccess,
  getSuperAdminAggregatorsFailure,
  createSuperAdminAggregatorStart,
  createSuperAdminAggregatorComplete,
  updateSuperAdminAggregatorStatusStart,
  updateSuperAdminAggregatorStatusComplete,
  getSuperAdminGameCategoryStart,
  getSuperAdminGameCategorySuccess,
  getSuperAdminGameCategoryFailure,
  getSuperAdminGameSubCategoryStart,
  getSuperAdminGameSubCategorySuccess,
  getSuperAdminGameSubCategoryFailure,
  updateSACasinoGamesStatusStart,
  updateSACasinoGamesStatusComplete,
  getAllSAProvidersStart,
  getAllSAProvidersSuccess,
  getAllSAProvidersFailure,
  getFreeSpinGamesStart,
  getFreeSpinGamesSuccess,
  getFreeSpinGamesFailure,
  updateReorderGamesStart,
  updateReorderGamesSuccess,
  updateReorderGamesFailure,
  getCasinoThemesStart,
  getCasinoThemesSuccess,
  getCasinoThemesFailure,
  getUniqueGameIdsStart,
  getUniqueGameIdsSuccess,
  getUniqueGameIdsFailure,
  getSuperAdminTAGameSubCategorySuccess,
  getSuperAdminTAGameSubCategoryStart,
  getAllExcludedCasinoGamesStart,
  getAllExcludedCasinoGamesSuccess,
  getAllExcludedCasinoGamesFailure,
  addAggregatorsStart,
  addAggregatorsComplete,
  getBonusPrepaidListStart,
  getBonusPrepaidListComplete,
  updatePrepaidsStore,
  startFetchingAggregatorCategories,
  fetchAggregatorCategoriesSuccess,
  aggregatorCategoriesFetchFailed
} from '../redux-slices/superAdminCasinoManagement'
import { toast } from '../../components/Toast'

import { objectToFormData } from '../../utils/objectToFormdata'
import { getAllGamesStart, getAllProvidersSuccess } from '../redux-slices/tenantCasino'
import { isTenant } from '../../utils/constants'
import { fetchTenantDetailsStart } from '../redux-slices/login'

export default function * superAdminCasinoManagementWatcher () {
  yield takeLatest(getAllCasinoProvidersStart.type, getAllCasinoProvidersWorker)
  yield takeLatest(getAllCasinoGamesStart.type, getAllCasinoGamesWorker)
  yield takeLatest(createCasinoGameStart.type, createCasinoGameWorker)
  yield takeLatest(createCasinoProviderStart.type, createCasinoProviderWorker)
  yield takeLatest(updateCasinoGameStart.type, updateCasinoGameWorker)
  yield takeLatest(updateCasinoProviderStart.type, updateCasinoProviderWorker)
  yield takeLatest(resetCasinoGameStart.type, resetCasinoGameWorker)
  yield takeLatest(updateCasinoStatusStart.type, updateCasinoStatusWorker)
  yield takeLatest(getSuperAdminAggregatorsStart.type, getSuperAdminAggregatorsWorker)
  yield takeLatest(createSuperAdminAggregatorStart.type, createSuperAdminAggregatorWorker)
  yield takeLatest(updateSuperAdminAggregatorStatusStart.type, updateSuperAdminAggregatorStatusWorker)
  yield takeLatest(getSuperAdminGameCategoryStart.type, getSuperAdminGameCategoryWorker)
  yield takeLatest(getSuperAdminGameSubCategoryStart.type, getSuperAdminGameSubCategoryWorker)
  yield takeLatest(updateSACasinoGamesStatusStart.type, updateSACasinoGamesStatusWorker)
  yield takeEvery(getAllSAProvidersStart.type, getAllSAProvidersWorker)
  yield takeLatest(startFetchingAggregatorCategories.type, fetchAggregatorCategoriesWorker)
  yield takeLatest(getFreeSpinGamesStart.type, getFreeSpinGamesWorker)
  yield takeLatest(updateReorderGamesStart.type, updateReorderGamesWorker)
  yield takeLatest(getCasinoThemesStart.type, getCasinoThemesWorker)
  yield takeLatest(getUniqueGameIdsStart.type, getUniqueGameIdsWorker)
  yield takeLatest(getSuperAdminTAGameSubCategoryStart.type, getSuperAdminGameSubCategoryWorker)
  yield takeLatest(getAllExcludedCasinoGamesStart.type, getAllExcludedCasinoGamesWorker)
  yield takeLatest(addAggregatorsStart.type, addAggregatorsWorker)
  yield takeLatest(getBonusPrepaidListStart.type, getBonusPrepaidListWorker)
}

const prepaidsStoreData = (state) => state?.superAdminCasino

function * getAllCasinoProvidersWorker (action) {
  try {
    const { limit, pageNo, search = '', aggregatorId = '', reorder = '' } = action && action.payload
    const { data } =
    isTenant
      ? yield getAllSAProviders({
        limit,
        pageNo,
        search,
        reorder
      })
      : yield getAllCasinoProviders({
        limit,
        pageNo,
        search,
        aggregatorId
      })

    yield put(getAllCasinoProvidersSuccess(data?.data?.casinos))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getAllCasinoProvidersFailure(e?.response?.data?.errors[0].description))
  }
}

function * resetCasinoGameWorker () {
  yield put(resetCasinoGameSuccess())
}

function * getAllCasinoGamesWorker (action) {
  try {
    const { isTenant = '', bonusId, limit, pageNo, casinoCategoryId, search, isActive, tenantId, selectedProvider, freespins, countryCode = '', portalId = '', tenantIds = '[]', aggregatorId = '', setGamesData, aggregatorCategoryId } = action && action.payload
    const { data } = yield getAllCasinoGames({
      isTenant,
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      tenantId,
      selectedProvider,
      freespins: freespins || '',
      bonusId: bonusId || '',
      countryCode: countryCode || '',
      portalId,
      tenantIds,
      aggregatorId,
      aggregatorCategoryId
    })
    if (setGamesData) {
      setGamesData(data?.data?.casinoGames)
    }

    yield put(getAllCasinoGamesSuccess(data?.data?.casinoGames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCasinoGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * createCasinoProviderWorker (action) {
  try {
    const { data, limit, pageNo, search, aggregatorId = '' } = action && action.payload

    yield createCasinoProvider(objectToFormData(data))
    yield put(getAllCasinoProvidersStart({ limit, pageNo, search, aggregatorId }))

    yield toast('Provider created', 'success')
    yield put(createCasinoProviderComplete())
  } catch (e) {
    yield put(createCasinoProviderComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * createCasinoGameWorker (action) {
  try {
    const { data, casinoProviderId, limit, pageNo } = action && action.payload
    yield createCasinoGame(objectToFormData(data))

    yield toast('Game created', 'success')
    if (casinoProviderId) {
      yield put(getAllCasinoGamesStart({
        casinoProviderId,
        limit,
        pageNo,
        aggregatorId: action?.payload?.aggregatorId,
        aggregatorCategoryId: action?.payload?.aggregatorCategoryId
      }))
    }

    yield put(createCasinoGameComplete())
  } catch (e) {
    yield put(createCasinoGameComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoProviderWorker (action) {
  try {
    const { data, limit, pageNo, search, aggregatorId = '' } = action && action.payload

    yield updateCasinoProvider(objectToFormData(data))

    yield toast('Provider updated', 'success')
    yield put(getAllCasinoProvidersStart({ limit, pageNo, search, aggregatorId }))

    yield put(updateCasinoProviderComplete())
  } catch (e) {
    yield put(updateCasinoProviderComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoGameWorker (action) {
  try {
    const { data, casinoProviderId, limit, pageNo } = action && action.payload

    yield updateCasinoGame(objectToFormData(data))

    yield put(updateCasinoGameComplete())

    yield put(getAllCasinoGamesStart({
      casinoProviderId,
      limit,
      pageNo,
      aggregatorId: action?.payload?.aggregatorId,
      aggregatorCategoryId: action?.payload?.aggregatorCategoryId
    }))

    yield toast('Game updated', 'success')
  } catch (e) {
    yield put(updateCasinoGameComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateCasinoStatusWorker (action) {
  try {
    const { data, limit, pageNo, search, aggregatorId = '' } = action && action.payload
    isTenant ? yield tenantViewToggleStatus(data) : yield superAdminViewToggleStatus(data)
    yield toast('Status updated', 'success')

    yield put(getAllCasinoProvidersStart({ limit, pageNo, search, aggregatorId }))

    yield put(updateCasinoStatusComplete())
  } catch (e) {
    yield put(updateCasinoStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSACasinoGamesStatusWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      tenantId,
      selectedProvider
    } = action && action.payload

    yield superAdminViewToggleStatus(data)
    yield toast('Status updated', 'success')

    yield put(getAllCasinoGamesStart({
      data,
      limit,
      pageNo,
      casinoCategoryId,
      search,
      isActive,
      tenantId,
      selectedProvider,
      aggregatorId: action?.payload?.aggregatorId,
      aggregatorCategoryId: action?.payload?.aggregatorCategoryId
    }))

    yield put(updateSACasinoGamesStatusComplete())
  } catch (e) {
    yield put(updateSACasinoGamesStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminAggregatorsWorker (action) {
  try {
    const { limit, pageNo, search, tenantId = '' } = action && action.payload
    const { data } = yield getSuperAdminAggregators({
      limit,
      pageNo,
      search,
      tenantId
    })

    yield put(getSuperAdminAggregatorsSuccess(data?.data?.aggregators))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminAggregatorsFailure(e?.response?.data?.errors[0].description))
  }
}

function * createSuperAdminAggregatorWorker (action) {
  try {
    const { data, limit, pageNo, edit, search } = action && action.payload
    edit ? yield editSuperAdminAggregator(data) : yield createSuperAdminAggregator(data)

    yield toast('Aggregator Created', 'success')

    yield put(createSuperAdminAggregatorComplete())
    yield put(getSuperAdminAggregatorsStart({ limit, pageNo, search }))
  } catch (e) {
    yield put(createSuperAdminAggregatorComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateSuperAdminAggregatorStatusWorker (action) {
  try {
    const { data, limit, pageNo, search } = action && action.payload

    yield editSuperAdminAggregator(data)

    yield toast('Status Updated', 'success')

    yield put(updateSuperAdminAggregatorStatusComplete())
    yield put(getSuperAdminAggregatorsStart({ limit, pageNo, search }))
  } catch (e) {
    yield put(updateSuperAdminAggregatorStatusComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getSuperAdminGameCategoryWorker (action) {
  try {
    const { limit, pageNo, search, tenantId } = action && action.payload
    const { data } = yield getSuperAdminGameCategory({
      limit,
      pageNo,
      search,
      tenantId
    })

    yield put(getSuperAdminGameCategorySuccess(data?.data?.casinoCategories))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminGameCategoryFailure(e?.response?.data?.errors[0].description))
  }
}

function * getSuperAdminGameSubCategoryWorker (action) {
  try {
    const { limit, pageNo, search, gameCategoryId, isActive, tenantId, isSuperAdmin = false } = action && action.payload
    const { data } = yield getSuperAdminGameSubCategory({
      limit,
      pageNo,
      search,
      gameCategoryId,
      isActive,
      tenantId
    })

    !isSuperAdmin
      ? yield put(getSuperAdminGameSubCategorySuccess(data?.data?.casinoSubCategories))
      : yield put(getSuperAdminTAGameSubCategorySuccess(data?.data?.casinoSubCategories))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(getSuperAdminGameSubCategoryFailure(e?.response?.data?.errors[0].description))
  }
}

function * getAllSAProvidersWorker (action) {
  try {
    const { tenantId = '', tenantIds = '[]', allData = false, aggregatorId = '' } = action && action.payload
    const { data } = yield getAllSAProviders({ limit: '', pageNo: '', search: '', tenantId: tenantId || '', tenantIds, allData, aggregatorId })

    if (allData) { yield put(getAllProvidersSuccess(data?.data?.casinos?.rows)) } else yield put(getAllSAProvidersSuccess(data?.data?.providerList))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllSAProvidersFailure())
  }
}

// aggregators category
function * fetchAggregatorCategoriesWorker (action) {
  try {
    const { gameAggregatorId, search } = action && action.payload
    const { data } = yield getAggregatorCategories({
      gameAggregatorId, search
    })
    yield put(fetchAggregatorCategoriesSuccess(data?.data?.categories
    ))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')
    yield put(aggregatorCategoriesFetchFailed(e?.response?.data?.errors[0].description))
  }
}

function * getFreeSpinGamesWorker (action) {
  try {
    const { limit, pageNo, providerId, search, bonusId, tenantIds = '[]' } = action && action.payload

    const { data } = yield getFreeSpinGames({
      limit, pageNo, providerId, search, bonusId, isTenant: false, tenantIds
    })

    yield put(getFreeSpinGamesSuccess(data?.data?.gameWithFreespin))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getFreeSpinGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateReorderGamesWorker (action) {
  try {
    const { data, casinoCategoryId } = action && action.payload
    const res = yield updateReorderGames({ data })
    yield put(updateReorderGamesSuccess())
    yield put(
      getAllGamesStart({
        limit: '',
        pageNo: '',
        casinoCategoryId,
        isActive: '',
        orderBy: '',
        sort: '',
        providerId: '',
        order: true,
        aggregatorId: action?.payload?.aggregatorId,
        aggregatorCategoryId: action?.payload?.aggregatorCategoryId
      })
    )
    yield toast(res.data.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateReorderGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCasinoThemesWorker (action) {
  try {
    const { isTenant = false } = action && action.payload

    const { data } = yield getCasinoThemes({ isTenant })

    yield put(getCasinoThemesSuccess(data?.data?.ThemeList?.map(({ theme }) => theme)))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getCasinoThemesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getUniqueGameIdsWorker (action) {
  try {
    const { search, rating, provider, subCategory, themes, tenantId, countries, isTenant } = action && action.payload

    const { data } = yield getCasinoGameIds({ search, rating, provider, category: subCategory, themes, tenantId, country: countries, isTenant })

    yield put(getUniqueGameIdsSuccess(data?.data?.categoryGames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUniqueGameIdsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllExcludedCasinoGamesWorker (action) {
  try {
    const { isTenant, limit, pageNo, search = '', selectedProvider = '', bannedGamesListId, aggregatorId = '', aggregatorCategoryId = '' } = action && action.payload

    const { data } = yield getAllExcludedCasinoGames({
      limit,
      isTenant,
      pageNo,
      search,
      selectedProvider,
      bannedGamesListId,
      aggregatorId,
      aggregatorCategoryId
    })

    yield put(getAllExcludedCasinoGamesSuccess(data?.data?.gameDetail))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllExcludedCasinoGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * addAggregatorsWorker (action) {
  try {
    const { data } = action && action.payload
    const res = yield addAggregators({ data })

    yield put(addAggregatorsComplete())
    yield put(fetchTenantDetailsStart())
    yield toast(res.data.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(addAggregatorsComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * getBonusPrepaidListWorker (action) {
  try {
    const { currencyCode = '', gameIdentifier = '', tenantId = '' } = action && action.payload
    const { prepaidsStore } = yield select(prepaidsStoreData)
    const storeKey = JSON.stringify(currencyCode + gameIdentifier + tenantId)
    if (prepaidsStore?.[storeKey]) {
      yield put(getBonusPrepaidListComplete(prepaidsStore?.[storeKey]))
    } else {
      const { data } = yield getBonusPrepaidList({ currencyCode, gameIdentifier, tenantId })

      yield put(getBonusPrepaidListComplete(data?.data?.prepaidList))
      yield put(updatePrepaidsStore({ [storeKey]: data?.data?.prepaidList }))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getBonusPrepaidListComplete([]))
  }
}
