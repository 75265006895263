import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getItem, getLoginToken } from '../../../../utils/storageUtils'
import { getDateDaysAgo, formatDateYMD } from '../../../../utils/dateFormatter'
import { getKPISummaryStart } from '../../../../store/redux-slices/dashboard'
import { getAdminUserId, getAllTenantOptionsSelected, checkIsTenant, getTenantId } from '../../../../utils/constants'

const useKPISummary = ({ selectedPortal = '', selectedClient = '', accordionState }) => {
  const dispatch = useDispatch()
  const isTenant = checkIsTenant()
  const { kpiSummeryLoading: loading, KPISummary } = useSelector((state) => state.dashboard)
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const loadKPISummary = () => {
    accordionState.kpiSummaryReport && dispatch(getKPISummaryStart({
      isTenant,
      // startDate: getUtcDateYMD(...state.map(a => a.startDate), 'kpiReport'),
      // endDate: getUtcDateYMD(...state.map(a => a.endDate), 'kpiReport'),
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      tenantId: isTenant ? getAllTenantOptionsSelected() ? '' : getTenantId() : selectedPortal,
      adminId: isTenant ? getAdminUserId() : selectedClient
    }))
  }

  useEffect(() => {
    loadKPISummary()
  }, [state, selectedClient, selectedPortal, accordionState, accordionState.kpiSummaryReport])

  const getCsvDownloadUrl = () => {
    return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/kpi-summary?${!isTenant ? `adminId=${selectedClient}&` : ''}tenantId=${isTenant ? getItem('tenant-id') : selectedPortal}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&csvDownload=true&token=${getLoginToken()}`
  }

  return {
    loadKPISummary,
    loading,
    setState,
    state,
    getCsvDownloadUrl,
    KPISummary
  }
}

export default useKPISummary
