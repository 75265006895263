import { Button, Form as BForm, Modal, Row, Col } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import Select from 'react-select'
import { copySchema } from './schema'
import { copySettingsStart } from '../../../../store/redux-slices/payment'
import useCopySettings from '../hooks/useCopySettings'

const CopyModal = ({ show, setShow }) => {
  const {
    clientsFilterData,
    portalOptions,
    setSelectedClient,
    isTenant,
    dispatch,
    adminId,
    setSelectedPortal,
    paymentOptions
  } = useCopySettings();

  return (
    <>
      <Formik
        initialValues={{
          adminId: isTenant ? adminId : '',
          sourceTenantId: null,
          targetTenantId: null,
          aggregatorList: [],
        }}
        validationSchema={copySchema}
        onSubmit={(formValues, { resetForm }) => {
          const data = {
            adminId: parseInt(formValues.adminId),
            sourceTenantId: formValues?.sourceTenantId?.value,
            targetTenantId: formValues?.targetTenantId?.value,
            aggregatorList: formValues?.aggregatorList.map(aggregator => aggregator.value),
          };
          dispatch(copySettingsStart({ data, isTenant, resetForm, setShow }));
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue, errors }) => (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton className="d-flex align-items-center">
              <h4 className="mx-2">Copy Aggregator/Provider Settings</h4>
            </Modal.Header>
            <Modal.Body className="add-payment-method">
              {!isTenant &&
                <Col>
                  <label>Owner<span className='text-danger'> *</span></label>
                  <BForm.Select
                    name='adminId'
                    value={values.adminId}
                    onChange={(e) => {
                      handleChange(e)
                      setSelectedClient(e.target.value)
                      setFieldValue('tenantIds', [])
                      setFieldValue('sourceTenantId', null)
                      setFieldValue('targetTenantId', null)
                    }}
                  >
                    <option value='' disabled key=''>
                      Select Owner
                    </option>
                    {clientsFilterData?.rows?.map((admin) => (
                      <option value={admin.adminUserId} key={admin.adminUserId}>
                        {`${admin.email} (${admin?.firstName} ${admin?.lastName})`}
                      </option>
                    ))}
                  </BForm.Select>
                  <ErrorMessage
                    component='div'
                    name='adminId'
                    className='text-danger'
                  />
                </Col>}
              <Col className='mt-2'>
                <label>Copy From Portal<span className="text-danger"> *</span></label>
                <Select
                  name="sourceTenantId"
                  value={values.sourceTenantId}
                  options={portalOptions}
                  classNamePrefix="select"
                  onChange={(option) => {
                    setFieldValue('sourceTenantId', option)
                    setSelectedPortal(option)
                    setFieldValue('targetTenantId', null)
                  }}
                />
                <ErrorMessage component="div" name="sourceTenantId" className="text-danger" />
              </Col>
              <Col className='mt-4'>
                <label>Copy To Portal<span className="text-danger"> *</span></label>
                <Select
                  name="targetTenantId"
                  value={values.targetTenantId}
                  options={portalOptions.filter(
                    (option) => option.value !== values.sourceTenantId?.value
                  )} // Exclude selected "Copy From" item
                  classNamePrefix="select"
                  onChange={(option) => setFieldValue('targetTenantId', option)}
                />
                <ErrorMessage component="div" name="targetTenantId" className="text-danger" />
              </Col>
              <Col className='mt-4'>
                <label>Aggregator/Provider<span className="text-danger"> *</span></label>
                <Select
                  isMulti
                  name="aggregatorList"
                  value={values.aggregatorList}
                  options={paymentOptions}
                  classNamePrefix="select"
                  onChange={(option) => setFieldValue('aggregatorList', option)}
                />
                <ErrorMessage component="div" name="aggregatorList" className="text-danger" />
              </Col>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between align-items-center mt-4">
              <Button variant="outline-warning" onClick={() => setShow(false)}>
                Cancel
              </Button>
              <Button variant="outline-success" onClick={handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default CopyModal;
