export const tableHeaders = [
  { label: 'ID', value: 'masterCasinoGameId' },
  { label: 'Name', value: 'name' },
  { label: 'Aggregator', value: 'casionoAggregator' },
  { label: 'Provider', value: 'casionoprovider' },
  { label: 'RTP', value: 'returnToPlayer' },
  { label: 'SUB CATEGORY', value: 'casinosubcat' },
  { label: 'Game Type', value: 'gameType' },
  { label: 'Thumbnail', value: 'thumbnail' },
  { label: 'Device Type', value: 'devices' },
  { label: 'Status', value: 'status' },
  { label: 'Feature Group', value: 'operatorstatus' },
  { label: 'Released Date', value: 'released_at' },
  { label: 'Operator', value: 'operatorstatus' },
  { label: 'Action', value: 'action' }
]

export const volatilityRating = [
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
  { label: 'Medium High', value: 'medium-high' },
  { label: 'Very High', value: 'very-high' },
  { label: 'Low Nedium', value: 'low-medium' }
]
