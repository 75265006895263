import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPortalsStart } from "../../../../store/redux-slices/tenants";
import { isTenant } from "../../../../utils/constants";
import { getPaymentAggregatorsStart } from "../../../../store/redux-slices/payment";

const useCopySettings = () => {
  const {
    clientsFilterData,
    portalsFilterData: tenantData,
    tenantsList,
  } = useSelector((state) => state.tenants);
  const { tenantDetails } = useSelector((state) => state.login);
  const [portalOptions, setPortalOptions] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPortal, setSelectedPortal] = useState();
  const [selectedClient, setSelectedClient] = useState(
    isTenant ? tenantDetails?.adminUserId : ""
  );
  const dispatch = useDispatch();
  const portalsFilterData = isTenant ? tenantsList : tenantData;

  const { paymentAggregators } = useSelector((state) => state.payment);

  useEffect(() => {
    let options = [];
    if (portalsFilterData && (selectedClient || isTenant)) {
      options = portalsFilterData.rows.map((portal) => {
        return { label: portal.name, value: portal.tenantId };
      });
      setPortalOptions(options);
    }
  }, [portalsFilterData, selectedClient]);

  useEffect(() => {
    if (selectedClient && !isTenant) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }));
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedPortal?.value && selectedClient) {
      dispatch(
        getPaymentAggregatorsStart({
          isTenant,
          tenantId: selectedPortal?.value,
        })
      );
    }
  }, [selectedClient, selectedPortal]);

  useEffect(() => {
    setPaymentAggregators();
  }, [paymentAggregators]);

  const setPaymentAggregators = () => {
    const paymentAggregatorsOptions = Object.keys(paymentAggregators || {}).map(
      (aggregator) => ({
        label: aggregator.charAt(0).toUpperCase() + aggregator.slice(1),
        value: aggregator,
      })
    );
    setPaymentOptions(paymentAggregatorsOptions);
  };

  return {
    clientsFilterData,
    portalOptions,
    setSelectedClient,
    selectedClient,
    isTenant,
    dispatch,
    adminId: tenantDetails?.adminUserId,
    setSelectedPortal,
    selectedPortal,
    paymentOptions,
  };
};

export default useCopySettings;
