import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getExcludedLicenseListStart } from '../../store/redux-slices/licenseManagement'
const useExcludedLicenseList = (selectedPortal) => {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const [selectedExcludedLicense, setSelectedExcludedLicense] = useState(state?.myLicenseId || '')
  const { loadingLicenseList, excludedLicenseList } = useSelector((state) => state.licenseManagement)

  useEffect(() => {
    if (selectedPortal) {
      dispatch(getExcludedLicenseListStart({
        tenantId: selectedPortal || ''
      }))
    }
  }, [selectedPortal])

  const excludedLicenseListOptions = useMemo(() => {
    return excludedLicenseList?.length
      ? excludedLicenseList.map((item) => ({
        value: item.id,
        label: item.name
      }))
      : []
  }, [excludedLicenseList])

  return {
    loadingLicenseList,
    excludedLicenseListOptions,
    selectedExcludedLicense,
    setSelectedExcludedLicense
  }
}

export default useExcludedLicenseList
