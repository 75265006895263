import React from 'react'
import useRedirectionManagement from './hooks/useRedirectionManagement'
import { Button } from 'react-bootstrap'
import RedirectionModal from './components/RedirectionModal'
import DataTable from '../../../shared/DataTable'
import './scss/redirection.scss'
import BootStrapSelect from '../../../shared/Select/BootStrapSelect'
import { CustomConfirmationModal } from '../../../components/ConfirmationModal'
const RedirectionManagement = ({ isTenant }) => {
  const {
    // Modal state and controls
    showRedirectionModal,
    setShowRedirectionModal,

    // Data for the table and options
    columns,
    rowData,
    ownerOptions,
    tenantOptions,

    // Selected states and setters
    selectedOwner,
    setSelectedOwner,
    selectedTenant,
    setSelectedTenant,

    // Loading state and action handlers
    loading,
    updateRedirectionUrl,

    // UI control flags and permissions
    isHidden,
    selectedRedirectionDetail,
    showConfirmationModal,
    setShowConfirmationModal,
    handleConfirmationModal
  } = useRedirectionManagement({ isTenant })

  return (
    <>
      <div className='redirection-container'>
        <div className='redirection-header'>
          <h3> Mirror Site Redirection Management</h3>
        </div>
        <div className='redirection-filters'>
          {!isTenant &&
            <div className='redirection-filter-container'>
              <BootStrapSelect
                options={ownerOptions}
                onChange={(e) => setSelectedOwner(e.target.value)}
                value={selectedOwner}
                placeholder='Select an Owner'
                label='Owner'
                noOptionsMessage='No Owners Available'
              />

            </div>}
          {!isTenant && selectedOwner && (
            <div className='redirection-filter-container'>
              <BootStrapSelect
                options={tenantOptions}
                onChange={(e) => setSelectedTenant(e.target.value)}
                value={selectedTenant}
                placeholder='Select an Tenant'
                label='Tenant'
                noOptionsMessage='No Tenant Available'
              />
            </div>
          )}
          {!isHidden({ module: { key: 'Settings', value: 'C' } }) && selectedOwner && selectedTenant && (
            <div className='d-flex'>
              <Button
                variant='outline-success'
                size='sm'
                onClick={() => setShowRedirectionModal(true)}
              >
                Create
              </Button>
            </div>
          )}
        </div>

      </div>

      {!isTenant && !selectedOwner && (
        <p className='text-center text-danger mt-4'>Please Select Owner First</p>
      )}

      {!isTenant && selectedOwner && !selectedTenant && (
        <p className='text-center text-danger mt-4'>Please Select Portal</p>
      )}

      {
      selectedTenant &&
        <DataTable columns={columns} loading={loading} rowData={rowData} />
      }

      {showRedirectionModal && (
        <RedirectionModal
          isVisible={showRedirectionModal}
          closeModal={() => setShowRedirectionModal(false)}
          onConfirm={updateRedirectionUrl}
          ownerId={selectedOwner}
          tenantId={selectedTenant}
        />
      )}

      {
        showConfirmationModal &&
          <CustomConfirmationModal
            isVisible={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            onConfirm={handleConfirmationModal}
          >
            <div className='fs-5'>
              Are you sure you want to mark {!selectedRedirectionDetail.isActive ? 'Active' : 'In-Active'}?
            </div>
          </CustomConfirmationModal>
      }
    </>
  )
}

export default RedirectionManagement
