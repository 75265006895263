import React from 'react'
import { Form } from '@themesberg/react-bootstrap'
import useAllProviderList from './useAllProviderList'

const ProvidersFilter = ({ setSelectedProvider, selectedProvider, isTenantRoute = false, tenantId = '', tenantIds = '[]', bonus = false, aggregatorId = '' }) => {
  const { allProviders = [] } = useAllProviderList({ isTenantRoute, tenantId, tenantIds, aggregatorId })
  const providers = Array.isArray(allProviders?.rows)
    ? allProviders.rows
    : Array.isArray(allProviders)
      ? allProviders
      : []
  return !bonus && (
    <>
      <Form.Label style={{ marginRight: '15px', marginBottom: '0px' }}>
        Provider
      </Form.Label>

      <Form.Select
        onChange={(e) => {
          setSelectedProvider(e.target.value)
        }}
        value={selectedProvider}
        style={{ maxWidth: '230px', marginRight: isTenantRoute && '15px' }}
        size='sm'
      >
        <option value=''>
          All
        </option>

        {allProviders?.count === 0 && (
          <option value='' disabled>
            No Providers Available
          </option>
        )}

        {providers.map(({ masterCasinoProviderId, name }) => (
          <option key={masterCasinoProviderId} value={masterCasinoProviderId}>
            {name}
          </option>
        ))}
      </Form.Select>
    </>
  )
}

export default ProvidersFilter
